import {Box, Flex, FormLabel, Text, VStack} from "@chakra-ui/react";
import {InputControl, LanguagePicker, RadioControl, RadioGroupControl, TextareaControl} from "../../forms";
import React from "react";

const Step2Content = ({props}) => {

    return (
        <Flex direction={['column', null, 'row']} p={3} w="full" spacing={6}>
            <VStack spacing={6} flex="1" pr={[0, null, 8]} alignItems="start">
                <InputControl name="jobTitle" labelKey="project.all.step2.jobTitle" required/>
                <TextareaControl name="description" labelKey="project.all.step2.description" required/>
            </VStack>

            <VStack spacing={6} flex="1" alignItems="start">
                <Box>
                    <FormLabel>I want to translate:</FormLabel>
                    <Flex align="center" mt={2}>
                        <Text mr={2}>From</Text>
                        <Box flex="1" mr={4}>
                            <LanguagePicker name="localizeFrom" {...props}/>
                        </Box>
                        <Text mr={2}>To</Text>
                        <Box flex="1">
                            <LanguagePicker name="localizeTo" {...props}/>
                        </Box>
                    </Flex>
                </Box>
                <InputControl name="sourceFile" labelKey="project.translationDubbing.step2.sourceFile"
                              variant='projectLink'/>

                <RadioGroupControl name="whatFormat" labelKey="project.translationDubbing.step2.whatFormat">
                    <RadioControl value="Text">Text</RadioControl>
                    <RadioControl value="Audio">Audio</RadioControl>
                    <RadioControl value="Video">Video</RadioControl>
                </RadioGroupControl>
            </VStack>
        </Flex>
    )
}

export default Step2Content;