import { defineStyle, defineStyleConfig } from '@chakra-ui/react'


const baseStyle = defineStyle({
    color: 'brand.veryDarkGray',
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '13px',
    _hover: {
        color: 'brand.green',
        textDecoration: 'underline',
    }
});

const menu = defineStyle({
  color: 'brand.black',
  textDecoration: 'none',
  _activeLink: {
    fontWeight: 'bold'
  }
});

const dashboard = defineStyle({
    color: 'brand.black',
    textDecoration: 'none',
    _activeLink: {
        fontWeight: 'bold'
    }
});

const filter = defineStyle({
    color: 'brand.veryDarkGray',
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '18px',
    _hover: {
        color: 'brand.green',
        textDecoration: 'underline',
    }
});

const action = defineStyle({
    fontSize: 'xl',
    fontWeight: 'semibold',
});

export const linkTheme = defineStyleConfig({
  baseStyle,
  variants: { menu, action, filter, dashboard },
});