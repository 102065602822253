import {
    Flex,
    Spinner,
    Center
} from '@chakra-ui/react';

const ComponentLoading = () => {
    return <Flex h='full'>
        <Center w='full'>
            <Spinner />
        </Center>
    </Flex>; 
};

export default ComponentLoading;