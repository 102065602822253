import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
    bg: 'brand.white',
    border: "1px solid",
    borderColor: "brand.darkerGray",
    borderRadius: "5px",
    minH: "140px",
    '&:focus': {
        borderColor: 'brand.green',
        outline: 'none',
    }
})


const sizes = {
    sm: {
            fontSize: "13px",
    },
    md: {
            fontSize: "13px",
    },
};

export const textareaTheme = defineStyleConfig({
    baseStyle,
    sizes
})