import {Box, Flex, Text, VStack} from "@chakra-ui/react";
import TruncatedLink from "../../truncatedLink";

const parseText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const maxUrlLength = 40; // Maximum length of the URL

    return text.split(urlRegex).map((part, i) => {
        if (urlRegex.test(part)) {
            let displayUrl = part;
            if (part.length > maxUrlLength) {
                displayUrl = `${part.substring(0, maxUrlLength)}...`;
            }

            return (
                <div key={i}>
                    <a href={part} target="_blank" style={{ color: '#3B9D97', wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-all' }} rel="noreferrer">
                        {displayUrl}
                    </a>
                </div>
            );
        } else {
            return part;
        }
    });
};

const DetailInformation = ({project}) => {
    return (
        <Flex direction={['column', null, 'row']} w="full">
            {/* First Column */}
            <VStack spacing={6} flex="1" pr={[0, null, 8]} alignItems="start">
                <Box>
                    <Text fontWeight='bold'>Job Title</Text>
                    <Text>{project.jobTitle}</Text>
                </Box>
                <Box>
                    <Text fontWeight='bold'>Job Description</Text>
                    <Text whiteSpace="pre-wrap">
                        {parseText(project.description)}
                    </Text>
                </Box>
                <Box>
                    <Text fontWeight='bold'>Is it part of a series?</Text>
                    <Text>{project.isPartOfSeries ? 'Yes' : 'No'}</Text>
                </Box>
                <Box>
                    <Text fontWeight='bold'>Reference Videos</Text>
                    <ul style={{listStyle: 'none'}}>
                        {project.previousEpisodes?.map((url, i) => (
                            <li key={i}>
                                {url.toLowerCase().includes('https') ? (
                                    <TruncatedLink url={url}/>
                                ) : (
                                    url
                                )}
                            </li>
                        ))}
                    </ul>
                </Box>
            </VStack>

            <VStack spacing={6} flex="1" alignItems="start">
                <Box>
                    <Text fontWeight='bold'>Specify the length of each video</Text>
                    <ol style={{marginLeft: '1em'}}>
                        {project.lengthOfVideos?.map((e, i) => (
                            <li key={i}>{e.name}</li>
                        ))}
                    </ol>
                </Box>
                <Box>
                    <Text fontWeight='bold'>Can you share any existing assets?</Text>
                    <ul style={{listStyle: 'none'}}>
                        {project.existingAssets?.map((url, i) => (
                            <li key={i}>
                                {url.toLowerCase().includes('https') ? (
                                    <TruncatedLink url={url}/>
                                ) : (
                                    url
                                )}
                            </li>
                        ))}
                    </ul>
                </Box>
                <Box>
                    <Text fontWeight='bold'>Do you already have footage?</Text>
                    <Text>{project.alreadyHaveFootage ? 'Yes' : 'No'}</Text>
                </Box>
                <Box>
                    <Text fontWeight='bold'>Footage Link</Text>
                    <TruncatedLink url={project.footageLink}/>
                </Box>
            </VStack>
        </Flex>
    )
}
export default DetailInformation;