import {useEffect, useState} from "react";
import {useApi} from "../../hooks/useApi";
import {IconButton, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure} from "@chakra-ui/react";
import {EditIcon} from "@chakra-ui/icons";
import {useHistory} from "react-router-dom";

export default ({companies, onCompanyEdit}) => {
    const [allCompanies, setAllCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [loading, setIsLoading] = useState(true);
    const { profile: companiesService } = useApi();
    const history = useHistory();

    useEffect(() => {
        const fn = async () => {
            if (loading) {
                const companiesResponse = await companiesService.getAll();
                setAllCompanies(await companiesResponse.json());
                setIsLoading(false);
            }
        }

        fn();
    })

    if (loading) {
        return <Spinner />;
    }
    // const handleEditCompany = async (companyId) => {
    //     console.log(companyId);
    //     history.push(`/profile/company/${companyId}`);
    // };

    return <>
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th>Company Name</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {companies?.map((c) => <Tr key={c?.companyId}>
                        <Td>{c.name}</Td>
                        <Td><IconButton variant="ghost" icon={<EditIcon onClick={() => onCompanyEdit(c?.companyId)} />} aria-label="Edit"></IconButton></Td>
                    </Tr>)}
                </Tbody>
            </Table>
        </TableContainer>
    </>;
}