import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../../protectedRoute.jsx';
import step2 from './cameraOperatorStep2.jsx';
import step3 from './step3.jsx';
import assignFreelancer from '../assignFreelancer.jsx';

export default () => {
    const { path } = useRouteMatch();

    return <Switch>
        <ProtectedRoute path={`${path}/:projectId/step-2`} component={step2} />
        <ProtectedRoute path={`${path}/:projectId/step-3`} component={step3} />
        <ProtectedRoute path={`${path}/:projectId/assign-freelancer`} component={assignFreelancer} />
    </Switch>;
}