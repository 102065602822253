import {useParams} from 'react-router-dom';
import {useProject} from '../../entities';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../../hooks/useApi.jsx';
import VideoEditingDetail from './detail.jsx';
import ComponentLoading from '../../ComponentLoading.jsx';
import GenericLastStep from "../genericLastStep";
import useSubmitProject from "../useSubmitProject";

const Step4 = () => {
    const {companyId, projectId} = useParams();
    const {loading} = useProject(companyId, projectId);
    const {t} = useTranslation();
    const {projects} = useApi();

    const handleSubmit = useSubmitProject(
        projects.videoEditing.submitBrief,
        '/'
    );

    if (loading) {
        return <ComponentLoading/>;
    }

    return (
        <GenericLastStep
            projectId={projectId}
            companyId={companyId}
            handleSubmit={() => handleSubmit(companyId, projectId)}
            DetailComponent={VideoEditingDetail}
            heading='④'
            subheading={t('project.all.step4.title')}
            progress={80}
        />
    );
}

export default Step4;