import React from 'react';
import {useHistory} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth.jsx';
import {Button, Container, Grid, GridItem, Heading, HStack, Text, VStack,} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import ActivityPanel from './activityPanel.jsx';
import ProjectPanel from "./dashboard/projectPanel";

export default () => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const history = useHistory();

    return (
        <Container maxW={'100rem'} padding={6}>
            <HStack>
                <Heading marginTop={8} marginBottom={4}>
                    {t('dashboard.title')}
                </Heading>
            </HStack>

            {!user.isFreelanceMode && <Button size='primary' onClick={() => history.push('/create-project')}
                                              variant="primary">+ {t('dashboard.createProject')}</Button>}
            <Grid
                templateColumns={!user.isFreelanceMode ? ['minmax(0, 1fr)', null, 'minmax(0, 3fr) minmax(0, 1fr)'] : 'minmax(0, 1fr)'}
                templateAreas={!user.isFreelanceMode ? [`"activity" "project"`, null, `"project activity"`] : `"project"`}
                gap='2em'
                marginTop='32px'>

                {!user.isFreelanceMode &&
                    <GridItem area={'activity'}>
                        <VStack alignItems="left">
                            <Heading size='md' marginTop={0} height='29px' pt='4px'>Activity</Heading>
                            <ActivityPanel
                                emptyContent={
                                    <Text>Ready to create your first project? Click 'Create a Project' to start!</Text>
                                }
                                linkToProject
                            />
                        </VStack>
                    </GridItem>
                }
                <GridItem area={'project'}>
                    <ProjectPanel user={user}/>
                </GridItem>
            </Grid>
        </Container>
    );
};