import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            general: {
                required: 'This field is required',
                somethingwentwrong: 'Sorry, something went wrong. Please contact support.',
                addFile: 'Upload File'
            },
            login: {
                title: 'Welcome back!',
                email: 'Email Address',
                password: 'Password',
                submit: 'Submit',
                yourdatasafe: 'Your data is safe with us. Check our',
                privacypolicy: 'Privacy policy',
                terms: 'Terms and Conditions',
                rememberme: 'Keep me logged in',
                forgotpassword: 'Forgot password?'
            },
            signup: {
                title: 'Sign up and create your first Project!',
                email: 'Email',
                password: 'Password',
                confirmPassword: 'Confirm password',
                submit: 'Sign up',
                userexists: 'This user already exists'
            },
            forgotpassword: {
                title: 'Forgot your password?',
                remindus: 'Remind us your e-mail address below and we\'ll send you the reset link!',
                email: 'Enter your e-mail',
                submit: 'Submit',
                checkyouremail: 'We\'ve sent the link - check your email'
            },
            resetpassword: {
                title: 'Enter your new password below.',
                password: 'New password',
                confirmpassword: 'Confirm new password',
                submit: 'Submit',
                mustbesame: 'These two passwords must match'
            },
            dashboard: {
                title: 'Dashboard',
                createProject: 'Create a Project',
                no_projects: 'No projects found',
                projects: {
                    title: 'Project Panel',
                    datecreated: 'Date',
                    duedate: 'Due date',
                    name: 'Name',
                    tags: 'Tags',
                    progress: 'Progress',
                    edit: 'Edit',
                    delete: 'Delete',
                    assignUsers: 'Assign Users',
                    assignedTo: 'Assigned To',
                    workOnThis: 'Work on this'
                }
            },

            // Project statuses listed here as some projects have step3 as final step; and some have step4
            projects: {
                assignUser: {
                    nousers: 'No users for this email address'
                },
                'channel-branding': {
                    status: {
                        Step2: 'Step 2',
                        Step3: 'Step 3',
                        Step4: 'Ready to submit',
                        BriefSubmitted: 'Brief Submitted',
                        PendingAllocation: 'Pending Allocation',
                        ProjectAccepted: 'Project Accepted',
                        FilesReceived: 'Files Received',
                        ReviewLinksSubmitted: 'Review Links Submitted',
                        FinalFilesSubmitted: 'Final Files Submitted',
                        ProjectCompleted: 'Project Completed'
                    }
                },
                'camera-operator': {
                    status: {
                        Step2: 'Step 2',
                        Step3: 'Ready to submit',
                        BriefSubmitted: 'Brief Submitted',
                        PendingAllocation: 'Pending Allocation',
                        ProjectAccepted: 'Project Accepted',
                        FilesReceived: 'Files Received',
                        ReviewLinksSubmitted: 'Review Links Submitted',
                        FinalFilesSubmitted: 'Final Files Submitted',
                        ProjectCompleted: 'Project Completed'
                    }
                },
                'translation-dubbing': {
                    status: {
                        Step2: 'Step 2',
                        Step3: 'Step 3',
                        Step4: 'Ready to submit',
                        BriefSubmitted: 'Brief Submitted',
                        PendingAllocation: 'Pending Allocation',
                        ProjectAccepted: 'Project Accepted',
                        FilesReceived: 'Files Received',
                        ReviewLinksSubmitted: 'Review Links Submitted',
                        FinalFilesSubmitted: 'Final Files Submitted',
                        ProjectCompleted: 'Project Completed'
                    }
                },
                'motion-graphics': {
                    status: {
                        Step2: 'Step 2',
                        Step3: 'Step 3',
                        Step4: 'Ready to submit',
                        BriefSubmitted: 'Brief Submitted',
                        PendingAllocation: 'Pending Allocation',
                        ProjectAccepted: 'Project Accepted',
                        FilesReceived: 'Files Received',
                        ReviewLinksSubmitted: 'Review Links Submitted',
                        FinalFilesSubmitted: 'Final Files Submitted',
                        ProjectCompleted: 'Project Completed'
                    }
                },
                'video-editing': {
                    status: {
                        Step2: 'Step 2',
                        Step3: 'Step 3',
                        Step4: 'Ready to submit',
                        BriefSubmitted: 'Brief Submitted',
                        PendingAllocation: 'Pending Allocation',
                        ProjectAccepted: 'Project Accepted',
                        FilesReceived: 'Files Received',
                        ReviewLinksSubmitted: 'Review Links Submitted',
                        FinalFilesSubmitted: 'Final Files Submitted',
                        ProjectCompleted: 'Project Completed'
                    }
                },
                bespoke: {
                    status: {
                        Step2: 'Step 2',
                        Step3: 'Ready to submit',
                        BriefSubmitted: 'Brief Submitted',
                        PendingAllocation: 'Pending Allocation',
                        ProjectAccepted: 'Project Accepted',
                        FilesReceived: 'Files Received',
                        ReviewLinksSubmitted: 'Review Links Submitted',
                        FinalFilesSubmitted: 'Final Files Submitted',
                        ProjectCompleted: 'Project Completed'
                    }
                },
            },

            // Other project labels here, ideally should be refactored so they're keyed under the
            // same project type identifier?
            project: {
                edits: 'Edit Links',
                type: {
                    title: 'Select a project type',
                    companyId: 'Select a company'
                },
                all: {
                    step2: {
                        jobTitle: 'Job title:',
                        description: 'Describe your project a little...',
                        existingAssets: 'Can you share any existing assets?',
                        title: 'Project information',
                        inspirations: 'Can you share anything to inspire?',
                        includeCompanyAssets: 'Do you want to include your company graphic assets?',
                        attachedAssets: 'Select which assets to include',
                        preferredColourSpecification: 'Preferred colour specification:',
                    },
                    step3: {
                        title: 'Deliverables',
                        finalFileVersions: 'Final File Versions',
                        deadline: 'When do you wish to have the files delivered?',
                        asap: 'As soon as possible',
                        localize: 'Do you want to localize?',
                        howToLocalize: 'How do you want to localize',
                        captions: 'Captions',
                        dubbing: 'Dubbing',
                        captionsAndDubbing: 'Captions and dubbing',
                        receiveProjectFiles: 'Do you want to receive project files?',
                    },
                    step4: {
                        title: 'Review your brief'
                    }
                },
                channelBranding: {
                    step3: {
                        graphicSoftwareType: 'Do you have a preference for the graphic software we use?'
                    }
                },
                cameraOperator: {
                    step2: {
                        whenIsTheShootTakingPlace: 'When is the shoot taking place?',
                        doYouNeedProfessionalLighting: 'Do you need professional lighting?',
                        numberOfCameras: 'Number of cameras',
                        whereIsTheShootTakingPlace: 'Where is the shoot taking place?'
                    }
                },
                translationDubbing: {
                    step2: {
                        whatFormat: 'What Format is the source content?',
                        sourceFile: 'Please share a link to what you want translated'
                    },
                    step3: {
                        finalFileVersions: 'Which final file versions do you want to receive?'
                    },
                    step4: {
                        title: 'Review your brief'
                    }
                },
                motionGraphics: {
                    step2: {
                        footageLink: 'Footage Link'
                    },
                    step3: {
                        finalFileVersions: 'Final File Versions',
                        formats: 'Formats'
                    }
                },
                videoEditing: {
                    step2: {
                        isPartOfSeries: 'Is it a part of a series?',
                        // referenceVideos: 'Can you share a reference video link as an example?',
                        referenceVideos: 'Reference Videos',
                        lengthOfVideos: 'What is the length of each video?',
                        alreadyHaveFootage: 'Do you already have footage?',
                        footageLink: 'Footage link'
                    },
                    step3: {                        
                        wantThumbnail: 'Do you want a thumbnail?',                        
                        videoVersions: 'Which video versions do you want to receive?',
                        videoEditingSoftware: 'Do you have a preference for the editing software we use?'
                    }
                },
                bespoke: {
                    step3: {
                        title: 'Review your brief'
                    }
                },
                assignUser: {
                    title: 'Assign Freelancer',
                    projectName: 'Project Name:',
                    name: 'Name',
                    skills: 'Skills',
                    availability: 'Availability',
                    assign: 'Assign'
                }
            },
            profile: {
                firstName: 'First name',
                lastName: 'Surname',
                email: 'Email',
                phoneNumber: 'Phone number',
                language: 'Language',
                level: 'Level',
                submit: 'Save',
                freelanceSection: 'Freelance Section',
                availability: 'Availability',
                WorkingDays: 'Working Days',
                CurrentlyUnavailable: 'Currently Unavailable',
                EveningsAndWeekends: 'Evenings and Weekends',
                Anytime: 'Any time',
                company: {
                    name: 'Name',
                    field: 'Field',
                    city: 'City',
                    country: 'Country',
                    submit: 'Update',
                    delete: 'Delete',
                    invitations: 'Invite Collaborators',
                    logoColoured: 'Coloured version:',
                    logoMonochrome: 'Monochrome version:',
                    logoBlackWhiteBackground: 'Black/White background:',
                    logoWhiteBlackBackground: 'White/Black background:',
                    visuals: 'Brand visuals:',
                    brandGuidelines: 'Brand guidelines:'
                }
            }
        }
    }
};

i18n
    .use(initReactI18next)
    .init({ resources, lng: 'en-GB', interpolation: { escapeValue: false } });

export default i18n;