import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useApi} from '../../../hooks/useApi.jsx';
import * as Yup from 'yup';
import ProjectStep from '../projectStep.jsx';
import Step2Content from "./step2Content";
import useLocalStorageUtil from "../../../utils/localStorageUtils";
import React from "react";

// https://medium.com/hackernoon/formik-handling-files-and-recaptcha-209cbeae10bc
// https://codesandbox.io/s/jpo114rk6v?from-embed=&file=/index.js
// https://codesandbox.io/s/lkkjpr5r7?from-embed=&file=/index.js
const BespokeStep2 = ({projectId: propProjectId, companyId: propCompanyId, isAnonymous, onNext}) => {
    const {t} = useTranslation();
    const {projects} = useApi();
    const {companyId: paramCompanyId, projectId: paramProjectId} = useParams();
    const [guid] = useLocalStorageUtil();
    const history = useHistory();

    const companyId = propCompanyId || paramCompanyId;
    const projectId = propProjectId || paramProjectId;
    const nextStep = `/company/${companyId}/bespoke-project/${projectId}/step-3`;

    const updateProjectStep = async (values) => {
        const updateFunction = isAnonymous ? projects.bespoke.updateStep2Anonymous : projects.bespoke.updateStep2;
        await updateFunction(companyId, projectId, isAnonymous ? guid : values, values);
    };

    const onSubmit = async (values) => {
        if (isAnonymous && !guid) {
            console.log("Invalid anonymous id");
            return;
        }
        await updateProjectStep(values);
        if(onNext !== undefined) {
            onNext(nextStep);
            return;
        }

        history.push(nextStep);
    };

    const validationSchema = Yup.object().shape({
        jobTitle: Yup.string().required(t('general.required')),
        description: Yup.string().required(t('general.required'))
    });

    const projectStepProps = {
        companyId,
        projectId,
        heading: '②',
        subheading: t('project.all.step2.title'),
        onSubmit,
        next: nextStep,
        previous: `/create-project`,
        progress: 50,
        validationSchema,
        isAnonymous
    };

    return (
        <ProjectStep {...projectStepProps}>
            {() => <Step2Content/>}
        </ProjectStep>
    );
};

export default BespokeStep2;