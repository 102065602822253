import { useState, useEffect } from 'react';
import { useApi } from '../../hooks/useApi.jsx';
import useLocalStorageUtil from "../../utils/localStorageUtils";

const useEntity = (getter, params) => {
    const [entity, setEntity] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            (async () => {

                const response = await getter(...params);
                const entity = await response.json();
                setEntity(entity);
                setLoading(false);
            })();
        }
    }, [loading, getter, params]);

    return { entity, setLoading, loading };
};

const useProject = (companyId, projectId, isAnonymous) => {
    const { projects: projectApi } = useApi();
    const [guid] = useLocalStorageUtil();
    const apiMethod = isAnonymous ? projectApi.getAnonymous : projectApi.get;

    return useEntity(apiMethod, [companyId, projectId, isAnonymous ? guid : undefined]);
};


const useLengthOfVideoTypes = () => {
    const { lengthOfVideoTypes } = useApi();
    return useEntity(lengthOfVideoTypes.get, []);
};

const useVideoVersionTypes = () => {
    const { videoVersionTypes } = useApi();
    return useEntity(videoVersionTypes.get, []);
};

const useChannelBrandingFinalFileVersionTypes = () => {
    const { channelBrandingFinalFileVersionTypes } = useApi();
    return useEntity(channelBrandingFinalFileVersionTypes.get, []);
};

const useMotionGraphicsFinalFileVersionTypes = () => {
    const { motionGraphicsFinalFileVersionTypes } = useApi();
    return useEntity(motionGraphicsFinalFileVersionTypes.get, []);
};

const useTranslationDubbingFinalFileVersionTypes = () => {
    const { translationDubbingFinalFileVersionTypes } = useApi();
    return useEntity(translationDubbingFinalFileVersionTypes.get, []);
};

const useVideoEditingSoftwareTypes = () => {
    const { videoEditingSoftwareTypes } = useApi();
    return useEntity(videoEditingSoftwareTypes.get, []);
};

const useGraphicEditingSoftwareTypes = () => {
    const { graphicSoftwareTypes } = useApi();
    return useEntity(graphicSoftwareTypes.get, []);
};

const useLanguages = () => {
    const { languages } = useApi();
    return useEntity(languages.get, []);
};

const useProjectActivityLogs = (projectId, pageNumber, pageSize) => {
    const { projectActivityLogs } = useApi();
    return useEntity(projectActivityLogs.get, [ projectId, pageNumber, pageSize ]);
};

export {
    useProject,
    useLengthOfVideoTypes,
    useVideoVersionTypes,
    useChannelBrandingFinalFileVersionTypes,
    useMotionGraphicsFinalFileVersionTypes,
    useTranslationDubbingFinalFileVersionTypes,
    useVideoEditingSoftwareTypes,
    useGraphicEditingSoftwareTypes,
    useLanguages,
    useProjectActivityLogs
};
