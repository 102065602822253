import {Button, Heading, HStack, Link, Spacer, Text, VStack} from '@chakra-ui/react';
import {Formik} from 'formik';
import {InputControl, ProfilePhotoUploadControl} from '../forms';
import {useApi} from '../../hooks/useApi.jsx';
import {useAuth} from '../../hooks/useAuth.jsx';
import humps from 'humps';
import BaseCard from "../baseCard";

const SignupFreelancer = ({onSubmit}) => {
    const {profile: profileApi} = useApi();
    const {reloadProfile} = useAuth();

    const handleSubmit = async (values, props) => {
        const response = await profileApi.signupFreelancer(values.firstName, values.lastName);
        if (response.ok) {
            await reloadProfile(values.firstName);
            onSubmit();
        } else {
            const badRequest = await response.json();
            if (badRequest.errors) {
                const camelized = humps.camelizeKeys(badRequest.errors);
                props.setErrors(camelized);
            }
        }
    }

    return <BaseCard
        maxWidth={'80rem'}
        padding={6}
        borderRadius={'20px'}
        width={'400px'}
    >
        <Heading textAlign='center' size={'lg'} pt="0.5rem">Tell us about yourself</Heading>
        <Text align={'center'} fontSize={'sm'}>Set up your profile page and let others know who they collaborate
            with!</Text>
        <ProfilePhotoUploadControl/>
        <Formik initialValues={{firstName: '', lastName: ''}}
                onSubmit={handleSubmit} width={'100%'}>
            {(props) => (
                <form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                    <VStack alignItems="center" width="100%">
                        <InputControl name="firstName" labelKey="profile.firstName"/>
                        <InputControl name="lastName" labelKey="profile.lastName"/>

                    </VStack>
                    <HStack mt="30px">
                        <Link variant='projectBack' href={'/'}>
                            I'll do this later
                        </Link>
                        <Spacer/>
                        <Button type="submit" variant='primary'>Next</Button>
                    </HStack>
                </form>
            )}
        </Formik>
    </BaseCard>
};

export default SignupFreelancer;