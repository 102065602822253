import {Flex, Grid, GridItem, VStack} from "@chakra-ui/react";
import {
    DatePickerControl,
    LanguagePicker,
    RadioControl,
    RadioGroupControl,
    SwitchControl,
    VideoEditingSoftwareTypePicker,
    VideoVersionTypePicker
} from "../../forms";
import {useTranslation} from "react-i18next";

const Step3Content = ({formikProps}) => {
    const { t } = useTranslation();
    return (
        <Flex direction={['column', null, 'row']} p={3} w="full">
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]}>

                <VideoVersionTypePicker
                    name="videoVersions"
                    labelKey="project.videoEditing.step3.videoVersions"
                />

                <VideoEditingSoftwareTypePicker
                    name="videoEditingSoftware"
                    labelKey="project.videoEditing.step3.videoEditingSoftware"
                />


                <SwitchControl
                    name="wantThumbnail"
                    labelKey="project.videoEditing.step3.wantThumbnail"
                />


                <SwitchControl
                    name="asap"
                    labelKey="project.all.step3.asap"
                />


                <SwitchControl
                    name="receiveProjectFiles"
                    labelKey="project.all.step3.receiveProjectFiles"
                />
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6}>

                <DatePickerControl
                    name="deadline"
                    labelKey="project.all.step3.deadline"
                />

                <SwitchControl
                    name="localize"
                    labelKey="project.all.step3.localize"
                />

                {formikProps?.values?.localize && (
                    <>
                        <Grid templateColumns='auto 1fr auto 1fr' templateRows='1fr 1fr' gap={2}>
                            <GridItem mt="4px" fontSize={"13px"} pr={"4px"}>From</GridItem>
                            <GridItem><LanguagePicker name="localizeFrom"/></GridItem>
                            <GridItem mt="4px" fontSize={"13px"} pr={"4px"}>To</GridItem>
                            <GridItem><LanguagePicker name="localizeTo"/></GridItem>

                            <GridItem colSpan="4" mt={2}>
                                <RadioGroupControl name="howToLocalize" labelKey="project.all.step3.howToLocalize">
                                    <RadioControl value="Captions">{t('project.all.step3.captions')}</RadioControl>
                                    <RadioControl value="Dubbing">{t('project.all.step3.dubbing')}</RadioControl>
                                    <RadioControl
                                        value="CaptionsAndDubbing">{t('project.all.step3.captionsAndDubbing')}</RadioControl>
                                </RadioGroupControl>
                            </GridItem>
                        </Grid>
                    </>
                )}

            </VStack>
        </Flex>
    )
}

export default Step3Content;