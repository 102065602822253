import {Box, Flex, Text, VStack} from "@chakra-ui/react";
import {format} from 'date-fns';
const DetailDeliverables = ({project}) => {
    return (
        <Flex direction={['column', null, 'row']} w="full" >
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]} alignItems={"start"}>
                <Box>
                    <Text fontWeight="bold">Check the deliverables:</Text>
                    <ol>
                        {project.finalFileVersions.map((e, i) => (
                            <li
                                style={{
                                    float: 'left',
                                    marginLeft: '1em',
                                    marginRight: '1em',
                                }}
                                key={i}
                            >
                                {e.translationDubbingFinalFileVersionType.name}
                            </li>
                        ))}
                    </ol>
                </Box>
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6} alignItems={"start"}>
                <Box>
                    <Text fontWeight="bold">Project Deadline</Text>
                    <Text>
                        {project.asap
                            ? 'As soon as possible'
                            : project.deadline
                                ? format(
                                    new Date(project.deadline),
                                    'dd/MM/yyyy'
                                )
                                : null}
                    </Text>
                </Box>
            </VStack>
        </Flex>
    )
}
export default DetailDeliverables;