import {Box, Flex, Text, VStack} from "@chakra-ui/react";

const DetailDeliverables = ({project}) => {
    return (
        <Flex direction={['column', null, 'row']} w="full">
            <VStack width={['100%', null, '50%']} spacing={6} alignItems="start">
                <Box colSpan="2">
                    <Text fontWeight='bold'>Check the deliverables:</Text>
                    <ol>
                        {project.finalFileVersions?.map((version, i) => (
                            <li style={{float: 'left', marginLeft: '1em', marginRight: '1em'}} key={i}>
                                {version.motionGraphicsFinalFileVersionType.name}
                            </li>
                        ))}
                    </ol>
                </Box>

                <Box>
                    <Text fontWeight='bold'>Formats:</Text>
                    <Text>{project.formats ? 'Yes' : 'No'}</Text>
                </Box>

                <Box>
                    <Text fontWeight='bold'>Do you want to receive project files?</Text>
                    <Text>{project.receiveProjectFiles ? 'Yes' : 'No'}</Text>
                </Box>
            </VStack>
        </Flex>
    )
}

export default DetailDeliverables;