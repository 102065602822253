import { defineStyle, defineStyleConfig } from '@chakra-ui/react'


const baseStyle = defineStyle({
    filledTrack: {
        bg: 'brand.green',
    },
    marginTop: "1em",
    width: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "12px",
    backgroundColor: "brand.gray", // For the background color of the uncompleted part
    height: "sm", // Assuming you meant to set the height to 'sm', modify as needed
});



export const progressTheme = defineStyleConfig({
  baseStyle,
});