import {useApi} from "../../hooks/useApi";
import React, {useCallback, useEffect, useState} from "react";
import ComponentLoading from "../ComponentLoading";
import {
    Box,
    Card,
    CardBody,
    Flex,
    FormLabel,
    Heading,
    HStack,
    Icon, Input, InputGroup, InputLeftAddon, InputLeftElement,
    Link,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";
import ProjectRow from "./projectRow";
import ProjectCard from "./projectCard";
import useLoading from "../../hooks/useLoading";
import {useTranslation} from "react-i18next";
import DashboardViewPicker from "./dashboardViewPicker";
import {FaSearch} from "react-icons/fa";
import DatePicker from "react-datepicker";
import moment from "moment";

const ProjectPanel = ({user}) => {
    const u = user;
    const {t} = useTranslation();
    const {profile: profileApi} = useApi();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useLoading();
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [dateFilter, setDateFilter] = useState('');
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState('');

    const pageSize = 5;
    const [view, setView] = useState('AllProjects');

    useEffect(() => {
        fetchAndUpdateProjects();
    }, [user, pageNumber, pageSize, dateFilter, debouncedSearchText, view, setLoading]);


    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 500); // 500 ms delay

        return () => {
            clearTimeout(timerId);
        };
    }, [searchText]);

    const fetchAndUpdateProjects = async () => {
        if (!user.isLoggedIn) return;

        setLoading(true);
        const response = await profileApi.getProjects(user.isFreelanceMode, view, dateFilter ?? '', debouncedSearchText, pageNumber, pageSize);
        const data = await response.json();
        setProjects(data.projects);
        setTotalPages(data.totalPages);
        setLoading(false);
    };


    const handlePageClick = (page) => {
        setPageNumber(page);
    };

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleDateChange = (date) => {
        if (date) {
            const formattedDate = moment(date).locale('en-gb').format('YYYY-MM-DD');
            setDateFilter(formattedDate);
        } else {
            setDateFilter("");
        }
    };

    const handleViewPicker = (view) => {
        setLoading(true);
        setView(view);
        setPageNumber(1);
    }

    return <VStack alignItems="left">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Heading size={'md'} marginTop={0}>{t('dashboard.projects.title')}</Heading>
            <HStack gap="0.5rem">
                <Flex direction={['column', 'row']}>
                    <Box>
                        <DashboardViewPicker
                            value={view}
                            setValue={view => handleViewPicker(view)}
                        />
                    </Box>
                    <Spacer/>
                </Flex>
                <Flex direction="column">
                    <DatePicker
                        placeholderText='Select date'
                        showIcon
                        isClearable
                        style={{fontSize: "13px"}}
                        selected={dateFilter ? moment(dateFilter).toDate() : null}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => handleDateChange(date)}
                    />
                </Flex>
                <Flex direction="column">
                    <InputGroup>
                        <InputLeftElement children={<Icon as={FaSearch}/>}/>
                        <Input variant='filter' value={searchText} onChange={handleSearchTextChange}
                               placeholder='Search...'/>
                    </InputGroup>
                </Flex>
            </HStack>
        </Flex>
        { loading ? <ComponentLoading/> :
            <Card width="100%" height="420px">
            <CardBody>
                <TableContainer display={['none', null, 'block']}>
                    <Table size="sm" className="table-tiny" variant='simple'>
                        <Thead>
                            <Tr>
                                <Th textAlign={'center'}>{t('dashboard.projects.datecreated')}</Th>
                                <Th textAlign={'center'}>{t('dashboard.projects.name')}</Th>
                                <Th textAlign={'center'}>{t('dashboard.projects.tags')}</Th>
                                <Th textAlign={'center'}>{t('dashboard.projects.progress')}</Th>
                                <Th textAlign={'center'}>{t('dashboard.projects.duedate')}</Th>
                                <Th textAlign={'center'}>{!user.isFreelanceMode && user.isAdmin && t('dashboard.projects.assignedTo')}</Th>
                                <Th textAlign={'center'}>&nbsp;</Th>

                            </Tr>
                        </Thead>
                        <Tbody>
                            {projects &&
                            projects.length > 0
                                ? projects.map(p => <ProjectRow project={p} user={u} key={p.projectId} onProjectDeleted={fetchAndUpdateProjects}/>)
                                : <Tr>
                                    <Td colSpan={6}>{t('dashboard.no_projects')}</Td>
                                </Tr>
                            }

                        </Tbody>
                    </Table>
                </TableContainer>

                <VStack display={['flex', null, 'none']} gap="2em" alignItems="left">
                    {projects &&
                    projects.length > 0
                        ? projects.map((p) => <ProjectCard project={p} user={u} key={p.projectId}/>)
                        : <Box>{t('dashboard.no_projects')}</Box>}
                </VStack>

                <Flex justifyContent="flex-end" mt={4} mr={4} alignItems="center">
                    <Spacer/>
                    <Box
                        as="span"
                        onClick={() => handlePageClick(pageNumber - 1)}
                        disabled={pageNumber === 1}
                        cursor={pageNumber === 1 ? "default" : "pointer"}
                        fontSize="lg"
                    >
                        &lt;
                    </Box>

                    {/* Display the first page and possibly an ellipsis */}
                    {pageNumber > 2 && (
                        <Box as="span" onClick={() => handlePageClick(1)} cursor="pointer" mx={1}>
                            1
                        </Box>
                    )}
                    {pageNumber > 3 && <Box as="span" mx={1}>...</Box>}

                    {/* Display one page before the current page if it's not the first page */}
                    {pageNumber > 1 && (
                        <Box as="span" onClick={() => handlePageClick(pageNumber - 1)} cursor="pointer" mx={1}>
                            {pageNumber - 1}
                        </Box>
                    )}

                    {/* Display the current page */}
                    <Box as="span" mx={1} fontWeight="bold">
                        {pageNumber}
                    </Box>

                    {/* Display one page after the current page if it's not the last page */}
                    {pageNumber < totalPages && (
                        <Box as="span" onClick={() => handlePageClick(pageNumber + 1)} cursor="pointer" mx={1}>
                            {pageNumber + 1}
                        </Box>
                    )}

                    {/* Display an ellipsis and the last page if needed */}
                    {pageNumber < totalPages - 2 && <Box as="span" mx={1}>...</Box>}
                    {pageNumber < totalPages - 1 && (
                        <Box as="span" onClick={() => handlePageClick(totalPages)} cursor="pointer" mx={1}>
                            {totalPages}
                        </Box>
                    )}

                    <Box as="span" onClick={() => handlePageClick(pageNumber + 1)} disabled={pageNumber === totalPages}
                         cursor="pointer" fontSize="lg">
                        &gt;
                    </Box>
                </Flex>
            </CardBody>
        </Card>}
    </VStack>
};
export default ProjectPanel;

