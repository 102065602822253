import React, {useState} from 'react';
import {useAuth} from '../../hooks/useAuth.jsx';
import {useApi, usePublicApi} from '../../hooks/useApi.jsx';
import {useHistory} from 'react-router-dom';
import SignupUser from './signupUser.jsx';
import SignupUserType from './signupUserType.jsx';
import {useTranslation} from 'react-i18next';
import humps from 'humps';
import SignupProjectDetails from "./signupProjectDetails";
import ChannelBrandingStep2 from "../projects/channelBranding/channelBraningStep2";
import VideoEditingStep2 from "../projects/videoEditing/videoEditingStep2";
import BespokeStep2 from "../projects/bespoke/bespokeStep2";
import CameraOperatorStep2 from "../projects/cameraOperator/cameraOperatorStep2";
import MotionGraphicsStep2 from "../projects/motionGraphics/motionGraphicsStep2";
import TranslationDubbingStep2 from "../projects/translationDubbing/translationDubbingStep2";
import {v4 as uuid} from 'uuid';
import useLocalStorageUtil from "../../utils/localStorageUtils";
import {SignupSteps} from "./signupSteps";
import SignupFreelancer from "./signupFreelancer";
import SignupCompany from "./signupCompany";

const Signup = () => {
    const [currentStep, setCurrentStep] = useState(SignupSteps.SELECT_USER_TYPE);
    const [nextStepAfterSignup, setNextStepAfterSignup] = useState(null);
    const [selectedProjectType, setSelectedProjectType] = useState(null);
    const [userType, setUserType] = useState('company');
    const [guid, companyId, projectId, updateLocalStorage, clearLocalStorage] = useLocalStorageUtil();

    const auth = useAuth();
    const history = useHistory();
    const {t} = useTranslation();
    const {company, projects} = useApi();
    const {account: accountApi} = usePublicApi();

    const handleGoogleSignupSubmit = async (values, props) => {
        clearLocalStorage();
        if(userType === 'freelancer') {
            setCurrentStep(SignupSteps.ADD_FREELANCER_DETAILS);
            return;
        }
        setCurrentStep(SignupSteps.ADD_COMPANY_DETAILS);
    }

    const handleSignupUserSubmit = async (values, props) => {
        try {
            const response = await accountApi.signup(values.email, values.password, guid);

            if (!response.ok) {
                await handleErrorResponse(response, props);
                return;
            }

            const isFreelancer = userType === 'freelancer';
            const loginResponse = await response.json();
            await auth._setUserWithToken(true, false, false, loginResponse.token, isFreelancer );

            clearLocalStorage();

            if(userType === 'freelancer') {
                setCurrentStep(SignupSteps.ADD_FREELANCER_DETAILS);
                return;
            }
            setCurrentStep(SignupSteps.ADD_COMPANY_DETAILS);

        } catch (error) {
            console.error("Signup error:", error);
            props.setErrors({'email': t('general.somethingwentwrong')});
        }
    };

    const handleErrorResponse = async (response, props) => {
        const errorResponse = await response.json();
        const error = response.status === 409 ? {'email': t('signup.userexists')} :
            response.status === 400 ? humps.camelizeKeys(errorResponse) :
                {'email': t('general.somethingwentwrong')};

        props.setErrors(error);
    };



    const handleProjectSubmit = async (projectType) => {
        setSelectedProjectType(projectType);
        await createTemporaryProject(projectType);
        setCurrentStep(SignupSteps.PROJECT_STEP_2);
    };

    const handleUserType = async (userType) => {
        setUserType(userType);
        if (userType === 'company') {
            await createTemporaryCompany();
            setCurrentStep(SignupSteps.SELECT_PROJECT_TYPE);
        } else if (userType === 'freelancer') {
            setCurrentStep(SignupSteps.CREATE_ACCOUNT);
        }
    }

    const getCompanyId = async (anonymousId) => {
        const response = await company.getAnonymousById(anonymousId);
        const data = await response.json();
        updateLocalStorage({companyId: data.companyId});
        return data.companyId;
    };


    const createTemporaryCompany = async () => {
        let currentCompanyId = companyId;
        let currentGuid = guid;

        if (currentGuid) {
            if (!currentCompanyId) {
                currentCompanyId = await getCompanyId(currentGuid);
                if (!currentCompanyId) {
                    const response = await company.createAnonymous(currentGuid);
                    const companyData = await response.json();
                    currentCompanyId = companyData.companyId;
                }
            }
        } else {
            currentGuid = uuid();
            const response = await company.createAnonymous(currentGuid);
            const companyData = await response.json();
            currentCompanyId = companyData.companyId;
        }

        updateLocalStorage({guid: currentGuid, companyId: currentCompanyId});
    };

    const createTemporaryProject = async (projectType) => {
        if (!guid) {
            console.log("Invalid guid");
            return;
        }

        const response = await projects[projectType].createAnonymous(companyId, guid);
        const projectData = await response.json();
        const tempProjectId = projectData.projectId;

        updateLocalStorage({projectId: tempProjectId});
    };

    const handleCreateAccount = (nextStep) => {
        setNextStepAfterSignup(nextStep);
        setCurrentStep(SignupSteps.CREATE_ACCOUNT);

    };

    const handleCompanyDetailsSubmit = () => {
        history.push(nextStepAfterSignup);
    }

    const handleFreelancerDetailsSubmit = () => {
        history.push('/');
    }

    const renderStep2Component = () => {
        switch (selectedProjectType) {
            case 'channelBranding':
                return <ChannelBrandingStep2 projectId={projectId} companyId={companyId} isAnonymous={true}
                                             onNext={handleCreateAccount}/>;
            case 'videoEditing':
                return <VideoEditingStep2 projectId={projectId} companyId={companyId} isAnonymous={true}
                                          onNext={handleCreateAccount}/>;
            case 'bespoke':
                return <BespokeStep2 projectId={projectId} companyId={companyId} isAnonymous={true}
                                     onNext={handleCreateAccount}/>;
            case 'cameraOperator':
                return <CameraOperatorStep2 projectId={projectId} companyId={companyId} isAnonymous={true}
                                            onNext={handleCreateAccount}/>;
            case 'motionGraphics':
                return <MotionGraphicsStep2 projectId={projectId} companyId={companyId} isAnonymous={true}
                                            onNext={handleCreateAccount}/>;
            case 'translationDubbing':
                return <TranslationDubbingStep2 projectId={projectId} companyId={companyId} isAnonymous={true}
                                                onNext={handleCreateAccount}/>
            default:
                return;
        }
    }

    const renderStep = () => {
        if (auth.user.isLoggedIn && currentStep === SignupSteps.SELECT_USER_TYPE) {
            history.push('/');
            return;
        }
        switch (currentStep) {
            case SignupSteps.SELECT_USER_TYPE:
                return <SignupUserType onNext={handleUserType}/>;
            case SignupSteps.SELECT_PROJECT_TYPE:
                return <SignupProjectDetails onNext={handleProjectSubmit}/>;
            case SignupSteps.PROJECT_STEP_2:
                return renderStep2Component();
            case SignupSteps.CREATE_ACCOUNT:
                return <SignupUser onSubmit={handleSignupUserSubmit} onGoogleSubmit={handleGoogleSignupSubmit} userType={userType}/>
            case SignupSteps.ADD_COMPANY_DETAILS:
                return <SignupCompany onSubmit={handleCompanyDetailsSubmit}/>
            case SignupSteps.ADD_FREELANCER_DETAILS:
                return <SignupFreelancer onSubmit={handleFreelancerDetailsSubmit}/>
            default:
                return null;
        }
    };

    return <>
        {renderStep()}
    </>
}
export default Signup;
