import React from 'react';
import { useFormikContext, useField } from "formik";
import {
    useRadioGroupContext,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText, Stack
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.css";

// https://chakra-ui.com/community/recipes/advanced-formik-integration
const RadioControl = React.forwardRef((props, ref) => {

    const { children, name, ...radioProps } = props;
    const group = useRadioGroupContext();
    const isChecked = group.value.toString() === props.value.toString();

    const [{ checked, ...field }] = useField({
        name,
        type: "radio",
        value: radioProps.value.toString(),
        checked: isChecked
    });
    return <Radio {...field} paddingRight={2}> {children}</ Radio>;
});

const RadioGroupControl = ({ name, children, labelKey, helperTextKey, vertical = false,...props }) => {
    const { t } = useTranslation();
    const { errors, touched } = useFormikContext();
    const [field, { setValue }] = useField({ name, value: props.value });
    const flexDirection = vertical ? "column" : "row";

    const namedChildren = React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return;
        }

        return React.cloneElement(child, { name });
    });

    return <FormControl isInvalid={errors && errors[name] && touched[name]} paddingRight={5}>
        <FormLabel htmlFor={name}>{t(labelKey)}</FormLabel>
        <RadioGroup {...props} {...field} onChange={setValue} flexDirection={flexDirection} children={namedChildren} />
        {helperTextKey && <FormHelperText>{t(helperTextKey)}</FormHelperText>}
        <FormErrorMessage>{errors && errors[name]}</FormErrorMessage>
    </FormControl>;
};

export {
    RadioControl,
    RadioGroupControl
}