import {Progress} from "@chakra-ui/react";

const ProgressBar = ({progress}) => (
    <Progress
        value={progress}
        marginTop="1em"
        size='sm'
        w={"400px"}
        marginLeft="auto"
        marginRight="auto"
        borderRadius={"12px"}
        bg="brand.gray"
        marginBottom="46px"
    />
)

export default ProgressBar;