import {Button, Card, CardBody, Center, Container, Heading, HStack, Link, Spacer, VStack} from '@chakra-ui/react';
import {Formik} from 'formik';
import humps from 'humps';
import {useHistory} from 'react-router-dom';
import {useProject} from '../entities';
import ComponentLoading from '../ComponentLoading.jsx';
import ProgressBar from "./progressBar";

const ProjectStep = (props) => {
    const {
        companyId,
        projectId,
        heading,
        subheading,
        onSubmit,
        previous,
        next,
        progress,
        validationSchema,
        children,
        isAnonymous
    } = props;


    const {entity: project, loading} = useProject(companyId, projectId, isAnonymous);
    const history = useHistory();

    if (loading) {
        return <ComponentLoading/>;
    }

    const handleSubmit = async (values, props) => {
        const response = await onSubmit(values);

        if (response !== undefined && response?.status === 400) {
            const badRequest = await response.json();
            if (badRequest.errors) {
                const camelized = humps.camelizeKeys(badRequest.errors);
                props.setErrors(camelized);
            }
            return;
        }

        props.setSubmitting(false);
        if (!isAnonymous) {
            history.push(next);
        }
    }

    return <Container maxW={'5xl'} padding={6}>
        <Card>
            <CardBody>
                <Center marginBottom="1em">
                    <VStack>
                        <Heading size='2xl'>{heading}</Heading>
                        <Heading>{subheading}</Heading>
                    </VStack>
                </Center>
                <ProgressBar
                    progress={progress}
                />

                <Formik onSubmit={handleSubmit} initialValues={project} validationSchema={validationSchema}>
                    {(props) => {
                        return <form onSubmit={props.handleSubmit}>
                            <Center bg="content_fill" padding='10' alignItems="stretch">
                                {children(project, props.values)}
                            </Center>

                            <HStack paddingLeft='10' paddingRight='10' marginTop='5'>
                                <Link href={previous}>Previous step</Link>
                                <Spacer/>
                                <Button type="submit" variant='primary'>
                                    Next
                                </Button>
                            </HStack>
                        </form>
                    }
                    }
                </Formik>
            </CardBody>
        </Card>

    </Container>;
};

export default ProjectStep;