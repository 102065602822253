import {Divider, Heading, HStack, useDisclosure} from "@chakra-ui/react";
import {EditButton} from "./editButton";
import EditProjectModal from "./editProjectModal";
import {cloneElement, useRef} from "react";

export const ProjectDetailsDeliverables = ({onEdit, children}) => {
    return <>
        <HStack mt={8}>
            <Heading size='md' textTransform='uppercase' mt={0}>Deliverables:</Heading>
            <EditButton onEdit={onEdit}></EditButton>
        </HStack>

        <Divider borderColor='black'/>
        {children}
    </>
};
