import {Box, Flex, ListItem, OrderedList, Text} from "@chakra-ui/react";
import {format} from 'date-fns';

const DetailDeliverables = ({project}) => (
    <Flex direction={['column', null, 'row']} wrap="wrap" spacing={6}>
        {/*<Box width={['100%', null, '100%']} mb={6}>*/}
        {/*    <Text fontWeight='bold'>Check the deliverables:</Text>*/}
        {/*    <OrderedList>*/}
        {/*        {project.finalFileVersions?.map((e, i) => (*/}
        {/*            <ListItem ml="1em" mr="1em" key={i} display="inline">*/}
        {/*                {e}*/}
        {/*            </ListItem>*/}
        {/*        ))}*/}
        {/*    </OrderedList>*/}
        {/*</Box>*/}
        {/**/}
        {/*<Box width={['100%', null, '50%']} mb={6}>*/}
        {/*    <Text fontWeight='bold'>Do you want to receive project files?</Text>*/}
        {/*    <Text>{project.receiveProjectFiles ? 'Yes' : 'No'}</Text>*/}
        {/*</Box>*/}

        <Box width={['100%', null, '50%']} mb={6}>
            <Text fontWeight='bold'>Project Deadline</Text>
            <Text>
                {project.asap ?
                    'As soon as possible' :
                    project.deadline ? format(new Date(project.deadline), 'dd/MM/yyyy') : null}
            </Text>
        </Box>
    </Flex>
);

export default DetailDeliverables;
