import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useApi} from '../../../hooks/useApi.jsx';
import * as Yup from 'yup';
import ProjectStep from '../projectStep.jsx';
import Step3Content from "./step3Content";

const Step3 = ({next, previous}) => {
    const { t } = useTranslation();
    const { projects } = useApi();
    const { companyId, projectId } = useParams();

    const onSubmit = async (values) => await projects.videoEditing.updateStep3(companyId, projectId, values);

    const validationSchema = Yup.object().shape({
        wantThumbnail: Yup.bool(),
        receiveProjectFiles: Yup.bool()
    });

    const projectStepProps = {
        companyId,
        projectId,
        heading: '③',
        subheading: t('project.all.step3.title'),
        onSubmit,
        next: next !== undefined ? next : `/company/${companyId}/video-editing-project/${projectId}/step-4`,
        previous: previous !== undefined ? previous :`/company/${companyId}/video-editing-project/${projectId}/step-2`,
        progress: 75,
        validationSchema
    };

    return (
        <ProjectStep {...projectStepProps}>
            {(project, props) => (
                <Step3Content formikProps={props}/>
            )}
        </ProjectStep>
    );
}

export default Step3;