import React, { useState } from 'react';
import {
    Box,
    HStack,
    Text,
    Input,
    Button
} from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';

const ChatInput = (props) => {
    const [message, setMessage] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        if (props.isDisabled) {
            return;
        }

        const isMessageProvided = message && message !== '';

        if (isMessageProvided) {
            props.sendMessage(message);
            setMessage('');
        }
        else {
            alert('Please enter a message.');
        }
    }


    const onMessageUpdate = (e) => {
        setMessage(e.target.value);
    }

    return (
        <form onSubmit={onSubmit} className="chat-input">
            <HStack alignItems="center" padding="10px">
                <Input type="text" id="message" value={message} onChange={onMessageUpdate} variant="chat" disabled={props.isDisabled} />
                <Button p={0} type="submit" disabled={props.isDisabled}><ChatIcon boxSize={4} /></Button>
            </HStack>
        </form>
    )
};

export default ChatInput;