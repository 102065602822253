import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    badge: {
        bg: 'gray.500',
        border: '5px solid #3B9D97',
    },
    container: {
        borderRadius: 'xl'
    },
    excessLabel: {
        bg: 'gray.800',
        color: 'white',
        borderRadius: 'xl',
    },
});

const roundedSquare = definePartsStyle({
    badge: {
        bg: "gray.500",
        border: "2px solid"
    },
    container: {
        borderRadius: "xl"
    },
    excessLabel: {
        bg: "gray.800",
        color: "white",
        borderRadius: "xl",
        border: "2px solid",

        // let's also provide dark mode alternatives
        _dark: {
        bg: "gray.400",
        color: "gray.900"
        }
    }
})

export const avatarTheme = defineMultiStyleConfig({ 
    baseStyle,
    variants: { roundedSquare }
});