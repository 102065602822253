import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import {Formik} from "formik";

export const GenericModal = ({isOpen, onClose, title, children, initialValues, onSubmit}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay/>
            <ModalContent maxWidth='4xl'>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton/>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <ModalBody>
                                {children(formikProps)}
                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit" mr={3}>Save</Button>
                                <Button onClick={onClose}>Cancel</Button>
                            </ModalFooter>
                        </form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};
