import React from 'react';
import {useField, useFormikContext} from "formik";
import {FormControl, FormErrorMessage, FormHelperText, FormLabel, Radio, RadioGroup} from "@chakra-ui/react";
import {useTranslation} from 'react-i18next';

export default ({name, labelKey, helperTextKey, ...props}) => {
    const {t} = useTranslation();
    const [field] = useField(name);
    const {errors, touched} = useFormikContext();

    const value = field.value ?? false.toString();

    const handleChange = (value) => {
        field.onChange({target: {name: name, value: value === 'true'}});
    }

    const required = props?.required ?? false;
    return <FormControl isInvalid={errors && errors[name] && touched[name]} paddingRight={5}>
        <FormLabel htmlFor={name}>
            {t(labelKey)}
            {required && <span style={{color: 'red'}}>*</span>}</FormLabel>
        <RadioGroup {...field} {...props} value={value.toString()} onChange={handleChange}>
            <Radio value={true.toString()} paddingRight={'10px'}>Yes</Radio>
            <Radio value={false.toString()}>No</Radio>
        </RadioGroup>

        {helperTextKey && <FormHelperText>{t(helperTextKey)}</FormHelperText>}
        <FormErrorMessage>{errors && errors[name]}</FormErrorMessage>
    </FormControl>
};