import Control from './control.jsx';
import { RadioControl, RadioGroupControl } from './radioGroupControl.jsx';
import SwitchControl from './switchControl.jsx';
import { ProfilePhotoUploadControl, CompanyAssetUploadControl } from './fileUploadControl.jsx';
import { DatePickerControl } from './datePickerControl.jsx';
import {
    Textarea,
    Input
} from "@chakra-ui/react";
import {
    LengthOfVideoTypePicker,
    LanguagePicker,
    VideoVersionTypePicker,
    ChannelBrandingFinalFileVersionTypePicker,
    MotionGraphicsFinalFileVersionTypePicker,
    TranslationDubbingFinalFileVersionTypePicker,
    VideoEditingSoftwareTypePicker,
    GraphicEditingSoftwareTypePicker
} from './relatedEntityPickers.jsx';
import { ControlListControl } from './controlList.jsx';
import { Invitation } from './invitationControl.jsx';
import { HeroSelector, RadioGroup } from './heroSelector.jsx';
import { ColourPicker } from './colourPicker.jsx';

const TextareaControl = (props) => Control({ Component: Textarea, ...props });
const InputControl = (props) => Control({ Component: Input, ...props });

export {
    InputControl,
    TextareaControl,
    SwitchControl,
    DatePickerControl,
    RadioGroupControl,
    RadioControl,
    ProfilePhotoUploadControl,
    CompanyAssetUploadControl,
    LengthOfVideoTypePicker,
    ControlListControl,
    VideoVersionTypePicker,
    ChannelBrandingFinalFileVersionTypePicker,
    MotionGraphicsFinalFileVersionTypePicker,
    TranslationDubbingFinalFileVersionTypePicker,
    VideoEditingSoftwareTypePicker,
    GraphicEditingSoftwareTypePicker,
    LanguagePicker,
    Invitation,
    HeroSelector,
    RadioGroup,
    ColourPicker
};