import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Heading,
    Image,
    Center,
    FormErrorMessage,
    Container,
    Grid,
    GridItem,
    Card,
    CardBody
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useApi } from '../hooks/useApi.jsx';


export default function ForgotPasswordForm() {
    const { profile: profileService } = useApi();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        onSubmit: async (values) => {
            const clientUri = window.location.protocol + "//" + window.location.host + "/reset-password";

            const response = await profileService.forgotPassword(values.email, clientUri);
            if (response.ok) {
                formik.setStatus(t('forgotpassword.checkyouremail'));
            } else {
                formik.setStatus(t('general.somethingwentwrong'));
            }
        }
    });

    return <Container maxW={'80rem'} padding={6}>
        <Grid
            templateColumns={['minmax(0, 1fr)', null, 'max-content minmax(0, 1fr)']}
            gap='2em'
            marginTop='2em'>

            <GridItem>
                <Card>
                    <CardBody padding='4em'>
                        <VStack>
                            <Heading>{t('forgotpassword.title')}</Heading>
                            <p>{t('forgotpassword.remindus')}</p>
                            <form onSubmit={formik.handleSubmit}>
                                <VStack spacing={4} align="flex-start">
                                    <FormControl isInvalid={!!formik.status}>
                                        {!!formik.status && <FormErrorMessage>{formik.status}</FormErrorMessage>}
                                        <FormLabel htmlFor="email">{t('forgotpassword.email')}</FormLabel>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            width='30ch' 
                                        />
                                    </FormControl>

                                    <VStack width="100%">
                                        <Button type="submit" width="full">
                                            {t('forgotpassword.submit')}
                                        </Button>
                                    </VStack>
                                </VStack>
                            </form>
                        </VStack>
                    </CardBody>
                </Card>
            </GridItem>


            <GridItem>
                <Image src="/images/signup-hero.png" style={{
                    'height': '100%',
                    'aspectRatio': '868/587',
                    'objectFit': 'cover'
                }} />
            </GridItem>
        </Grid>
    </Container>;
}