import React, {useEffect, useReducer, useRef} from 'react';
import {useField} from "formik";
import {Button, FormControl, FormLabel, HStack, Icon, Image, Link, Spinner, VStack} from "@chakra-ui/react";
import {useTranslation} from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.css";
import {BlockBlobClient, newPipeline} from '@azure/storage-blob';
import {useApi} from '../../hooks/useApi.jsx';
import {useAuth} from '../../hooks/useAuth.jsx';
import Avatar from '../Avatar.jsx';
import {FaUpload} from "react-icons/fa";

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE':
            let items = [...state];
            let originalItemIndex = items.findIndex(i => i.filename === action.filename);
            if (originalItemIndex === -1) {
                let newItem = {...action};
                items.push(newItem);
                return items;
            }

            let mergedItem = {...items[originalItemIndex], ...action};
            items[originalItemIndex] = mergedItem;
            return items;
        case 'RESET':
            return [];
    }
};

const useFileUpload = (name, upload, handleChange, priorUploads) => {
    const [state, dispatch] = useReducer(reducer, priorUploads);

    const onChange = (event) => {
        const files = Array.from(event.currentTarget.files);
        for (const file of files) {
            startUpload(file);
        }

        event.currentTarget.value = null;
        handleChange({target: {name: name, value: files}});
    }

    const handleProgress = (file, progressBytes) => {
        const filename = file.name;
        const progressPercent = progressBytes.loadedBytes / file.size * 100;

        dispatch({type: 'UPDATE', filename, progress: progressPercent});
    };

    const handleResponse = (filename, response) => {
        dispatch({type: 'UPDATE', filename, response: response._response.status});
    };

    const startUpload = async (file) => {
        // get a SAS uri for this file
        const sasUriResponse = await upload(file.name);
        if (sasUriResponse.status === 400) {
            dispatch({type: 'UPDATE', filename: file.name, error: true});
            return;
        }

        const blobUri = await sasUriResponse.json();
        const pipeline = newPipeline();
        const blobClient = new BlockBlobClient(blobUri, pipeline);

        dispatch({type: 'UPDATE', filename: file.name, error: false, progress: 0, response: ''});

        blobClient
            .uploadBrowserData(file, {onProgress: (progress) => handleProgress(file, progress)})
            .then(response => handleResponse(file.name, response));


    };

    return {
        onChange,
        state,
        dispatch
    };

};

const ProfilePhotoUploadControl = ({project, name, labelKey, ...props}) => {
    const {profile: profileService} = useApi();
    const {t} = useTranslation();
    /*const [field] = useField(name);*/
    const fileRef = useRef();
    const auth = useAuth();

    const upload = async (filename) => await profileService.uploadProfilePhotoToken(filename);

    const handleOnChange = (files) => {
        /*field.onChange({ target: { name: name, value: files } });*/
    }

    const {onChange, state, dispatch} = useFileUpload(name, upload, handleOnChange, []);

    useEffect(() => {
        if (state && state.length > 0 && state[0].response === 201) {
            auth.reloadProfile();
            dispatch({type: 'RESET'});
        }
    }, [state]);
//style={{border: '3px #3B9D97 solid'}}
    var content = <Avatar size="2xl" userId={auth.user.sub}/>; // <Avatar size="2xl" src={`${config.PROFILE_IMAGE_ROOT}${auth.user.sub}?t=${Date.now()}`} />;
    if (state && state.length > 0 && !state[0].complete) {
        content = <Spinner/>;
    }

    return (
        <VStack margin='30px 0 0 0' align='start'>
            {content}
            <Link to="#" style={{width: '128px'}} variant="action" onClick={() => fileRef.current.click()}>Edit</Link>
            <FormControl>
                <input type="file" name={name} onChange={onChange} value={undefined} ref={fileRef} hidden/>
            </FormControl>
        </VStack>
    );
};

const LabelledImage = ({label, imageUrl}) => {
    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <Image boxSize='100px' objectFit='cover' src={imageUrl || "/images/no-image-placeholder.png"}/>
        </FormControl>
    );
};

export default LabelledImage;


const CompanyAssetUploadControl = ({companyId, name, labelKey, refresh, ...props}) => {
    const {company: companyService} = useApi();
    const {t} = useTranslation();
    const [field] = useField(name);
    const fileRef = useRef();
    var currentUri = field.value;

    const upload = async (_) => await companyService.uploadCompanyAsset(companyId, name);

    const handleOnChange = async (files) => {
        var companiesResponse = await companyService.get();
        var companies = await companiesResponse.json();
        var match = companies.find(c => c.companyId === companyId);

        var newValue = match[name];
        field.onChange({target: {name: name, value: newValue}});
    }

    const {onChange, state, dispatch} = useFileUpload(name, upload, handleOnChange, []);

    useEffect(() => {
        if (state && state.length > 0 && state[0].response === 201) {
            // need to handle reloading company here?
            refresh();
            dispatch({type: 'RESET'});
        }
    }, [state]);

    let content;
    if (state && state.length > 0 && !state[0].complete) {
        content = <Spinner/>;
    } else {
        content = <LabelledImage label={t(labelKey)} imageUrl={currentUri}/>;
    }

    return (
        <>
            <HStack>
                {content}
                <Button p={0} variant="green" onClick={() => fileRef.current.click()}>
                    <Icon boxSize="5" color="white" as={FaUpload}/>
                </Button>
            </HStack>
            <input type="file" name={field.name} onChange={onChange} value={undefined} ref={fileRef} hidden/>
        </>
    );

};
export {ProfilePhotoUploadControl, CompanyAssetUploadControl}