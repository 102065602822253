import React from 'react';
import { useTranslation } from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import * as Yup from 'yup';
import ProjectStep from '../projectStep';
import Step2Content from "./step2Content";
import useLocalStorageUtil from '../../../utils/localStorageUtils';

const TranslationDubbingStep2 = ({ projectId: propProjectId, companyId: propCompanyId, isAnonymous, onNext }) => {
    const { t } = useTranslation();
    const { projects } = useApi();
    const { companyId: paramCompanyId, projectId: paramProjectId } = useParams();
    const [guid] = useLocalStorageUtil();
    const history = useHistory();

    const companyId = propCompanyId || paramCompanyId;
    const projectId = propProjectId || paramProjectId;
    const nextStep = `/company/${companyId}/translation-dubbing-project/${projectId}/step-3`;

    const updateProjectStep = async (values) => {
        const updateFunction = isAnonymous ? projects.translationDubbing.updateStep2Anonymous : projects.translationDubbing.updateStep2;
        await updateFunction(companyId, projectId, isAnonymous ? guid : values, values);
    };

    const onSubmit = async (values) => {
        if (isAnonymous && !guid) {
            console.log("Invalid anonymous id");
            return;
        }
        await updateProjectStep(values);
        if(onNext !== undefined) {
            onNext(nextStep);
            return;
        }

        history.push(nextStep);
    };

    const validationSchema = Yup.object().shape({
        jobTitle: Yup.string().required(t('general.required')),
        description: Yup.string().required(t('general.required')),
    });

    const projectStepProps = {
        companyId,
        projectId,
        heading: '②',
        subheading: t('project.all.step2.title'),
        onSubmit,
        next: nextStep,
        previous: `/create-project`,
        progress: 50,
        validationSchema,
        isAnonymous
    };

    return (
        <ProjectStep {...projectStepProps}>
            {(project, formikProps) => <Step2Content props={formikProps}/>}
        </ProjectStep>
    );
};

export default TranslationDubbingStep2;
