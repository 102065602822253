import { inputAnatomy } from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers, defineStyle} from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);
 
const baseStyle = definePartsStyle({
    field: {
        bg: 'brand.white',
        border: '1px solid',
        fontSize: "13px",
        '&:focus': {
            borderColor: 'brand.green',
            outline: 'none',
        }
    }
});

const sizes = {
  sm: definePartsStyle({
    field: {
      maxWidth: '30ch',
      borderRadius: '16px',
        borderColor: "brand.darkerGray",
        fontSize: "13px",
        height:"29px",
    }
  }),
  md: definePartsStyle({
    field: {
      maxWidth: '60ch',
        borderRadius: '16px',
        borderColor: "brand.darkerGray",
        fontSize: "13px",
        height:"29px",
    }
  }),
};

const filter = definePartsStyle({
    field: {
        bg: 'brand.white',
        border: '1px solid',
        fontSize: "13px",
        '&:focus': {
            borderColor: 'brand.green',
            outline: 'none',
        }
    }
});


export const inputTheme = defineMultiStyleConfig({ 
    baseStyle,
    sizes,
    variants: {filter}
});