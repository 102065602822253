import {useEffect, useState} from 'react';
import {Badge, Button, Card, CardBody, Divider, FormControl, FormLabel, Heading, HStack, Stack} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../hooks/useApi.jsx';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import {InputControl, RadioControl, RadioGroupControl} from '../forms'
import {Autocomplete} from 'chakra-ui-simple-autocomplete';
import {CloseIcon} from '@chakra-ui/icons';
import ComponentLoading from '../ComponentLoading';

const useProfileComponent = ({profile}) => {
    const {t} = useTranslation();
    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('general.required'))
    });
    const [skills, setSkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const {skills: skillsApi} = useApi();
    const [chosenSkills, setChosenSkills] = useState(profile.skills.map(s => ({label: s.name, value: s.name})));

    useEffect(() => {
        const fn = async () => {
            if (loading) {
                const skillsResponse = await skillsApi.get();
                const skills = await skillsResponse.json();
                setSkills(skills)
            }

            setLoading(false);
        };

        fn();
    }, [loading]);

    if (loading) {
        return {
            loading
        };
    }

    const initialValues = {
        email: profile.email ?? '',
        firstName: profile.firstName ?? '',
        lastName: profile.lastName ?? '',
        phoneNumber: profile.phoneNumber ?? '',
        language: profile.language ?? '',
        level: profile.level ?? '',
        availability: profile.availability ?? 'CurrentlyUnavailable',
        skills: profile.skills ?? []
    };

    return {
        loading,
        initialValues,
        schema,
        skills,
        chosenSkills,
        setChosenSkills
    };
};

export default ({profile, updateProfile}) => {
    const {t} = useTranslation();
    const {
        loading,
        initialValues,
        schema,
        skills,
        chosenSkills,
        setChosenSkills
    } = useProfileComponent({profile});

    if (loading) {
        return <ComponentLoading/>;
    }

    return <>
        <Formik initialValues={initialValues}
                validationSchema={schema}
                onSubmit={updateProfile}>
            {(props) => <>
                <form onSubmit={props.handleSubmit}>
                    <InputControl name="firstName" labelKey="profile.firstName" size="sm"/>
                    <InputControl name="lastName" labelKey="profile.lastName" size="sm"/>
                    <InputControl name="email" labelKey="profile.email" disabled size="sm"/>
                    <InputControl name="phoneNumber" labelKey="profile.phoneNumber" size="sm"/>
                    <InputControl name="language" labelKey="profile.language" size="sm"/>
                    <InputControl name="level" labelKey="profile.level" size="sm" mb={3}/>
                    <RadioGroupControl name="availability" labelKey="profile.availability" vertical >
                        <Stack direction='column'>
                            <RadioControl value="WorkingDays">{t('profile.WorkingDays')}</RadioControl>
                            <RadioControl
                                value="CurrentlyUnavailable">{t('profile.CurrentlyUnavailable')}</RadioControl>
                            <RadioControl value="EveningsAndWeekends">{t('profile.EveningsAndWeekends')}</RadioControl>
                            <RadioControl value="Anytime">{t('profile.Anytime')}</RadioControl>
                        </Stack>

                    </RadioGroupControl>
                    <Divider/>
                    <Heading variant="section">My Skills</Heading>
                    <Field name="skills">
                        {(props) => (
                            <FormControl>

                                <FormLabel htmlFor={props.field.name}>Skills</FormLabel>
                                <Autocomplete
                                    className="skills-autocomplete"
                                    style={{
                                        backgroundColor: 'white',
                                        maxWidth: '30ch',
                                        borderRadius: '16px',
                                        borderColor: '#C4C4C4',
                                        fontSize: '13px',
                                        height: '29px',
                                    }}
                                    options={skills.map(s => ({value: s.name, label: s.name}))}
                                    result={chosenSkills}
                                    setResult={(options) => {
                                        const mappedOptions = options.map(o => ({name: o.value}));
                                        props.form.setFieldValue(props.field.name, mappedOptions);
                                        setChosenSkills(options);
                                    }}
                                    placeholder="Type to find or create more skills"
                                    renderBadge={(option) => {
                                        return <Badge variant="gray">
                                            {option.label.toLowerCase()}
                                            <CloseIcon ml='2' w={2} h={2}/>
                                        </Badge>
                                    }}
                                />

                            </FormControl>)}
                    </Field>


                    <HStack>
                        <Button type="submit" ml="auto">
                            Save
                        </Button>
                    </HStack>

                </form>
            </>}
        </Formik>
    </>
}