import {Box, Divider, Heading, HStack, Icon, Link, Spacer, useDisclosure, VStack} from "@chakra-ui/react";
import AssignFreelancerModal from "../projects/assignFreelancer";
import DeleteProjectModal from "./deleteProjectModal";
import {DeleteIcon, EditIcon} from "@chakra-ui/icons";
import {FaUserPlus} from "react-icons/fa";
import React from "react";
import {useApi} from "../../hooks/useApi";
import {useTranslation} from "react-i18next";
import useLoading from "../../hooks/useLoading";
import {projectLink} from "../../utils/projectUtils";
import ProjectStatus from "./projectStatus";


const ProjectCard = ({project, user}) => {
    const {projects: projectsApi} = useApi();
    const {i18n} = useTranslation();
    const [ loading, setLoading] = useLoading();

    const handleDeleteProject = async (companyId, projectId) => {
        await projectsApi.delete(companyId, projectId);
        setLoading(true);
    }

    const p = project;
    const title = p.jobTitle && p.jobTitle !== '' ? p.jobTitle : 'No Title';
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen: isAssignOpen, onOpen: onAssignOpen, onClose: onAssignClose} = useDisclosure();
    return <VStack alignItems="left">
        <AssignFreelancerModal onClose={onAssignClose} isOpen={isAssignOpen} companyId={p.companyId}
                               projectId={p.projectId}/>
        <DeleteProjectModal onClose={onClose} isOpen={isOpen}
                            handleDeleteProject={() => handleDeleteProject(p.companyId, p.projectId)}/>
        <Heading size="sm"><Link href={projectLink(p)}>{title}</Link></Heading>
        <ProjectStatus project={p}/>
        {!user.isFreelanceMode && user.isAdmin &&
            <Box>{p.assignedFreelancer ? p.assignedFreelancer.firstName : 'Nobody'}</Box>}
        <HStack>
            <Box>{Intl.DateTimeFormat(i18n.language).format(new Date(p.created))}</Box>
            <Spacer/>
            {!user.isFreelanceMode && <Box><Link variant="dashboard" href={projectLink(p)}><EditIcon/></Link></Box>}
            {!user.isFreelanceMode && <Box><Link variant="dashboard" onClick={onOpen}><DeleteIcon/></Link></Box>}
            {!user.isFreelanceMode && user.isAdmin &&
                <Box><Link variant="dashboard" onClick={onAssignOpen}><Icon as={FaUserPlus}
                                                                            verticalAlign='middle'/></Link></Box>}
            {user.isFreelanceMode && <Box><Link variant="dashboard" href={projectLink(p)}><EditIcon/></Link></Box>}
        </HStack>
        <Divider/>
    </VStack>;
};

export default ProjectCard;