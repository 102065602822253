import React, {useEffect, useState} from 'react';
import {useAuth} from '../../hooks/useAuth.jsx';
import {
    Box,
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    Heading,
    HStack,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    VStack
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../hooks/useApi.jsx';
import {useHistory} from 'react-router-dom';
import {Formik} from "formik";
import {FaUserMinus, FaUserPlus} from 'react-icons/fa'

const AssignFreelancer = ({companyId, projectId, onClose}) => {
    const {t} = useTranslation();
    const [project, setProject] = useState();
    const [loading, setLoading] = useState(true);
    const [notify, setNotify] = useState(null);
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState('');
    const {user} = useAuth();
    const {projects: projectsApi, profile: profileApi} = useApi();
    const history = useHistory();
    const toast = useToast();

    useEffect(() => {
        const fn = async () => {
            if (user.isLoggedIn) {
                const projectResponse = await projectsApi.get(companyId, projectId);
                const project = await projectResponse.json();
                setProject(project);

                const usersResponse = await profileApi.getAll();
                const users = await usersResponse.json();
                setUsers(users);

                setLoading(false);
            }
            setLoading(false);
        }

        fn();
    }, [user]);

    const handleAssign = async (user) => {
        await projectsApi.assign(companyId, projectId, user.userId);
        onClose();
        window.location.reload();
    };

    const handleUnassign = async (user) => {
        await projectsApi.unassign(companyId, projectId, user.userId);
        onClose();
        window.location.reload();
    };

    if (loading) {
        return <Spinner/>;
    }

    const handleInvite = async (values, props) => {
        const clientUri = window.location.protocol + "//" + window.location.host + "/signup";
        const response = await projectsApi.invite(companyId, projectId, values.email, clientUri);
        if (response.ok) {
            props.setErrors({'email': "Your invitation has been sent"});
        } else {
            props.setErrors({'email': 'An error occurred'});
        }
    }

    const initialValues = {
        email: '',
    };

    const handleChange = (e) => {
        setFilter(e.target.value);
    }

    const isUserAssigned = (userId) => {
        return project.assignedFreelancers?.some(x => x.userId === userId);
    }


    const filtered = users.filter(u => filter == '' || u.email.startsWith(filter));

    return <Center marginBottom="2em">
        <VStack width="100%">
            {/*<Heading>{t('project.assignUser.title')}</Heading>*/}
            <Heading size="md">{t('project.assignUser.projectName')} {project.jobTitle}</Heading>
            <br/>
            <Formik onSubmit={handleInvite} initialValues={initialValues}>
                {(props) => {
                    return <form onSubmit={props.handleSubmit} onChange={handleChange}>
                        <HStack>
                            <FormControl isInvalid={props.errors && props.errors['email'] && props.touched['email']}
                                         style={{width: 'unset'}}>
                                <Input name="email" {...props.getFieldProps('email')} placeholder="invite by email..."
                                       width="35ch"/>
                                <FormErrorMessage>{props.errors && props.errors['email']}</FormErrorMessage>
                            </FormControl>
                            <Button type="submit" disabled={filtered && filtered.length > 0}>Send</Button>
                        </HStack>
                    </form>;
                }}
            </Formik>
            <br/>
            <TableContainer style={{overflowY: 'auto', maxHeight: '500px'}}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>{t('project.assignUser.name')}</Th>
                            <Th>{t('project.assignUser.availability')}</Th>
                            <Th>{t('project.assignUser.assign')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filtered && filtered.length > 0 ? filtered.map(u => <Tr key={u.userId}>
                            <Td>{(u.firstName || u.lastName) ? `${u.firstName} ${u.lastName}` : '-'}</Td>
                            <Td>
                                {u.skills && u.skills.length > 0
                                    ? u.skills.map(s => <Tag variant='black' marginRight={2}
                                                             key={s.name}>{s.name}</Tag>)
                                    : '-'
                                }
                            </Td>

                            <Td>{t('profile.' + u.availability)}</Td>
                            <Td>
                                {isUserAssigned(u.userId) ? (
                                    <Button mr={2} p={0} variant="dashboard" onClick={() => handleUnassign(u)}>
                                        <Icon as={FaUserMinus} boxSize={4}/> {/* Icon for removal */}
                                    </Button>
                                ) : (
                                    <Button mr={2} p={0} variant="dashboard" onClick={() => handleAssign(u)}>
                                        <Icon as={FaUserPlus} boxSize={4}/> {/* Icon for assignment */}
                                    </Button>
                                )}
                            </Td>

                        </Tr>) : <Tr>
                            <Td colSpan={6}>{t('projects.assignUser.nousers')}</Td>
                        </Tr>}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    </Center>;
};

const AssignFreelancerModal = ({isOpen, onClose, companyId, projectId}) => {
    return <Modal isOpen={isOpen} onClose={onClose} width="5xl">
        <ModalOverlay/>
        <ModalContent maxWidth='5xl'>
            <ModalHeader>Assign Freelancer</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <AssignFreelancer companyId={companyId} projectId={projectId} onClose={onClose}/>
            </ModalBody>
            <ModalFooter>
                <HStack>
                    <Box>
                        <Button onClick={onClose}>Cancel</Button>
                    </Box>
                </HStack>
            </ModalFooter>
        </ModalContent>

    </Modal>
}

export default AssignFreelancerModal;