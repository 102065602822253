import { useState, useEffect } from 'react';
import {
    Spinner,
    TableContainer, Table, Tbody, Thead, Tr, Td, Th, Checkbox,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text, IconButton
} from '@chakra-ui/react';
import { useApi } from '../../hooks/useApi.jsx';
import {DeleteIcon, EditIcon} from '@chakra-ui/icons';
import {useHistory} from "react-router-dom";

const DeleteUserModal = ({ isOpen, onClose, selectedUser }) => {
    const { profile: profileService } = useApi();
    const handleSubmit = async () => {
        await profileService.deleteUser(selectedUser);
        onClose();
    }

    return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Are you sure?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>Are you sure you want to delete this user?</Text>
                <Text>This will remove them from any assigned projects; and anonymise their activity logs</Text>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" variant='primary' onClick={handleSubmit}>Submit</Button>
                <Button variant='ghost' onClick={onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>;
};


export default ({onUserEdit}) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setIsLoading] = useState(true);
    const { profile: profileService } = useApi();
    const { isOpen, onOpen, onClose } = useDisclosure();


    useEffect(() => {
        const fn = async () => {
            if (loading) {
                const usersResponse = await profileService.getAll();
                setUsers(await usersResponse.json());
                setIsLoading(false);
            }
        }

        fn();
    })

    if (loading) {
        return <Spinner />;
    }

    const handleChanged = async (id, isAdmin) => {
        if (isAdmin) {
            await profileService.removeAdmin(id);
        } else {
            await profileService.makeAdmin(id);
        }

        setIsLoading(true);
    };

    const handleDeleteUser = async (userId) => {
        setSelectedUser(userId)
        onOpen();
    };

    const handleEditUser = async (userId) => {
        setSelectedUser(userId)
        onUserEdit(userId);
    };


    const handleModalClose = () => {
        onClose();
        setIsLoading(true);
    }

    return <>
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th>Username</Th>
                        <Th>Admin</Th>
                        {/*<Th>Edit</Th>*/}
                        <Th>Delete</Th>

                    </Tr>
                </Thead>
                <Tbody>
                    {users.map((u) => <Tr key={u.userId}>
                        <Td>{u.email}</Td>
                        <Td><Checkbox isChecked={u.isAdmin} onChange={(e) => handleChanged(u.userId, u.isAdmin)} /></Td>
                        {/*<Td><IconButton variant="ghost" icon={<EditIcon onClick={() => handleEditUser(u.userId)} />} aria-label="Edit"></IconButton></Td>*/}
                        <Td><IconButton variant="ghost" icon={<DeleteIcon onClick={() => handleDeleteUser(u.userId)} />} aria-label="Delete"></IconButton></Td>
                    </Tr>)}
                </Tbody>
            </Table>
        </TableContainer>
        <DeleteUserModal isOpen={isOpen} onClose={handleModalClose} selectedUser={selectedUser} />
    </>;
}