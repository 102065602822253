import {Box, Flex, Text, VStack} from "@chakra-ui/react";
import TruncatedLink from "../../truncatedLink";
import ColorDisplay from "../colorDisplay";

const DetailInformation = ({project}) => {
    return (
        <Flex direction={['column', null, 'row']} w="full">
            <VStack width={['100%', null, '50%']} spacing={6} alignItems="start">
                <Box>
                    <Text fontWeight='bold'>Job Title</Text>
                    <Text>{project.jobTitle}</Text>
                </Box>

                <Box>
                    <Text fontWeight='bold'>Job Description</Text>
                    <Text whiteSpace="pre-wrap">{project.description}</Text>
                </Box>

                <Box>
                    <Text fontWeight='bold'>Can you share any existing assets?</Text>
                    <ul style={{listStyle: 'none'}}>
                        {project.existingAssets?.map((url, i) => (
                            <li key={i}>
                                {url.toLowerCase().includes('https') ?
                                    <TruncatedLink url={url}/> : url}
                            </li>
                        ))}
                    </ul>
                </Box>
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6} alignItems="start">
                <Box>
                    <Text fontWeight='bold'>Can you share anything to inspire?</Text>
                    <ul style={{listStyle: 'none'}}>
                        {project.inspirations?.map((url, i) => (
                            <li key={i}>
                                {url.toLowerCase().includes('https') ?
                                    <TruncatedLink url={url}/> : url}
                            </li>
                        ))}
                    </ul>
                </Box>

                <Box>
                    <Text fontWeight='bold'>Preferred Colour Specification</Text>
                    <ColorDisplay colorString={project.preferredColourSpecification}/>
                </Box>
            </VStack>
        </Flex>
    )
}
export default DetailInformation;