import {ProjectDetailsHeader} from "../projectDetailsHeader";
import {ProjectDetailsInformation} from "../projectDetailsInformation";
import {useRef} from "react";
import createModalComponent from "../createModalComponent";
import Step2Content from "./step2Content";
import DetailInformation from "./detailInformation";

const CameraOperatorDetail = ({project, onProjectUpdate}) => {
    const informationModalRef = useRef();
    const handleEditInformation = () => {
        informationModalRef.current.openModal();
    };

    const updateStep2 = async (companyId, projectId, values, projects) => {
        await projects.cameraOperator.updateStep2(companyId, projectId, values);
        if (onProjectUpdate) onProjectUpdate();
    };

    const InformationModal = createModalComponent({
        title: "Edit DetailInformation",
        ContentComponent: Step2Content,
        handleSubmit: updateStep2
    });

    return (
        <>
            <ProjectDetailsHeader project={project}/>
            <ProjectDetailsInformation onEdit={handleEditInformation}>
                <DetailInformation project={project}/>
            </ProjectDetailsInformation>

            <InformationModal project={project} ref={informationModalRef}/>
        </>
    );
};

export default CameraOperatorDetail;