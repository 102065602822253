import { radioAnatomy as parts } from "@chakra-ui/anatomy"
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system"

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyleControl = defineStyle((props) => {
  return {
    bg: 'white',
    borderColor: 'brand.gray',
    _checked: {
      bg: "brand.green",
      borderColor: "brand.green",
      _before: {
        bg: "brand.green",
      },
      _hover: {
        bg: "brand.green",
        borderColor: "brand.green",
      }
    },
  }
});

const sizes = {
  sm: definePartsStyle({
    label: {
      fontSize: "13px",
    }
  }),
  md: definePartsStyle({
    label: {
      fontSize: "13px",
    }
  }),
};

const baseStyle = definePartsStyle((props) => ({
  control: baseStyleControl(props),
}));

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  sizes
});
