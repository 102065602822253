import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
    container: {
        bg: 'brand.white',
        fontSize: '13px',
    },
    control: {
        _checked: {
            bg: 'brand.green',
            borderColor: 'brand.green',
            color: 'white', // Change this to suit your needs
        },
        _hover: {
            _checked: {
                bg: 'brand.green',
                borderColor: 'brand.green',
            },
        },
    },
})

const sizes = {
    sm: {
        label: {
            fontSize: "13px",
        }
    },
    md: {
        label: {
            fontSize: "13px",
        }
    },
};

export const checkboxTheme = defineStyleConfig({
    baseStyle,
    sizes
})
