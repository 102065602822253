import {Flex, VStack} from "@chakra-ui/react";
import {InputControl, TextareaControl} from "../../forms";

const Step2Content = () => {
    return (
        <Flex direction={['column', null, 'row']} w="full">
            <VStack width={['100%', null, '100%']} spacing={6}>
                <InputControl
                    name="jobTitle"
                    labelKey="project.all.step2.jobTitle"
                    required
                />
                <TextareaControl
                    name="description"
                    labelKey="project.all.step2.description"
                    required
                />
            </VStack>
        </Flex>
    )
}

export default Step2Content;