import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Heading,
    Image,
    Center,
    Link,
    Text,
    HStack,
    Checkbox,
    FormErrorMessage,
    Container,
    Grid,
    GridItem,
    Card,
    CardBody
} from '@chakra-ui/react';
import { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth.jsx';
import { InputControl, SwitchControl } from './forms'
import qs from 'qs';

export default function LoginForm() {
    const auth = useAuth();
    const { login, user } = auth;
    const [loginComplete, setLoginComplete] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    //const flow = useGoogleLogin({ onSuccess: handleGoogleSuccess, flow: 'auth-code' });

    const getReturnPath = () => (qs.parse(history.location.search, { ignoreQueryPrefix: true }).path || '/');

    const handleSubmit = async (values, props) => {
        const response = await login(values.email, values.password, values.rememberMe);
        if (response.ok) {
            const loginResponse = await response.json();

            if (loginResponse.userHasCompletedProfile) {
                history.push(getReturnPath());
            } else {
                history.push('/signup/select');
            }
        } else {
            props.setErrors({ 'email': "User not found or incorrect password" });
        }
    };

    const handleGoogleSuccess = async (response) => {
        const loginResponse = await auth.handleGoogleSuccess(response, null)
        if (loginResponse.userHasCompletedProfile) {
            history.push(getReturnPath());
        } else {
            history.push('/signup/select');
        }
    }

    const handleGoogleFailure = (response) => {
        console.log(response);
    };

    if (user.isLoggedIn || loginComplete) {
        return <Redirect to={getReturnPath()} />;
    }

    return <Container maxW={'80rem'} padding={6}>
        <Grid
            templateColumns={['minmax(0, 1fr)', null, 'max-content minmax(0, 1fr)']}
            gap='2em'
            marginTop='2em'>

            <GridItem>
                <Card>
                    <CardBody padding='4em'>
                        <VStack>
                            <Heading>{t('login.title')}</Heading>
                            <GoogleLogin onSuccess={handleGoogleSuccess} onError={handleGoogleFailure} />
                            <Formik initialValues={{
                                email: "",
                                password: "",
                                rememberMe: true
                            }}
                                onSubmit={handleSubmit}>
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <VStack spacing={4} align="flex-start">
                                            <InputControl name="email" labelKey="login.email" width='30ch' />
                                            <InputControl name="password" labelKey="login.password" type="password" width='30ch' />
                                            <HStack width="100%">
                                                <SwitchControl name="rememberMe" labelKey="login.rememberme" />
                                            </HStack>

                                            <VStack width="100%">
                                                <Button type="submit" width="full">
                                                    {t('login.submit')}
                                                </Button>
                                                <Link fontSize="xs" href="/forgot-password">{t('login.forgotpassword')}</Link>
                                                <Text fontSize="sm">{t('login.yourdatasafe')}</Text>
                                                <Text fontSize="sm">
                                                    <Link href="https://elefantrock.com/home-page/pivacy-policy/">{t('login.privacypolicy')}</Link> | <Link href="https://elefantrock.com/home-page/terms/">{t('login.terms')}</Link>
                                                </Text>
                                            </VStack>
                                        </VStack>
                                    </form>
                                )}
                            </Formik>
                        </VStack>
                    </CardBody>
                </Card>
            </GridItem>

            
            <GridItem>
                <Image src="/images/signup-hero.png" style={{
                    'height': '100%',
                    'aspectRatio': '868/587',
                    'objectFit': 'cover'
                }} />
            </GridItem>
        </Grid>
    </Container>;
};