import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth.jsx';
import {
    Box,
    Flex,
    Text,
    Heading,
    Spinner,
    Link,
    Tooltip,
    Card,
    CardBody,
    HStack,
    VStack,
    Divider, Spacer, StackDivider
} from '@chakra-ui/react';
import { useProjectActivityLogs } from './entities';
import Avatar from './Avatar.jsx';
import ComponentLoading from './ComponentLoading.jsx';
import Pagination from "./pagination";
import {useApi} from "../hooks/useApi";

const ActivityPanel = (props) => {
    const { projectActivityLogs } = useApi();
    const [pageNumber, setPageNumber] = useState(1);
    const [logs, setLogs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    const pageSize = 3;

    useEffect(() => {
        const fetchLogs = async () => {
            setLoading(true);
            const response = await projectActivityLogs.get(props.projectId, pageNumber, pageSize);
            const data = await response.json();
            setLogs(data.logs);
            setTotalPages(data.totalPages);
            setLoading(false);
        };
        fetchLogs();
    }, [pageNumber, pageSize]);

    if (loading) {
        return <ComponentLoading />;
    }

    if (logs?.length < 1 && !props.emptyContent) {
        return null;
    }

    const handlePageClick = (page) => {
        setPageNumber(page);
    };

    // e.ProjectTypeIdentifier is not provided!
    //const Element = (props.linkToProject ? Link : Box);
    const Element = Box;
    const convertDate = (datetime) =>
        Intl.DateTimeFormat("en-gb",
            {
                day: '2-digit',
                month: 'long',
                hour: '2-digit',
                minute: '2-digit'
            }).format(new Date(datetime));

    return <Card height="420px">
        <CardBody display="flex" flexDirection="column" height="100%">
            {   logs.length < 1 ?
                props.emptyContent :
                <VStack overflowY="auto" flex="1" paddingY="4" spacing={4}>
                    {logs.map(e => (
                        <Box
                            key={e.projectActivityLogId}
                            flex="1"
                            width="100%"
                        >
                            <Element href={`/company/${e.companyId}/${e.projectTypeIdentifier}/${e.projectId}`} variant="text">
                                <VStack spacing={2} alignItems="stretch">
                                    <Box fontSize="sm" fontWeight="bold">{convertDate(e.created)}</Box>
                                    <Box fontSize="sm" color="gray">{e.userName} {e.description} {e.projectJobTitle}</Box>
                                </VStack>
                            </Element>
                            <Divider marginTop='0.5rem' marginBottom='0.5rem' />
                        </Box>
                    ))}
                </VStack>

            }
            <Flex justifyContent="flex-end" mt={4} mr={4} alignItems="center">
                <Spacer />
                <Box
                    as="span"
                    onClick={() => handlePageClick(pageNumber - 1)}
                    disabled={pageNumber === 1}
                    cursor={pageNumber === 1 ? "default" : "pointer"}
                    fontSize="lg"
                >
                    &lt;
                </Box>

                {/* Display the first page and possibly an ellipsis */}
                {pageNumber > 2 && (
                    <Box as="span" onClick={() => handlePageClick(1)} cursor="pointer" mx={1}>
                        1
                    </Box>
                )}
                {pageNumber > 3 && <Box as="span" mx={1}>...</Box>}

                {/* Display one page before the current page if it's not the first page */}
                {pageNumber > 1 && (
                    <Box as="span" onClick={() => handlePageClick(pageNumber - 1)} cursor="pointer" mx={1}>
                        {pageNumber - 1}
                    </Box>
                )}

                {/* Display the current page */}
                <Box as="span" mx={1} fontWeight="bold">
                    {pageNumber}
                </Box>

                {/* Display one page after the current page if it's not the last page */}
                {pageNumber < totalPages && (
                    <Box as="span" onClick={() => handlePageClick(pageNumber + 1)} cursor="pointer" mx={1}>
                        {pageNumber + 1}
                    </Box>
                )}

                {/* Display an ellipsis and the last page if needed */}
                {pageNumber < totalPages - 2 && <Box as="span" mx={1}>...</Box>}
                {pageNumber < totalPages - 1 && (
                    <Box as="span" onClick={() => handlePageClick(totalPages)} cursor="pointer" mx={1}>
                        {totalPages}
                    </Box>
                )}

                <Box as="span" onClick={() => handlePageClick(pageNumber + 1)} disabled={pageNumber === totalPages} cursor="pointer" fontSize="lg">
                    &gt;
                </Box>
            </Flex>
        </CardBody>
    </Card>
};

export default ActivityPanel;
