import { defineStyle, defineStyleConfig } from '@chakra-ui/react'


// Define sizes styles
const sizes = {
    '9xl': {
        maxWidth: '95rem'
    },
};

export const containerTheme = defineStyleConfig({
    sizes
})