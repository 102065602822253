import {Box, Button, Divider, Flex, FormLabel, Heading, HStack, Stack} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {ColourPicker, CompanyAssetUploadControl, ControlListControl, InputControl} from '../forms';
import React from "react";

const CompanyCard = ({company, updateCompany, deleteCompany, showDeleteButton, refresh}) => {
    const {t} = useTranslation();
    const schema = Yup.object().shape({
        name: Yup.string().required(t('general.required'))
    });

    const clientUri = window.location.protocol + "//" + window.location.host + "/signup";

    return <>
        <Formik initialValues={{
            name: company.name ?? '',
            field: company.field ?? '',
            city: company.city ?? '',
            country: company.country ?? '',
            sizeOfTeam: company.sizeOfTeam ?? '',
            invitations: company.invitations,
            invitationClientUri: clientUri,
            brandColours: company.brandColours,
            logoColoured: company.logoColoured,
            logoMonochrome: company.logoMonochrome,
            logoBlackWhiteBackground: company.logoBlackWhiteBackground,
            logoWhiteBlackBackground: company.logoWhiteBlackBackground,
            visuals: company.visuals,
            brandGuidelines: company.brandGuidelines
        }}
                validationSchema={schema}
                onSubmit={async (values, props) => await updateCompany(values, props)}>
            {(props) => (

                <form onSubmit={props.handleSubmit}>
                    <Heading variant="section">Basic Information</Heading>
                    <Divider/>

                    <InputControl name="name" labelKey="profile.company.name"/>
                    <InputControl name="field" labelKey="profile.company.field"/>
                    <Flex>
                        <Box marginRight="1em"><InputControl name="city" labelKey="profile.company.city"/></Box>
                        <Box><InputControl name="country" labelKey="profile.company.country"/></Box>
                    </Flex>
                    <br />
                    <Heading variant="section">Brand Logo</Heading>
                    <Divider/>
                    <Stack direction={{ base: "column", md: "row" }}>
                        <CompanyAssetUploadControl companyId={company.companyId} name={'logoColoured'}
                                                   labelKey='profile.company.logoColoured' refresh={refresh}/>
                        <CompanyAssetUploadControl companyId={company.companyId} name={'logoMonochrome'}
                                                   labelKey='profile.company.logoMonochrome' refresh={refresh}/>
                        <CompanyAssetUploadControl companyId={company.companyId} name={'logoBlackWhiteBackground'}
                                                   labelKey='profile.company.logoBlackWhiteBackground'
                                                   refresh={refresh}/>
                        <CompanyAssetUploadControl companyId={company.companyId} name={'logoWhiteBlackBackground'}
                                                   labelKey='profile.company.logoWhiteBlackBackground'
                                                   refresh={refresh}/>
                    </Stack>
                    <br />
                    <Heading variant="section">Brand Assets</Heading>
                    <Divider/>
                    <Stack direction={{ base: "column", md: "row" }}>

                        <CompanyAssetUploadControl companyId={company.companyId} name={'visuals'}
                                                   labelKey='profile.company.visuals' refresh={refresh}/>
                        <CompanyAssetUploadControl companyId={company.companyId} name={'brandGuidelines'}
                                                   labelKey='profile.company.brandGuidelines' refresh={refresh}/>
                    </Stack>

                    <br />
                    <Heading variant="section">Brand Colours</Heading>
                    <Divider/>

                    <ControlListControl name="brandColours" labelKey="" defaultValue={''}>
                        {(props) => {
                            return <ColourPicker {...props} />
                        }}
                    </ControlListControl>


                    {/*<Divider />*/}
                    {/*<Heading variant="section">Invitations</Heading>*/}

                    {/*<ControlListControl name="invitations" labelKey="profile.company.invitations" defaultValue={''}>*/}
                    {/*    {(props) => <Invitation {...props} variant="settings" />}*/}
                    {/*</ControlListControl>*/}

                    <Flex>
                        <Button type="submit" variant="primary" ml="auto">
                            Save
                        </Button>

                        {showDeleteButton && <Button onClick={deleteCompany} variant="primary" marginRight={4}>
                            {t('profile.company.delete')}
                        </Button>}
                    </Flex>
                </form>
            )}
        </Formik>
    </>
};

export default CompanyCard;