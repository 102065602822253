import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import ChatWindow from './chatWindow.jsx';
import ChatInput from './chatInput.jsx';

const Chat = ({ projectId, userId }) => {
    const [connection, setConnection] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [chat, setChat] = useState([]);
    const latestChat = useRef(null);

    latestChat.current = chat;

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/chathub') // 'https://localhost:44486/chathub')
            .withAutomaticReconnect()
            .build();
        //, {
        //    headers: {
        //        authorization: 'test'
        //    },
        //    //withCredentials: false
        //}

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.send('JoinGroup', projectId);
                    setIsConnected(true);
                })
                .then(result => {
                    connection.on('ReceiveMessages', messages => {
                        const updatedChat = [...latestChat.current];
                        messages.forEach(message => {
                            if (message.projectId != projectId) {
                                return;
                            }
                            updatedChat.push(message);
                        });
                        setChat(updatedChat);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    useEffect(() => {
        const chatElement = document.querySelector('.chat-window-inner');
        if (chatElement) {
            chatElement.scroll({
                top: chatElement.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [ chat ]);

    const sendMessage = async (message) => {
        const chatMessage = {
            projectId,
            userId,
            message
        };

        if (connection._connectionStarted) {
            try {
                await connection.send('SendMessage', chatMessage);
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }

    return (
        <div className="chat">
            <ChatWindow chat={chat} />
            <ChatInput sendMessage={sendMessage} isDisabled={!isConnected} />
        </div>
    );
};

export default Chat;