const Config = {
    // PROFILE_IMAGE_ROOT: 'https://briefportalstorage.blob.core.windows.net/profile-images/',
    PROFILE_IMAGE_ROOT: 'https://elefantrock.blob.core.windows.net/profile-images/',
    // GOOGLE_AUTH_CALLBACK_URL: 'https://localhost:44486',
    // GOOGLE_AUTH_CALLBACK_URL: 'https://elefantrock.digitaltradingco.co.uk',
    GOOGLE_AUTH_CALLBACK_URL: 'https://portal.elefantrock.co.uk',
    // GOOGLE_CLIENT_ID: '903000747370-k6pnc9oonu9s03ofpmmrernvei5jqp0o.apps.googleusercontent.com'
    GOOGLE_CLIENT_ID: '286594736569-m6f0292rsuvkn6dlg3t6li57n2vbr3gi.apps.googleusercontent.com' //LIVE
    // GOOGLE_CLIENT_ID: '286594736569-3f0q4snd47hjs0no3n2h92q5cb90391m.apps.googleusercontent.com' // DEV
};

export default Config;