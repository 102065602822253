import CompanyAssets from '../companyAssets.jsx'
import {useRef} from "react";
import createModalComponent from "../createModalComponent";
import Step2Content from "./step2Content";
import Step3Content from "./step3Content";
import {ProjectDetailsHeader} from "../projectDetailsHeader";
import {ProjectDetailsInformation} from "../projectDetailsInformation";
import DetailInformation from "./detailInformation";
import {ProjectDetailsDeliverables} from "../projectDetailsDeliverables";
import DetailDeliverables from "./detailDeliverables";

const VideoEditingDetail = ({project, onProjectUpdate}) => {
    const informationModalRef = useRef();
    const deliverablesModalRef = useRef();
    const handleEditInformation = () => {
        informationModalRef.current.openModal();
    };
    const handleEditDeliverables = () => {
        deliverablesModalRef.current.openModal();
    };

    const updateStep2 = async (companyId, projectId, values, projects) => {
        await projects.videoEditing.updateStep2(companyId, projectId, values);
        if (onProjectUpdate) onProjectUpdate();
    };

    const updateStep3 = async (companyId, projectId, values, projects) => {
        await projects.videoEditing.updateStep3(companyId, projectId, values);
        if (onProjectUpdate) onProjectUpdate();
    };

    const InformationModal = createModalComponent({
        title: "Edit Information",
        ContentComponent: Step2Content,
        handleSubmit: updateStep2
    });

    const DeliverablesModal = createModalComponent({
        title: "Edit Deliverables",
        ContentComponent: Step3Content,
        handleSubmit: updateStep3
    });

    return (
        <>
            <ProjectDetailsHeader project={project}/>
            <ProjectDetailsInformation onEdit={handleEditInformation}>
                <DetailInformation project={project}/>
            </ProjectDetailsInformation>

            <CompanyAssets project={project}/>

            <ProjectDetailsDeliverables onEdit={handleEditDeliverables}>
                <DetailDeliverables project={project}/>
            </ProjectDetailsDeliverables>

            <InformationModal project={project} ref={informationModalRef}/>
            <DeliverablesModal project={project} ref={deliverablesModalRef}/>
        </>
    );
};

export default VideoEditingDetail;