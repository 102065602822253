import React from 'react';
import { Route, Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth.jsx';

export default ({ component: Component, ...restOfProps }) => {
    const { user } = useAuth();
    const history = useHistory();

    return <Route {...restOfProps}
        render={(props) => user.isLoggedIn ?
            <Component {...props} />
            : <Redirect to={`/login/?path=${window.encodeURIComponent(history.location.pathname)}`} replace />} />
};