import {
    Box,
    Button,
    Card,
    CardBody,
    Container,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Input,
    Link,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Spinner,
    Text,
    UnorderedList,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import {useHistory, useParams} from 'react-router-dom';
import {useProject} from '../entities';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../hooks/useApi.jsx';

import {useAuth} from '../../hooks/useAuth.jsx';
import {Formik} from 'formik';
import {ControlListControl} from '../forms'
import humps from 'humps';
import {CheckIcon} from '@chakra-ui/icons';
import React from 'react';
import Chat from '../chat/chat.jsx';
import ActivityPanel from '../activityPanel';

import BespokeDetail from './bespoke/detail.jsx';
import CameraOperatorDetail from './cameraOperator/detail.jsx';
import ChannelBrandingDetail from './channelBranding/detail.jsx';
import MotionGraphicsDetail from './motionGraphics/detail.jsx';
import TranslationDubbingDetail from './translationDubbing/detail.jsx';
import VideoEditingDetail from './videoEditing/detail.jsx';

const SubmitEditsModal = ({history, projectsApi, projectId, companyId, isOpen, onClose}) => {
    const handleSubmit = async (values, props) => {
        const response = await await projectsApi.submitEdit(companyId, projectId, values);

        if (response.status === 400) {
            const badRequest = await response.json();
            if (badRequest.errors) {
                const camelized = humps.camelizeKeys(badRequest.errors);
                props.setErrors(camelized);
            }
            return;
        }

        props.setSubmitting(false);
        history.go(0);
    }

    const initialValues = {
        editLinks: [''],
    };
    return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {(props) =>
                <form onSubmit={props.handleSubmit}>
                    <ModalContent>
                        <ModalHeader>Submit Edits</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <ControlListControl name="editLinks" labelKey="project.edits" defaultValue={''}>
                                {(props) => <Box width="100%" paddingBottom=".5em"><Input {...props}
                                                                                          variant="projectLink"/></Box>}
                            </ControlListControl>
                        </ModalBody>
                        <ModalFooter>
                            <HStack>
                                <Box>
                                    <Button type="submit">Submit</Button>
                                </Box>
                                <Box>
                                    <Button onClick={onClose}>Close</Button>
                                </Box>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </form>}
        </Formik>
    </Modal>
}

const ProjectEdit = ({companyId, projectId, projectEdit, acceptEdit}) => {
    return <VStack width="100%" alignItems="flex-start">
        <HStack width="100%">
            <Text>
                {Intl.DateTimeFormat("en-gb",
                    {
                        day: '2-digit',
                        month: 'long',
                        hour: '2-digit',
                        minute: '2-digit'
                    }).format(new Date(projectEdit.created))}
            </Text>
            <Spacer/>
            {projectEdit.accepted && <Text color="brand.green">Accepted <CheckIcon/></Text>}
            {projectEdit.accepted || <Text color="lightgrey">Not Yet Accepted</Text>}
        </HStack>

        <UnorderedList paddingLeft="1em">
            {projectEdit.editLinks.map(el => <ListItem key={el.editLinkId}>
                <Link href={el.uri} style={{overflowWrap: 'anywhere'}} isExternal target="_blank">
                    {el.uri}
                </Link>
            </ListItem>)}
        </UnorderedList>
        <HStack width="100%">
            <Spacer/>
            {projectEdit.accepted ||
                <Button variant="green" onClick={() => acceptEdit(companyId, projectId, projectEdit.editId)}>Accept This
                    Edit</Button>}
        </HStack>
        <Divider/>
    </VStack>;
};

export default () => {
    const {companyId, projectId} = useParams();

    const {t} = useTranslation();
    const history = useHistory();
    const {projects} = useApi();
    const {user} = useAuth();
    const {isOpen: isOpenSubmitEdits, onOpen: onOpenSubmitEdits, onClose: onCloseSubmitEdits} = useDisclosure();

    const {entity: project, loading, setLoading} = useProject(companyId, projectId);

    if (loading) {
        return <Spinner/>;
    }

    const acceptEdit = async (companyId, projectId, editId) => {
        const response = await projects.acceptEdit(companyId, projectId, editId);
        setLoading(true);
    };

    const canSubmit = user.isFreelanceMode && (user.email === project.assignedFreelancer?.email);

    const handleCompletion = async () => {
        const response = await projects.complete(companyId, projectId);
        setLoading(true);
    };

    const handleProjectUpdate = () => {
        setLoading(true);
    };

    const details = {
        'bespoke': BespokeDetail,
        'camera-operator': CameraOperatorDetail,
        'channel-branding': ChannelBrandingDetail,
        'motion-graphics': MotionGraphicsDetail,
        'translation-dubbing': TranslationDubbingDetail,
        'video-editing': VideoEditingDetail
    }

    const DetailComponent = details[project.projectTypeIdentifier];

    if (!DetailComponent) {
        console.log("Detail was null");
        console.log(project.projectTypeIdentifier);
    }

    return <Container maxW={'100rem'} padding={6}>
        <VStack>
            <Heading>{project.title}</Heading>
        </VStack>
        <Grid
            templateColumns={['minmax(0, 1fr)', 'minmax(0, 2fr) minmax(0, 1fr)']}
            templateAreas={[`"project" "activity"`, `"project activity"`]}
            gap='2em'
            marginTop='2em'
            width="100%"
        >

            <GridItem area={'activity'}>
                <Card mb='10px'>
                    <CardBody px='20px' py='10px'>
                        <Heading size='lg' marginTop={0} height='29px'>Chat</Heading>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody p='10px'>
                        <Chat projectId={projectId} userId={user.sub}/>
                    </CardBody>
                </Card>
                <Card mb='10px' mt='20px'>
                    <CardBody px='20px' py='10px'>
                        <Heading size='lg' marginTop={0} height='29px'>Activity</Heading>
                    </CardBody>
                </Card>
                <ActivityPanel projectId={projectId}/>
            </GridItem>

            <GridItem area={'project'}>
                <Card>
                    <CardBody>
                        <DetailComponent project={project} onProjectUpdate={handleProjectUpdate}/>
                        {canSubmit && <div>Is Freelance Mode</div>}

                        <Heading size='md' textTransform='uppercase'>Edits</Heading>
                        <Divider style={{borderColor: 'black'}}/>
                        {(!project.edits || project.edits.length < 1) && "No edits submitted"}
                        {project.edits && <VStack>
                            {project.edits.map(e => <ProjectEdit projectEdit={e} key={e.editId} acceptEdit={acceptEdit}
                                                                 companyId={companyId} projectId={projectId}/>)}
                        </VStack>}
                        {project.status !== 'ProjectCompleted' && <Box>
                            <Button variant='green' onClick={onOpenSubmitEdits}>Submit An Edit</Button>
                        </Box>}
                        <HStack paddingLeft='10' paddingRight='10' marginTop='5'>
                            <Link href='/'>Back to dashboard</Link>
                            <Spacer/>
                            {project.status !== 'ProjectCompleted' &&
                                <Button type="submit" variant='primary' onClick={handleCompletion}>
                                    Complete Project
                                </Button>}
                        </HStack>
                    </CardBody>

                </Card>

            </GridItem>
        </Grid>
        <SubmitEditsModal history={history} projectsApi={projects} companyId={companyId} projectId={projectId}
                          isOpen={isOpenSubmitEdits} onClose={onCloseSubmitEdits}/>
    </Container>;
}