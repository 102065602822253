import BaseCard from "../baseCard";
import {Heading, Text} from "@chakra-ui/react";
import React from "react";
import CreateProjectForm from "../projects/createProjectForm";

const SignupProjectDetails = ({onNext}) => {
    const handleSubmit = async (values) => {
        onNext(values.projectType);
    };

    return <BaseCard
        maxWidth={'80rem'}
        padding={6}
        borderRadius={'20px'}
        // width={'400px'}
    >
        <Heading textAlign='center' size={'lg'}>Project details</Heading>
        <Text pt="2rem" fontSize="md">I'm here for:</Text>
        <CreateProjectForm onSubmit={handleSubmit} />
    </BaseCard>
}

export default SignupProjectDetails;