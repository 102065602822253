import React from 'react';
import { Formik } from 'formik';
import {
    Center,
    Grid,
    GridItem,
    VStack,
    HStack,
    Spacer,
    Button,
} from '@chakra-ui/react';
import { HeroSelector, RadioGroup } from '../forms';

const CreateProjectForm = ({ defaultCompanyId, onSubmit }) => {
    return (
        <Formik
            initialValues={{ companyId: defaultCompanyId, projectType: 'channelBranding' }}
            onSubmit={onSubmit}
        >
            {(props) => (
                <form onSubmit={props.handleSubmit}>
                    <Center bg="content_fill" padding='10'>
                        <VStack>
                            <RadioGroup name="projectType" py={2}>
                                <Grid templateRows={['repeat(6, 1fr)', null, 'repeat(2, 1fr)']}
                                      templateColumns={['1fr', null, 'repeat(3, 1fr)']} alignItems='center'
                                      gap={4}>
                                    <GridItem>
                                        <HeroSelector name='projectType' label="Channel Branding"
                                                      value="channelBranding"/>
                                    </GridItem>
                                    <GridItem>
                                        <HeroSelector name='projectType' label="Video Editing"
                                                      value="videoEditing"/>
                                    </GridItem>
                                    <GridItem>
                                        <HeroSelector name='projectType' label="Camera Operator"
                                                      value="cameraOperator"/>
                                    </GridItem>
                                    <GridItem>
                                        <HeroSelector name='projectType' label="Translation Dubbing"
                                                      value="translationDubbing"/>
                                    </GridItem>
                                    <GridItem>
                                        <HeroSelector name='projectType' label="Motion Graphics"
                                                      value="motionGraphics"/>
                                    </GridItem>
                                    <GridItem>
                                        <HeroSelector name='projectType' label="Bespoke" value="bespoke"/>
                                    </GridItem>
                                </Grid>
                            </RadioGroup>
                        </VStack>
                    </Center>
                    <HStack paddingLeft='10' paddingRight='10' paddingTop={'50px'}>
                        <Spacer/>
                        <Button type="submit">Next</Button>
                    </HStack>
                </form>
            )}
        </Formik>
    );
}

export default CreateProjectForm;
