import {Button, Center, Heading, HStack, Spacer, Text, VStack} from '@chakra-ui/react';
import {HeroSelector, RadioGroup} from '../forms';
import React from "react";
import {Formik} from "formik";
import BaseCard from "../baseCard";

const SignupUserType = ({onNext}) => {
    const handleSubmit = (values) => {
        onNext(values.userType);
    }

    return <BaseCard
        maxWidth={'80rem'}
        padding={6}
        borderRadius={'20px'}
        width={'400px'}
    >
        <Heading textAlign='center' size={'lg'}>Welcome!</Heading>
        <Text fontSize="sm">Please select one option from below</Text>
        <Formik initialValues={{userType: 'company'}} onSubmit={handleSubmit}>
            {(props) => (
                <form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                    <Center bg="content_fill" py={8}>
                        <VStack width={'100%'}>
                            <RadioGroup name="userType">
                                <VStack spacing={10} alignItems='center'>
                                    <HeroSelector name='userType' label="I have a project"
                                                  value="company" padding="12px 52px"/>
                                    <HeroSelector name='userType' label="I'm a freelancer"
                                                  value="freelancer" padding="12px 52px"/>
                                </VStack>
                            </RadioGroup>
                        </VStack>
                    </Center>
                    <HStack paddingTop="1rem">
                        <Spacer/>
                        <Button type="submit">
                            Next
                        </Button>
                    </HStack>
                </form>
            )}
        </Formik>
    </BaseCard>
};

export default SignupUserType;
