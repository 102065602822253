import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { unstable_batchedUpdates } from 'react-dom';

// Buggy: https://github.com/chakra-ui/chakra-ui/issues/3293#issuecomment-775081618
const baseStyle = defineStyle({
    bg: 'transparent',
    backgroundColor: 'transparent',
    borderColor: 'brand.pink',
    borderWidth: '3px',
    borderRadius: 25,
    height: '2rem',
    color: 'brand.pink',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: '1rem',
    _hover: {
        color: 'white',
        bg: 'brand.pink',
        backgroundColor: 'brand.pink',
    }
});

const dashboard = defineStyle({
    bg: '#444444',
    backgroundColor: '#444444',
    borderColor: '#444444',
    borderRadius: 25,
    height: '2rem',
    color: 'white',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: '0',
    _hover: {
        color: '#444444',
        bg: 'transparent',
        backgroundColor: 'transparent',
    }
});

const link = defineStyle({
    bg: 'transparent',
    backgroundColor: 'transparent',
    borderWidth: '0',
    borderRadius: 0,
    color: 'brand.black',
    fontSize: '1.8rem',
    fontWeight: 'medium',
    marginTop: '1rem',
    textDecoration: 'underline',
    _hover: {
        color: 'black',
        bg: 'transparent',
        backgroundColor: 'transparent',
        textDecoration: 'none'
    }
});

const green = defineStyle({
    bg: 'brand.green',
    backgroundColor: 'brand.green',
    borderColor: 'brand.green',
    borderWidth: '4px',
    borderRadius: 25,
    color: 'brand.white',
    fontSize: '0.8rem',
    fontWeight: 'medium',
    marginTop: '1rem',
    height: 'unset',
    _hover: {
        color: 'brand.green',
        bg: 'brand.darkGray',
        backgroundColor: 'brand.darkGray',

    }
});

const ghost = defineStyle({
    borderWidth: 0,
    marginTop: 0,

});

const greenGhost = defineStyle({
    borderWidth: 0,
    marginTop: 0,
    color: '#444444',
    height: '2rem',
    fontSize: '2rem',
    fontWeight: 'bold',
    _hover: {
        color: 'white',
        backgroundColor: 'brand.green',
        bg: 'transparent',
    }
});

const plain = defineStyle({
    borderWidth: 0,
    bg: 'transparent',
    backgroundColor: 'transparent',
    color: 'brand.black',
    fontSize: '1rem',
    fontWeight: 'medium',
    paddingLeft: '4px',
    minWidth: 'unset',
    paddingRight: '4px',
    marginTop: '0.5rem',
    _hover: {
        color: 'black',
        bg: 'transparent',
        backgroundColor: 'transparent',
        textDecoration: 'underline'
    }

})


const sizes = {
    primary: defineStyle({
        fontSize: '20px',
        px: '8',
        h: '9'
    }),
};

export const buttonTheme = defineStyleConfig({
    baseStyle,
    variants: { link, green, ghost, greenGhost, plain, dashboard },
    sizes,
});