import {IconButton} from "@chakra-ui/react";
import {EditIcon} from "@chakra-ui/icons";
import React from "react";

export const EditButton = ({onEdit}) => (
    <IconButton
        onClick={onEdit}
        position="absolute"
        right="2rem"
        icon={<EditIcon w={3} h={3} boxSize={5}/>}
        variant='greenGhost'
        aria-label='Edit'
    />
);
