import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Heading,
    Image,
    Center,
    FormErrorMessage,
    Container,
    Grid,
    GridItem,
    Card,
    CardBody
} from '@chakra-ui/react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth.jsx';
import * as Yup from "yup";

export default function ResetPasswordForm() {
    const { resetPassword, user } = useAuth();
    const [submissionComplete, setSubmissionComplete] = useState(false);
    const { t } = useTranslation();

    const schema = Yup.object().shape({
        password: Yup.string().required(t('general.required')),
        confirmpassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                t('resetpassword.mustbesame')
            )
        })
    });

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmpassword: "",
            email: params.email,
            token: params.token
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            const response = await resetPassword(values.email, values.password, values.token);
            if (response.ok) {
                setSubmissionComplete(true);
                formik.setStatus('resetpassword.checkyouremail');
            } else if (response.status === 400) {
                const errors = await response.json();
                formik.setStatus(errors.join(', '));
            } else {
                formik.setStatus(t('general.somethingwentwrong'));
            }
        }
    });

    // check has token
    //if (user.isLoggedIn) {
    //    return <Redirect to='/' />;
    //}

    if (submissionComplete) {
        return <Redirect to='/login' />;
    }

    return <Container maxW={'80rem'} padding={6}>
        <Grid
            templateColumns={['minmax(0, 1fr)', null, 'max-content minmax(0, 1fr)']}
            gap='2em'
            marginTop='2em'>

            <GridItem>
                <Card>
                    <CardBody padding='4em'>
                        <VStack>
                            <Heading>{t('resetpassword.title')}</Heading>
                            <form onSubmit={formik.handleSubmit}>
                                <VStack spacing={4} align="flex-start">
                                    <FormControl isInvalid={!!formik.status || formik.errors.password}>
                                        <FormErrorMessage>{formik.status} {formik.errors.password}</FormErrorMessage>
                                        <FormLabel htmlFor="password">{t('resetpassword.password')}</FormLabel>
                                        <Input
                                            id="password"
                                            name="password"
                                            type="password"
                                            variant="filled"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            width="30ch"
                                        />
                                    </FormControl>

                                    <FormControl isInvalid={formik.errors.confirmpassword}>
                                        <FormErrorMessage>{formik.errors.confirmpassword}</FormErrorMessage>
                                        <FormLabel htmlFor="confirmpassword">{t('resetpassword.confirmpassword')}</FormLabel>
                                        <Input
                                            id="confirmpassword"
                                            name="confirmpassword"
                                            type="password"
                                            variant="filled"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmpassword}
                                            width="30ch"
                                        />
                                    </FormControl>

                                    <input type="hidden" name="token" value={formik.values.token} />
                                    <input type="hidden" name="email" value={formik.values.email} />

                                    <VStack width="100%">
                                        <Button type="submit" width="full">
                                            {t('resetpassword.submit')}
                                        </Button>
                                    </VStack>
                                </VStack>
                            </form>
                        </VStack>
                    </CardBody>
                </Card>
            </GridItem>


            <GridItem>
                <Image src="/images/signup-hero.png" style={{
                    'height': '100%',
                    'aspectRatio': '868/587',
                    'objectFit': 'cover'
                }} />
            </GridItem>
        </Grid>
    </Container>;
    /*
    return (
        <Flex bg="gray.100" align="center" justify="center" h="100vh">
            <Flex>
                <Center bg="white">
                    <Box p={50} rounded="md">
                        <Center>
                            
                        </Center>
                        
                    </Box>
                </Center>
                <Center>
                    <Image src="/images/login-hero.png" alt="{login.hero}" />
                </Center>
            </Flex>
        </Flex>
    );*/
}