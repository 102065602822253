import {GenericModal} from "../base/genericModal";
import {useDisclosure} from "@chakra-ui/react";
import {forwardRef, useImperativeHandle} from "react";
import {useApi} from "../../hooks/useApi";
import {useParams} from "react-router-dom";

const createModalComponent = ({ title, ContentComponent, handleSubmit }) => {
    return forwardRef((props, ref) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        const {projects} = useApi();
        const {companyId, projectId} = useParams();

        useImperativeHandle(ref, () => ({
            openModal: () => {
                onOpen();
            }
        }));

        const handleFormSubmit = async (values) => {
            await handleSubmit(companyId, projectId, values, projects);
            onClose();
        };

        return (
            <GenericModal
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={handleFormSubmit}
                initialValues={props.project}
                title={title}
            >
                {(props) => <ContentComponent formikProps={props} />}
            </GenericModal>
        );
    });
}

export default createModalComponent;
