import React from 'react';
import { useFormikContext, useField } from "formik";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input
} from "@chakra-ui/react";
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.css";

// https://codesandbox.io/s/chakra-ui-datepicker-demo-qmx3c?file=/src/components/Datepicker/ReactDatePicker.tsx
// https://gist.github.com/igoro00/99e9d244677ccafbf39667c24b5b35ed

// export const DatePicker = (props) => {
//     const wrappedProps = {
//         selected: props.value,
//         onChange: (event) => props.onChange({ target: { name: props.name, value: event.target.value } })
//     };
//
//     return <ReactDatePicker {...props} {...wrappedProps} />;
// };

export const DatePickerControl = ({ name, labelKey, helperTextKey }) => {
    const { t } = useTranslation();
    const [field] = useField(name);
    const { errors, touched, setFieldValue } = useFormikContext();

    return <FormControl isInvalid={errors && errors[name] && touched[name]} paddingRight={5}>
        <FormLabel htmlFor={name}>{t(labelKey)}</FormLabel>
        <DatePicker
            {...field}
            value={null}
            inline
            dateFormat="dd/MM/yyyy"
            selected={field.value ? new Date(field.value) : null}
            onChange={date => setFieldValue(name, date)}
        />
        {helperTextKey && <FormHelperText>{t(helperTextKey)}</FormHelperText>}
        <FormErrorMessage>{errors && errors[name]}</FormErrorMessage>
    </FormControl>
};