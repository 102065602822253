import React from 'react';

import Message from './message.jsx';

import {
    Box,
    HStack,
    Text
} from '@chakra-ui/react';

const ChatWindow = (props) => {
    const chat = props.chat
        .map(m =>
            <Message
                key={Date.now() * Math.random()}
                {...m}
            />);

    return (
        <Box className="chat-window">
            <Box className="chat-window-inner">
                {chat}
            </Box>
        </Box>
    )
};

export default ChatWindow;