import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useApi} from '../../../hooks/useApi';
import * as Yup from 'yup';
import ProjectStep from '../projectStep';
import Step2Content from "./step2Content";
import useLocalStorageUtil from '../../../utils/localStorageUtils';

const VideoEditingStep2 = ({projectId: propProjectId, companyId: propCompanyId, isAnonymous, onNext}) => {
    const {t} = useTranslation();
    const {projects} = useApi();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const {companyId: paramCompanyId, projectId: paramProjectId} = useParams();
    const [guid] = useLocalStorageUtil();
    const history = useHistory();

    const companyId = propCompanyId || paramCompanyId;
    const projectId = propProjectId || paramProjectId;
    const nextStep = `/company/${companyId}/video-editing-project/${projectId}/step-3`;

    const updateProjectStep = async (values) => {
        const data = {...values, companyAssetTypes: selectedOptions};
        const updateFunction = isAnonymous ? projects.videoEditing.updateStep2Anonymous : projects.videoEditing.updateStep2;
        await updateFunction(companyId, projectId, isAnonymous ? guid : data, data);
    };

    const onSubmit = async (values) => {
        if (isAnonymous && !guid) {
            console.log("Invalid anonymous id");
            return;
        }
        await updateProjectStep(values);
        if(onNext !== undefined) {
            onNext(nextStep);
            return;
        }

        history.push(nextStep);
    };

    const validationSchema = Yup.object().shape({
        jobTitle: Yup.string().required(t('general.required')),
        description: Yup.string().required(t('general.required')),
        includeCompanyAssets: Yup.bool()
    });

    const projectStepProps = {
        companyId,
        projectId,
        heading: '②',
        subheading: t('project.all.step2.title'),
        onSubmit,
        next: nextStep,
        previous: `/create-project`,
        progress: 50,
        validationSchema,
        isAnonymous
    };

    return (
        <ProjectStep {...projectStepProps}>
            {(project, props) => (
                <Step2Content formikProps={props}/>
            )}
        </ProjectStep>
    );
};

export default VideoEditingStep2;
