import {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardBody,
    Container,
    Divider,
    GridItem,
    Heading,
    HStack,
    Image,
    SimpleGrid,
    useToast,
    VStack
} from '@chakra-ui/react';
import {useAuth} from '../../hooks/useAuth.jsx';
import {useApi} from '../../hooks/useApi.jsx';
import humps from 'humps';
import {Route, Switch, useHistory, useLocation, useRouteMatch, Link} from 'react-router-dom';
import CompaniesCard from './companiesCard.jsx';
import ProfileCard from './profileCard.jsx';
import UsersCard from './usersCard.jsx';
import {ProfilePhotoUploadControl} from '../forms'
import ComponentLoading from '../ComponentLoading';
import AllBrandsCard from "./allBrandsCard";

const Profile = () => {
    const {profile: profileService, company: companyService} = useApi();
    const {user, reloadProfile} = useAuth();
    const {path, url} = useRouteMatch();
    const [profile, setProfile] = useState({});
    const [editProfile, setEditProfile] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [company, setCompany] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useToast();
    const history = useHistory();
    const match = useRouteMatch({path, exact: true});
    const location = useLocation();
    const currentPath = location.pathname;


    const loadCompany = async () => {
        const companyResponse = await companyService.get();
        setCompany(await companyResponse.json());
    };

    const loadSelectedCompany = async () => {
        const companyResponse = await companyService.getById(selectedCompanyId);
        const company = await companyResponse.json();
        setSelectedCompany(company);
    };

    const loadAllCompanies = async () => {
        const companyResponse = await companyService.getAll();
        const response = await companyResponse.json();
        setAllCompanies(response);
    };

    useEffect(() => {
        const fn = async () => {
            const profileResponse = await profileService.get();
            const profile = await profileResponse.json();
            setProfile(profile);

            await loadCompany();

            await loadAllCompanies();
            setLoading(false);
        };

        fn();
    }, [user]);

    useEffect(() => {
        const fn2 = async () => {
            if (selectedCompanyId) {
                await loadSelectedCompany();
                setLoading(false);
            }
        };

        fn2();
    }, [selectedCompanyId]);

    // useEffect(() => {
    //     const fn2 = async () => {
    //         if(selectedUser){
    //             const profileResponse = await profileService.getById(selectedUser);
    //             const profile = await profileResponse.json();
    //             setEditProfile(profile);
    //             setLoading(false);
    //         }
    //     };
    //
    //     fn2();
    // }, [selectedUser]);


    const updateProfile = async (values, props) => {
        const response = await profileService.update(values);
        props.setSubmitting(false);
        if (response.status === 400) {
            const badRequest = await response.json();
            if (badRequest.errors) {
                const camelized = humps.camelizeKeys(badRequest.errors);
                props.setErrors(camelized);
            }
            return;
        }

        var profile = await response.json();
        reloadProfile(values.firstName);
        setProfile(profile);

        toast({
            title: 'Changes saved',
            status: 'success',
            duration: 4000,
            isClosable: false,
        })
    };

    const handleCreateCompany = async () => {
        await companyService.create();
        loadCompany();
    };

    const handleDeleteCompany = async (companyId) => {
        await companyService.del(companyId);
        loadCompany();
    }

    const handleUpdateCompany = (companyId) => async (values, props) => {
        const response = await companyService.update(companyId, values);

        if (response.status === 400) {
            const badRequest = await response.json();
            if (badRequest.errors) {
                const camelized = humps.camelizeKeys(badRequest.errors);
                props.setErrors(camelized);
            }
            return;
        }

        props.setSubmitting(false);
        loadCompany();
        loadSelectedCompany();
        toast({
            title: 'Changes saved',
            status: 'success',
            duration: 4000,
            isClosable: false,
        })
    }

    const handleRefreshCompanies = () => {
        loadCompany();
    };

    const handleUserEdit = (userId) => {
        setSelectedUser(userId);
        history.push(`/profile/company/${userId}`);
    }

    const handleCompanyEdit = (companyId) => {
        setSelectedCompanyId(companyId);
        history.push(`/profile/all-brands/edit/${companyId}`);
    }

    const handleSetCompanyId = (companyId) => {
        setSelectedCompanyId(companyId);
    }

    if (loading) {
        return <ComponentLoading/>;
    }

    //  _activeLink={{ fontWeight: "bold" }}>



    const MenuLink = ({ url, label, icon, isActive }) => {
        return (
            <Link to={url}>
                <HStack>
                    <Image src={`/images/icons/${icon}.svg`} width="50px" height="50px"/>
                    <Box style={{ fontWeight: isActive ? 'bold' : 'normal' }}>{label}</Box>
                </HStack>
            </Link>
        );
    };



    return <Container maxW={'7xl'} padding={6}>
        {match &&<Heading>My Profile</Heading>}
        <SimpleGrid gridTemplateColumns={['1fr', null, 'auto 1fr']} gap='3em'>
            <GridItem minW={'2xs'}>
                {match && <ProfilePhotoUploadControl name="profilePhoto" padding={6}/>}
                <Card style={{borderRadius: '20px', boxShadow: 'none'}}>
                    <CardBody style={{borderRadius: '20px'}}>
                        <VStack alignItems='left'>
                            <MenuLink
                                url={`${url}`}
                                icon='User'
                                label='My Profile'
                                isActive={currentPath === url}
                            />
                            <Divider/>
                            <MenuLink
                                url={`${url}/brand`}
                                icon='Users'
                                label='My Brand'
                                isActive={currentPath === `${url}/brand`}
                            />
                            {user.isAdmin && (
                                <>
                                    <Divider/>
                                    <MenuLink
                                        url={`${url}/all-brands`}
                                        icon='Setting'
                                        label='Brands'
                                        isActive={currentPath === `${url}/all-brands`}
                                    />
                                    <Divider/>
                                    <MenuLink
                                        url={`${url}/users`}
                                        icon='Setting'
                                        label='Users'
                                        isActive={currentPath === `${url}/users`}
                                    />
                                </>
                            )}
                        </VStack>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem>
                <Switch>
                    <Route exact path={path}>
                        <ProfileCard
                            profile={profile}
                            updateProfile={updateProfile}
                        />
                    </Route>
                    <Route path={`${path}/brand`}>
                        <CompaniesCard
                            companies={company}
                            createCompany={handleCreateCompany}
                            updateCompany={handleUpdateCompany}
                            deleteCompany={handleDeleteCompany}
                            refresh={handleRefreshCompanies}
                        />
                    </Route>
                    <Route path={`${path}/all-brands/edit/:companyId`}>
                        {user.isAdmin && <CompaniesCard
                            companies={selectedCompany}
                            createCompany={handleCreateCompany}
                            updateCompany={handleUpdateCompany}
                            deleteCompany={handleDeleteCompany}
                            refresh={handleRefreshCompanies}
                            setCompanyId={handleSetCompanyId}
                        />}
                    </Route>
                    <Route path={`${path}/all-brands`}>
                        {user.isAdmin && <AllBrandsCard companies={allCompanies} onCompanyEdit={handleCompanyEdit}/>}
                    </Route>

                    <Route path={`${path}/profile/:profileId`}>
                        {user.isAdmin && <UsersCard profile={editProfile}/>}
                    </Route>
                    <Route path={`${path}/users`}>
                        {user.isAdmin && <UsersCard onUserEdit={handleUserEdit}/>}
                    </Route>
                </Switch>
            </GridItem>
        </SimpleGrid>
    </Container>;
}

export default Profile;