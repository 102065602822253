// hooks/useSubmitProject.js
import {useHistory} from 'react-router-dom';
import {useToast} from '@chakra-ui/react';

const useSubmitProject = (submitFunction, successRedirect) => {
    const history = useHistory();
    const toast = useToast();

    return async (companyId, projectId) => {
        try {
            const response = await submitFunction(companyId, projectId);

            if (response.status === 400) {
                toast({
                    title: 'Failed to submit project',
                    status: 'error',
                    duration: 4000,
                    isClosable: false,
                });
            } else {
                toast({
                    title: 'Successfully submitted project',
                    status: 'success',
                    duration: 4000,
                    isClosable: false,
                });
                history.push(successRedirect);
            }
        } catch (error) {
            toast({
                title: 'An error occurred',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    };
};

export default useSubmitProject;
