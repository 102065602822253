import {Flex, Input, VStack} from "@chakra-ui/react";
import {ColourPicker, ControlListControl, InputControl, SwitchControl, TextareaControl} from "../../forms";

const Step2Content = () => {
    return (
        <Flex direction={['column', null, 'row']} p={3} w="full">
            {/* First Column */}
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]}>
                <InputControl name="jobTitle" labelKey="project.all.step2.jobTitle" required/>
                <TextareaControl name="description" labelKey="project.all.step2.description" required />

                <SwitchControl name="includeCompanyAssets" labelKey="project.all.step2.includeCompanyAssets" />

            </VStack>

            {/* Second Column */}
            <VStack width={['100%', null, '50%']} spacing={6}>
                <InputControl
                    name="footageLink"
                    labelKey="project.motionGraphics.step2.footageLink"
                    variant="projectLink"
                />
                <ControlListControl
                    name="inspirations"
                    labelKey="project.all.step2.inspirations"
                    defaultValue={''}
                >
                    {(props) => <Input {...props} variant="projectLink" />}
                </ControlListControl>

                <ControlListControl
                    name="existingAssets"
                    labelKey="project.all.step2.existingAssets"
                    defaultValue={''}
                    helperTextKey="We accept any file sharing link - e.g. wetransfer, dropbox"
                >
                    {(props) => <Input {...props} variant="projectLink" />}
                </ControlListControl>

                <ControlListControl
                    name="preferredColourSpecification"
                    labelKey="project.all.step2.preferredColourSpecification"
                    defaultValue={''}
                >
                    {(props) => <ColourPicker name={props.name} labelKey="" variant='projectLink' {...props} />}
                </ControlListControl>
            </VStack>
        </Flex>
    )
}

export default Step2Content;