import React from 'react';
import { useFormikContext, useField } from "formik";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

export default ({ Component, name, labelKey, helperTextKey, ...props }) => {
    const { t } = useTranslation();
    const [field] = useField(name);
    const { errors, touched } = useFormikContext();
    const hasError = touched[name] && errors[name];

    const variant = props?.variant ?? 'primary';
    const required = props?.required ?? false;
    return <FormControl isInvalid={errors && errors[name] && touched[name]}>
        <FormLabel htmlFor={name}>
            {t(labelKey)}
            {required && <span style={{color: 'red'}}>*</span>}
        </FormLabel>
        <Component {...field} {...props} style={hasError ? { borderColor: 'red' } : null} variant={variant} />
        {helperTextKey && <FormHelperText>{t(helperTextKey)}</FormHelperText>}
        <FormErrorMessage>{errors && errors[name]}</FormErrorMessage>
    </FormControl>
};
