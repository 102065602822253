import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useApi} from "../../../hooks/useApi";
import useLocalStorageUtil from "../../../utils/localStorageUtils";
import Step2Content from "./step2Content";
import ProjectStep from "../projectStep";

const ChannelBrandingStep2 = ({projectId: propProjectId, companyId: propCompanyId, isAnonymous, onNext}) => {
    const {t} = useTranslation();
    const {projects} = useApi();
    const {companyId: paramCompanyId, projectId: paramProjectId} = useParams();
    const [guid] = useLocalStorageUtil();
    const history = useHistory();

    const companyId = propCompanyId || paramCompanyId;
    const projectId = propProjectId || paramProjectId;
    const nextStep = `/company/${companyId}/channel-branding-project/${projectId}/step-3`;

    const updateProjectStep = async (values) => {
        const updateFunction = isAnonymous
            ? projects.channelBranding.updateStep2Anonymous
            : projects.channelBranding.updateStep2;
        await updateFunction(companyId, projectId, isAnonymous ? guid : values, values);
    };

    const onSubmit = async (values) => {
        if (isAnonymous && !guid) {
            console.log("Invalid anonymous id");
            return;
        }
        await updateProjectStep(values);

        if(onNext !== undefined) {
            onNext(nextStep);
            return;
        }

        history.push(nextStep);
    };

    const projectStepProps = {
        companyId,
        projectId,
        heading: '②',
        subheading: t('project.all.step2.title'),
        onSubmit,
        next: nextStep,
        previous: `/create-project`,
        progress: 40,
        isAnonymous: isAnonymous ?? false
    };

    return <ProjectStep {...projectStepProps}>
        {() => <Step2Content/>}
    </ProjectStep>;
};

export default ChannelBrandingStep2;
