import {Divider, Heading, Stack, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import LabelledImage from "../forms/fileUploadControl";

const CompanyAssets = ({project}) => {
    const {t} = useTranslation();
    const hasImages = project.company.logoColoured || project.company.logoMonochrome ||
        project.company.logoBlackWhiteBackground || project.company.logoWhiteBlackBackground ||
        project.company.visuals || project.company.brandGuidelines;
    return (
        <>
            {project.includeCompanyAssets && (
                <>
                    <Heading size='md' textTransform='uppercase'>Brand Assets</Heading>
                    <Divider style={{borderColor: 'black'}}/>

                    {!hasImages ? (
                        <Text>No assets available</Text>
                    ) : (
                        <>
                            <Stack direction={{base: "column", md: "row"}}>
                                {project.company.logoColoured && <LabelledImage
                                    label="Logo Coloured Version"
                                    imageUrl={project.company.logoColoured}
                                />}
                                {project.company.logoMonochrome && <LabelledImage
                                    label="Logo Monochrome Version"
                                    imageUrl={project.company.logoMonochrome}
                                />}
                                {project.company.logoBlackWhiteBackground && <LabelledImage
                                    label="Logo Black/White Background"
                                    imageUrl={project.company.logoBlackWhiteBackground}
                                />}
                            </Stack>
                            <Stack direction={{base: "column", md: "row"}}>
                                {project.company.logoWhiteBlackBackground && <LabelledImage
                                    label="Logo White/Black Background"
                                    imageUrl={project.company.logoWhiteBlackBackground}
                                />}
                                {project.company.visuals && <LabelledImage
                                    label="Company Visuals"
                                    imageUrl={project.company.visuals}
                                />}
                                {project.company.brandGuidelines && <LabelledImage
                                    label="Company Brand Guidelines"
                                    imageUrl={project.company.brandGuidelines}
                                />}
                            </Stack>
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default CompanyAssets;