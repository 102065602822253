import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tagAnatomy.keys)

const gray = definePartsStyle({
    container: {
        bg: '#7B7B7B',
        color: 'white',
        fontWeight: 'bold',
    },
})

const black = definePartsStyle({
    container: {
        bg: '#444444',
        color: 'white',
    },
})

const thick = definePartsStyle({
    container: {
        px: '4',
        py: '2',
        bg: 'blue.400',
    },
})

export const tagTheme = defineMultiStyleConfig({
    variants: {
        gray: gray,
        thick: thick,
        black
    },
})
