import {Center, Heading, VStack} from "@chakra-ui/react";
import ProjectStatus from "../dashboard/projectStatus";

export const ProjectDetailsHeader = ({ project }) => (
    <Center mb="4em">
        <VStack>
            <Heading>{project.jobTitle}</Heading>
            <Heading size="xs">
                <ProjectStatus project={project}/>
            </Heading>
        </VStack>
    </Center>
);