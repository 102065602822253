import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
   paddingTop: 1,
    fontSize: "13px",
    lineHeight: "normal",
    fontWeight: "700",
});

export const formLabelTheme = defineStyleConfig({
  baseStyle
})