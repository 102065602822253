import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
    borderColor: '#7B7B7B',
    borderWidth: '1.5px',
    marginTop: '1rem',
    marginBottom: '1rem'
});

export const dividerTheme = defineStyleConfig({
  baseStyle,
});