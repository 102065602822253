import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Link,
    Spacer,
    Text,
    useToast,
    VStack
} from '@chakra-ui/react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {GoogleLogin} from '@react-oauth/google';
import {InputControl} from '../forms';
import React from "react";
import BaseCard from "../baseCard";
import {useAuth} from "../../hooks/useAuth";
import {useHistory, useLocation} from "react-router-dom";

const SignupUser = ({onSubmit, onGoogleSubmit, userType}) => {
    const {t} = useTranslation();

    const history = useHistory();
    const auth = useAuth();
    const {search} = useLocation();
    const params = React.useMemo(() => new URLSearchParams(search), [search]);
    const token = params.get('token');
    const toast = useToast();

    const handleGoogleFailure = () => {
        toast({
            title: 'Failed to login with Google',
            status: 'error',
            duration: 4000,
            isClosable: false,
        });
        console.log("Google login failed");
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });


    const formikProps = {
        initialValues: {
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema,
        onSubmit
    };
    const handleGoogleSuccess = async (googleResponse) => {
        const isFreelancer = userType === 'freelance';
        const loginResponse = await auth.handleGoogleSuccess(googleResponse, token, isFreelancer);
        if (loginResponse.userHasCompletedProfile) {
            history.push('/');
        } else {
            onGoogleSubmit();
        }

    }

    const OrDivider = () => {
        return (
            <Flex align="center" justify="center" position="relative" width={'100%'}>
                <Divider mr="20px"/>
                <Box position="absolute" bg="white" bottom="5px">
                    <Text as="span" color="brand.green" fontSize="sm">OR</Text>
                </Box>
                <Divider ml="20px"/>
            </Flex>
        );
    };

    return <BaseCard
        maxWidth={'80rem'}
        padding={6}
        borderRadius={'20px'}
        width={'400px'}
    >
        <Heading textAlign='center' size={'lg'} pb="1rem">Welcome!</Heading>
        <Box pt={"1rem"} pb={"1rem"}>
            <GoogleLogin onSuccess={handleGoogleSuccess} onError={handleGoogleFailure}/>
        </Box>
        <OrDivider/>
        <Heading textAlign='center' size={'md'} pt="0.5rem">Sign up</Heading>
        <Formik {...formikProps} width={'100%'}>
            {(props) => {
                return <form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                    <VStack width="100%">
                        <FormControl isInvalid={props.errors && props.errors['']} paddingRight={5}>
                            <FormErrorMessage>{props.errors && props.errors['']}</FormErrorMessage>
                        </FormControl>
                        <InputControl name="email" type="email" variant="settings"
                                      labelKey='signup.email'/>
                        <InputControl name="password" type="password" variant="settings"
                                      labelKey='signup.password'/>
                        <InputControl name="confirmPassword" type="password" variant="settings"
                                      labelKey='signup.confirmPassword'/>
                        <br/>
                        <Button type="submit" variant="primary_blue" size={'lg'}>
                            {t('signup.submit')}
                        </Button>
                        <Spacer/>
                        <Box>
                            <Box>
                                <Text as="span" fontSize="sm" fontWeight="300" opacity="0.6">Your data is safe with us.
                                    Check our</Text>
                            </Box>
                            <Link style={{textDecoration: 'underline'}}>Privacy Policy</Link> | <Link
                            style={{textDecoration: 'underline'}}>Terms and conditions</Link>
                        </Box>
                    </VStack>
                </form>
            }}
        </Formik>
    </BaseCard>
};

export default SignupUser;