import {useAuth} from "../../hooks/useAuth";
import {useApi} from "../../hooks/useApi";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Box, Select, Tag} from "@chakra-ui/react";
import projectColours from "../projects/projectColours";

const ProjectStatus = ({ project }) => {
    const { user } = useAuth();
    const { projects: projectsApi } = useApi();
    const { t } = useTranslation();
    const colour = projectColours[project.status];
    const history = useHistory();

    const handleProjectStatusChanged = async (e) => {
        await projectsApi.setStatus(project.companyId, project.projectId, e.target.value);
        history.go(0);
    };

    const styleProps = {
        cursor: 'pointer',
        borderColor: colour,
        borderWidth: 3,
        borderRadius: 20,
        padding: 2,
        paddingLeft: 3,
        paddingRight: 3,
        color: colour,
        maxWidth: '220px',
        marginLeft: 'auto',
        marginRight: 'auto',
        bg: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        _hover: {
            bg: colour,
            color: 'white',
            borderColor: colour
        },
        _focus: {
            borderColor: colour,
            outline: 'none'
        }
    };

    if (user.isAdmin) {
        const statuses = t('projects.' + project.projectTypeIdentifier + '.status', {returnObjects: true});
        const options = Object.entries(statuses).map((e) => {
            const [k, v] = e;
            return <option style={{textAlign: 'left', color: '#4D4D4D',}} value={k} key={k}>{v}</option>;
        });

        return <Select name="view" onChange={handleProjectStatusChanged} value={project.status} {...styleProps}>
            {options}
        </Select>;
    }

    return <>
        <Tag variant="black" bg={styleProps.color}>{t(`projects.${project.projectTypeIdentifier}.status.${project.status}`)}</Tag>
    </>
};

export default ProjectStatus;