import {Box, Flex, Text, VStack} from "@chakra-ui/react";

const DetailInformation = ({project}) => {
    return (
        <Flex direction={['column', null, 'row']} w="full">
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]} alignItems={"start"}>
                <Box>
                    <Text fontWeight="bold">Job Title</Text>
                    <Text>{project.jobTitle}</Text>
                </Box>

                <Box>
                    <Text fontWeight="bold">Job Description</Text>
                    <Text whiteSpace="pre-wrap">{project.description}</Text>
                </Box>

                <Box>
                    <Text fontWeight="bold">In what format is the source content?</Text>
                    <Text>{project.whatFormat}</Text>
                </Box>
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6} alignItems={"start"}>
                <Box>
                    <Text fontWeight="bold">Source File</Text>
                    <Text>{project.sourceFile}</Text>
                </Box>

                <Box>
                    <Text fontWeight="bold">I want to translate from</Text>
                    <Text>{project.localizeFrom?.name}</Text>
                </Box>

                <Box>
                    <Text fontWeight="bold">I want to translate to</Text>
                    <Text>{project.localizeTo?.name}</Text>
                </Box>
            </VStack>
        </Flex>
    )
}
export default DetailInformation;