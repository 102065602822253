import {Checkbox, Flex, Input, VStack} from "@chakra-ui/react";
import {ControlListControl, InputControl, LengthOfVideoTypePicker, SwitchControl, TextareaControl} from "../../forms";
import {Field} from "formik";

const Step2Content = ({ formikProps }) => {
    const checkboxes = [
        { name: 'logoColoured', label: 'Logo Coloured Version', key: 0 },
        { name: 'logoMonochrome', label: 'Logo Monochrome Version', key: 1 },
        { name: 'logoBlackWhiteBackground', label: 'Logo Black/White background', key: 2 },
        { name: 'logoWhiteBlackBackground', label: 'Logo White/Black background', key: 3 },
        { name: 'visuals', label: 'Company Visuals', key: 4 },
        { name: 'brandGuidelines', label: 'Company Brand Guidelines', key: 5 },
    ];

    return (
        <Flex direction={['column', null, 'row']} p={3} w="full">
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]}>
                <InputControl
                    name="jobTitle"
                    labelKey="project.all.step2.jobTitle"
                    required
                />
                <TextareaControl
                    name="description"
                    labelKey="project.all.step2.description"
                    required
                />

                <SwitchControl
                    name="includeCompanyAssets"
                    labelKey="project.all.step2.includeCompanyAssets"
                    required
                />
                {formikProps?.values?.includeCompanyAssets && (
                    <VStack alignItems="start" spacing={3}>
                        {checkboxes.map(({ name, label, key }) => (
                            formikProps?.values?.company[name] != null && (
                                <Field name={name} key={key}>
                                    {({ field }) => (
                                        <Checkbox
                                            {...field}
                                            value={name}
                                            isChecked={formikProps?.values[name]}
                                        >
                                            {label}
                                        </Checkbox>
                                    )}
                                </Field>
                            )
                        ))}
                    </VStack>
                )}

                <SwitchControl
                    name="alreadyHaveFootage"
                    labelKey="project.videoEditing.step2.alreadyHaveFootage"
                />

                <InputControl
                    name="footageLink"
                    labelKey="project.videoEditing.step2.footageLink"
                    variant="projectLink"
                    helperTextKey="We accept any file sharing link - e.g. wetransfer, dropbox"
                />
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6}>
                <ControlListControl
                    name="previousEpisodes"
                    labelKey="project.videoEditing.step2.referenceVideos"
                    defaultValue=""
                >
                    {(props) => <Input {...props} variant="projectLink" />}
                </ControlListControl>

                <LengthOfVideoTypePicker
                    name="lengthOfVideos"
                    labelKey="project.videoEditing.step2.lengthOfVideos"
                />

                <ControlListControl
                    name="existingAssets"
                    labelKey="project.all.step2.existingAssets"
                    defaultValue=""
                    helperTextKey="We accept any file sharing link - e.g. wetransfer, dropbox"
                >
                    {(props) => <Input {...props} variant="projectLink" />}
                </ControlListControl>

                <SwitchControl
                    name="isPartOfSeries"
                    labelKey="project.videoEditing.step2.isPartOfSeries"
                />
            </VStack>
        </Flex>
    )
}

export default Step2Content;