import {Flex, Input, VStack} from '@chakra-ui/react';
import {ColourPicker, ControlListControl, InputControl, SwitchControl, TextareaControl} from '../../forms';

const Step2Content = () => {
    return (
        <Flex direction={['column', null, 'row']} w="full" spacing={6}>
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]}>
                <InputControl
                    name="jobTitle"
                    labelKey="project.all.step2.jobTitle"
                    required
                />

                <TextareaControl
                    name="description"
                    labelKey="project.all.step2.description"
                    required
                />

                <SwitchControl
                    name="includeCompanyAssets"
                    labelKey="project.all.step2.includeCompanyAssets"
                />
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6}>
                <ControlListControl
                    name="existingAssets"
                    labelKey="project.all.step2.existingAssets"
                    defaultValue={''}
                    helperTextKey="We accept any file sharing link - e.g. wetransfer, dropbox"
                >
                    {(props) => <Input {...props} variant="projectLink"/>}
                </ControlListControl>

                <ControlListControl
                    name="inspirations"
                    labelKey="project.all.step2.inspirations"
                    defaultValue={''}
                >
                    {(props) => <Input {...props} variant="projectLink"/>}
                </ControlListControl>

                <ControlListControl
                    name="preferredColourSpecification"
                    labelKey="project.all.step2.preferredColourSpecification"
                    defaultValue={''}
                >
                    {(props) => <ColourPicker name={props.name} labelKey="" variant='projectLink' {...props} />}
                </ControlListControl>
            </VStack>
        </Flex>
    );
};

export default Step2Content;