import {Box, Flex} from "@chakra-ui/react";
import {DatePickerControl, SwitchControl} from "../../forms";

const Step3Content = () => {
    return (
        <Flex direction={['column', null, 'row']} wrap="wrap" w="full" spacing={6}>
            {/* First Item */}
            <Box width={['100%', null, '50%']} mb={6} pr={[0, null, 8]}>
                <DatePickerControl
                    name="deadline"
                    labelKey="project.all.step3.deadline"
                />
            </Box>

            {/* Second Item */}
            <Box width={['100%', null, '50%']} mb={6}>
                <SwitchControl
                    name="asap"
                    labelKey="project.all.step3.asap"
                />
            </Box>
        </Flex>
    );
};

export default Step3Content;