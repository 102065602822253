import React from 'react';
import { Box, Tag } from '@chakra-ui/react';

const ColorDisplay = ({ colorString }) => {
  return (
    <Box>
      {colorString?.map((color, index) => (
          <Tag key={index} bg={color} color="white" my={1} mr={1}>
            {color}
          </Tag>
      ))}
    </Box>
  );
};

export default ColorDisplay;