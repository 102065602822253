import {Button, Card, CardBody, Container, Heading, HStack, Link, Spacer, VStack} from "@chakra-ui/react";
import {useProject} from "../entities";
import ComponentLoading from "../ComponentLoading";
import ProgressBar from "./progressBar";

const GenericLastStep = ({
                             projectId,
                             companyId,
                             handleSubmit,
                             DetailComponent,
                             heading,
                             subheading,
                             progress
                         }) => {
    const {entity: project, loading, setLoading} = useProject(companyId, projectId);
    const dashboard = `/`;

    if (loading) {
        return <ComponentLoading/>;
    }

    const handleProjectUpdate = () => {
        setLoading(true);
    };

    return (
        <Container maxW={'6xl'} padding={6}>
            <Card>
                <CardBody padding={12}>
                    <VStack>
                        <Heading size='2xl'>{heading}</Heading>
                        <Heading>{subheading}</Heading>
                    </VStack>
                    <ProgressBar progress={progress}/>
                    <DetailComponent project={project} onProjectUpdate={handleProjectUpdate}/>
                    <HStack marginTop='5'>
                        <Link href={dashboard}>Back to dashboard</Link>
                        <Spacer/>
                        <Button type="submit" variant='primary' onClick={handleSubmit}>
                            Submit Brief
                        </Button>
                    </HStack>
                </CardBody>
            </Card>
        </Container>
    );
};
export default GenericLastStep;