import {Flex, VStack} from "@chakra-ui/react";
import {DatePickerControl, MotionGraphicsFinalFileVersionTypePicker, SwitchControl} from "../../forms";

const Step3Content = () => {
    return (
        <Flex direction={['column', null, 'row']} p={3} w="full">
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]}>
                <MotionGraphicsFinalFileVersionTypePicker
                    name="finalFileVersions"
                    labelKey="project.motionGraphics.step3.finalFileVersions"
                />
                <SwitchControl name="formats" labelKey="project.motionGraphics.step3.formats"/>
                <SwitchControl name="receiveProjectFiles" labelKey="project.all.step3.receiveProjectFiles"/>
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6}>
                <DatePickerControl name="deadline" labelKey="project.all.step3.deadline"/>
                <SwitchControl name="asap" labelKey="project.all.step3.asap"/>

            </VStack>
        </Flex>
    )
}

export default Step3Content;