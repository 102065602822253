import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
    body: {
        borderRadius: '5px',
        bg: 'brand.white',
    },
});

/*const rounded = definePartsStyle({
    body: {
        borderRadius: '0'
    }
});*/

export const cardTheme = defineMultiStyleConfig({ 
    baseStyle,
    /*variants: { }*/
});