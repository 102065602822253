import React from 'react';
import { useField } from "formik";
import {
    FormLabel,
    Icon,
    HStack,
    FormHelperText,
    FormControl
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.css";
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';

export const ControlList = ({ children, defaultValue, ...props }) => {
    const values = props.value && props.value.length > 0 ? props.value : [defaultValue];
    const add = () => props.onChange({ target: { name: props.name, value: [...values, defaultValue] } });
    const remove = (idx) => props.onChange({ target: { name: props.name, value: values.filter((element, index) => index !== idx) } });

    const handleOnChange = (idx, event) => {
        const newValues = [...values];
        newValues[idx] = event.target.value;
        props.onChange({ target: { name: props.name, value: newValues } });
    };

    const itemToControl = (idx) => {
        const controlProps = {
            ...props,
            name: `${props.name}_${idx}`,
            value: values[idx],
            onChange: (event) => handleOnChange(idx, event)
        };

        const isLast = idx === values.length - 1;
        return <HStack key={idx} marginBottom={1}>
            {children(controlProps)};
            {isLast ?
                <Icon onClick={add} as={FaPlusCircle} /> :
                <Icon onClick={() => remove(idx)} as={FaMinusCircle} />}
        </HStack>;
    };

    const controls = values.map((v, i) => itemToControl(i));
    return <>{controls}</>;
};

export const ControlListControl = ({ name, labelKey, defaultValue, helperTextKey, children }) => {
    const [field] = useField(name);
    const { t } = useTranslation();

    return <FormControl>
        <FormLabel>{t(labelKey)}</FormLabel>
        <ControlList {...field} defaultValue={defaultValue}>
            {(props) => children(props)}
        </ControlList>
        {helperTextKey && <FormHelperText>{t(helperTextKey)}</FormHelperText>}
    </FormControl>;
}