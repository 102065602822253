import {Divider, Heading, HStack, VStack} from "@chakra-ui/react";
import {EditButton} from "./editButton";

export const ProjectDetailsInformation = ({ onEdit, children }) => (
    <>
        <HStack mt={8}>
            <Heading size='md' textTransform='uppercase'>Project Information</Heading>
            <EditButton onEdit={onEdit}></EditButton>
        </HStack>
        <Divider borderColor='black'/>
        <VStack spacing={6} align="start">
            {children}
        </VStack>
    </>
);
