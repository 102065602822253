import React from 'react';
import Avatar from '../Avatar.jsx';
import {
    Box,
    HStack,
    VStack,
    Text
} from '@chakra-ui/react';

// Fix flashing on reload
// https://github.com/chakra-ui/chakra-ui/issues/149

const Message = (props) => (
    <Box className="chat-message">
        <Avatar userId={props.userId} />
        <Box className="details">
            <Box className="meta">
                {props.user &&
                    <Text className="user-details">{props.user.name}</Text>
                }
                <Text className="date-time">
                    {Intl.DateTimeFormat("en-gb",
                        {
                            day: '2-digit',
                            month: 'short',
                            hour: '2-digit',
                            minute: '2-digit'
                        }).format(new Date(props.created))}
                </Text>
            </Box>
            <Text className="body">{props.message}</Text>
        </Box>
    </Box>
);

export default Message;