/*
 * See https://chakra-templates.dev/navigation/navbar
 */

import {
    Box,
    Button,
    Center,
    Collapse,
    Container,
    Flex,
    Icon,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    Tooltip,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronRightIcon, CloseIcon, HamburgerIcon, WarningIcon,} from '@chakra-ui/icons';
import {useAuth} from 'hooks/useAuth.jsx';
import Avatar from 'components/Avatar.jsx';

const profile = (auth) => {
    if (auth.user.isLoggedIn) {
        const freelanceToggleText = auth.user.isFreelanceMode ? 'Switch to company view' : 'Switch to freelance view';

        return <>
            {auth.user.isAdmin && <Center><Tooltip label='This user is an admin'><WarningIcon/></Tooltip></Center>}
            <Menu>
                <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}>
                    <Avatar size="sm" userId={auth.user.sub}/>
                </MenuButton>
                <MenuList>
                   <MenuItem><Link
                        onClick={() => auth.setFreelanceMode(!auth.user.isFreelanceMode)}>{freelanceToggleText}</Link>
                   </MenuItem>
                    <MenuItem><Link href="/profile">Profile</Link></MenuItem>
                    <MenuDivider/>
                    <MenuItem><Link onClick={auth.logout}>Log out</Link></MenuItem>
                </MenuList>
            </Menu>
        </>;
    }
    return <>
        <Button
            as={'a'}
            fontSize={'lg'}
            fontWeight={500}
            variant={'link'}
            textDecoration={'none'}
            color={'#4A5568'}
            _hover={{
                color: '#FF4ADD',
            }}
            href={'/login'}>
            Sign In
        </Button>
        <Button
            as={'a'}
            borderWidth="2px"
            fontWeight={600}
            fontSize={'lg'}
            display={{base: 'none', md: 'inline-flex'}}
            href={'/signup'}>
            Sign Up
        </Button>
    </>;
}

const WithSubnavigation = () => {
    const {isOpen, onToggle} = useDisclosure();
    const auth = useAuth();

    return <Box bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'74px'}
                py={{base: 2}}
                px={{base: 4}}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}>
        <Container maxW={'100rem'}>
            <Flex
                align={'center'}>
                <Flex
                    flex={{base: 1, md: 'auto'}}
                    ml={{base: -2}}
                    display={{base: 'flex', md: 'none'}}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3}/> : <HamburgerIcon w={5} h={5}/>
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>

                <Flex>
                    <Link href='/'><Image src="/images/logo.png" alt="Logo" width={54} mt="10px" mb="10px"/></Link>
                </Flex>

                <Stack
                    flex={{base: 1}}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    <Flex display={{base: 'none', md: 'flex'}}>
                        <Center>
                            <DesktopNav/>
                        </Center>
                    </Flex>
                    {profile(auth)}
                </Stack>

            </Flex>
        </Container>

        <Collapse in={isOpen} animateOpacity>
            <MobileNav/>
        </Collapse>
    </Box>;
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                pl={2}
                                pr={2}
                                href={navItem.href ?? '#'}
                                fontSize={'lg'}
                                fontWeight={500}
                                color={linkColor}
                                textDecoration={'none'}
                                _hover={{
                                    color: '#FF4ADD',
                                }}>
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({label, href, subLabel}) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{bg: useColorModeValue('pink.50', 'gray.900')}}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{color: 'pink.400'}}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{opacity: '100%', transform: 'translateX(0)'}}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon}/>
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{md: 'none'}}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({label, children, href}) => {
    const {isOpen, onToggle} = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{marginTop: '0!important'}}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

const NAV_ITEMS = [
    {
        label: 'Dashboard',
        href: '/'
    },
    {
        label: 'About',
        href: 'https://elefantrock.com/about/'
    },
    {
        label: 'Clients',
        href: 'https://elefantrock.com/clients/'
    },
    {
        label: 'Blog',
        href: 'https://elefantrock.com/blog/',
    },
    {
        label: 'Contact',
        href: 'https://elefantrock.com/contact/',
    },
];

export default WithSubnavigation;