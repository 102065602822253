import React from 'react';
import { Card, CardBody, Container, Flex, VStack } from '@chakra-ui/react';

const BaseCard = ({ maxWidth, padding, borderRadius, width, children }) => {
    return (
        <Container maxW={maxWidth} padding={padding}>
            <Flex justify={'center'}>
                <Card borderRadius={borderRadius} width={width}>
                    <CardBody padding='2rem 4rem' borderRadius={borderRadius}>
                        <VStack>
                            {children}
                        </VStack>
                    </CardBody>
                </Card>
            </Flex>
        </Container>
    );
};

export default BaseCard;
