export const projectLink = (project) => {
    let href;
    switch (project.status) {
        case 'Step2':
            href = `/company/${project.companyId}/${project.projectTypeIdentifier}-project/${project.projectId}/step-2`;
            break;
        case 'Step3':
            href = `/company/${project.companyId}/${project.projectTypeIdentifier}-project/${project.projectId}/step-3`;
            break;
        case 'Step4':
            href = `/company/${project.companyId}/${project.projectTypeIdentifier}-project/${project.projectId}/step-4`;
            break;
        default:
            href = `/company/${project.companyId}/project/${project.projectId}`;
            break;
    }

    return href;
};