import {
    Box, Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import React from "react";

const DeleteProjectModal = ({ isOpen, onClose, handleDeleteProject }) => {

    const handleDelete = () => {
        handleDeleteProject();
        onClose();
    }

    return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Delete Project</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                Are you sure?
            </ModalBody>
            <ModalFooter>
                <HStack>
                    <Box>
                        <Button onClick={handleDelete}>Delete</Button>
                    </Box>
                    <Box>
                        <Button onClick={onClose}>Cancel</Button>
                    </Box>
                </HStack>
            </ModalFooter>
        </ModalContent>

    </Modal>
}

export default DeleteProjectModal;
