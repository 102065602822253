// https://usehooks.com/useAuth/
// https://www.npmjs.com/package/@react-oauth/google
// https://blog.thoughtspile.tech/2021/10/27/better-react-context/
import React, { useContext, createContext } from "react";
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import useCookie from 'react-use-cookie';
import { usePublicApi } from './useApi.jsx';
import jwt_decode from 'jwt-decode';

const authContext = createContext();

export function AuthProvider({ children, googleClientId }) {
    const auth = useAuthProvider();
    
    return <GoogleOAuthProvider clientId={googleClientId}>
            <authContext.Provider value={auth}>
                {children}
            </authContext.Provider>
        </GoogleOAuthProvider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

const encode = (user) => {
    const json = JSON.stringify(user);
    return utf8ToBase64(json);
};

function utf8ToBase64(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
    }));
}

function base64ToUtf8(str) {
    return decodeURIComponent(atob(str).split('').map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}



const decode = (binary) => {
    return JSON.parse(base64ToUtf8(binary));
}

function useAuthProvider() {
    const { account: accountApi } = usePublicApi();
    const [bUser, setUser] = useCookie('user', encode({ isLoggedIn: false }));
    const getCookieOptions = (rememberMe) => ({
        SameSite: 'strict',
        Secure: true,
        days: rememberMe ? 28 : 1
    });

    const user = decode(bUser);

    const _setUserWithToken = async (isLoggedIn, isGoogleUser, rememberMe, token, isFreelancer) => {
        const decoded = jwt_decode(token);
        const isAdmin = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'admin';
        await _setUser(isLoggedIn, isGoogleUser, rememberMe, token, decoded.email, decoded.firstName, isFreelancer ?? !isAdmin, isAdmin, Date.now());
    }

    const _setUser = async (isLoggedIn, isGoogleUser, rememberMe, token, email, firstName, isFreelanceMode, isAdmin, counter) => {
        let decodedToken = {};
        if (token) {
            const tokenParts = token.split('.');
            if (tokenParts.length > 1) {
                const base64Url = tokenParts[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const json = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                decodedToken = JSON.parse(json);
                
            }
        }

        const newUser = {
            isLoggedIn,
            isGoogleUser,
            token,
            rememberMe,
            email,
            firstName,
            isFreelanceMode,
            isAdmin,
            counter,
            ...decodedToken
        };

        setUser(encode(newUser), getCookieOptions(rememberMe));
    };

    const handleGoogleSuccess = async (googleResponse, invitationToken, isFreelancer) => {
        const response = await accountApi.externalLogin(googleResponse.credential, invitationToken);
        const loginResponse = await response.json();
        await _setUserWithToken(true, true, true, loginResponse.token, isFreelancer);
        return loginResponse;
    };

    const login = async (email, password, rememberMe) => {
        const response = await accountApi.login(email, password);
        let loginResponse = null;
        if (response.ok) {
            loginResponse = await response.json();
            await _setUserWithToken(true, false, rememberMe, loginResponse.token);
        }

        return {
            ok: response.ok,
            json: () => loginResponse
        };
    };

    // Used for updating the current session when editing the profile.
    const reloadProfile = async (firstName) => {
        if (!firstName) {
            firstName = user.firstName;
        }
        await _setUser(user.isLoggedIn, user.isGoogleUser, user.rememberMe, user.token, user.email, firstName, user.isFreelanceMode, user.isAdmin, Date.now());
    };

    const setFreelanceMode = async (freelanceMode) => {
        _setUser(user.isLoggedIn,
            user.isGoogleUser,
            user.rememberMe,
            user.token,
            user.email,
            user.firstName,
            freelanceMode,
            user.isAdmin,
            Date.now()
        );
    };

    const logout = () => {
        if (user && user.isGoogleUser) {
            googleLogout();
        }

        setUser(encode({ isLoggedIn: false }, getCookieOptions(false)));
    };

    const resetPassword = async (email, password, token) => {
        return await accountApi.resetPassword(email, password, token);
    };

    return {
        user,
        login,
        logout,
        resetPassword,
        handleGoogleSuccess,
        setFreelanceMode,
        reloadProfile,
        _setUserWithToken,
    };
}