import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const dark = defineStyle({
    borderRadius: 'full',
    px: 2,
    bg: 'brand.veryDarkGray',
    color: 'brand.white',
    mx: 1,
    cursor: 'pointer',

});

const gray = defineStyle({
    px: 3,
    bg: '#7B7B7B',
    color: 'brand.white',
    mx: 1,
    cursor: 'pointer',
    borderRadius: 'full',
    fontSize: '13px',
    fontWeight: '500',
    border: '1px solid #C4C4C4',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
});

export const badgeTheme = defineStyleConfig({
  variants: { dark, gray },
  defaultProps: {
    variant: 'dark'
  }
});