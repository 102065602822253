import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useApi} from '../../../hooks/useApi.jsx';
import * as Yup from 'yup';
import ProjectStep from '../projectStep.jsx';
import Step3Content from "./step3Content";

const Step3 = () => {
    const {t} = useTranslation();
    const {projects} = useApi();
    const {companyId, projectId} = useParams();

    const onSubmit = async (values) => await projects.motionGraphics.updateStep3(companyId, projectId, values);

    const validationSchema = Yup.object().shape({});

    const projectStepProps = {
        companyId,
        projectId,
        heading: '③',
        subheading: t('project.all.step3.title'),
        onSubmit,
        next: `/company/${companyId}/motion-graphics-project/${projectId}/step-4`,
        previous: `/company/${companyId}/motion-graphics-project/${projectId}/step-2`,
        progress: 75,
        validationSchema
    };

    return (
        <ProjectStep {...projectStepProps}>
            {() => (
                <Step3Content/>
            )}
        </ProjectStep>
    );

};

export default Step3;