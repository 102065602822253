import React from 'react';
import {
    Select,
    Spinner,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText
} from "@chakra-ui/react";
import {
    useLengthOfVideoTypes,
    useVideoVersionTypes,
    useChannelBrandingFinalFileVersionTypes,
    useMotionGraphicsFinalFileVersionTypes,
    useTranslationDubbingFinalFileVersionTypes,
    useVideoEditingSoftwareTypes,
    useGraphicEditingSoftwareTypes,
    useLanguages
} from '../entities';
import { ControlListControl } from './controlList.jsx';
import { useFormikContext, useField } from "formik";
import { useTranslation } from 'react-i18next';

const RelatedEntityPicker = ({ name, entities, mapFrom, mapTo, value, onChange, noneText }) => {
    const setValueMapped = (event) => {
        const mapped = mapTo(event.target.value);
        const newEvent = {
            target: {
                name: event.target.name,
                value: mapped
            }
        }
        onChange(newEvent);
    };

    let options = [];
    if (noneText) {
        options.push({ value: '', label: noneText });
    }

    options = options.concat(entities.map(mapFrom));
    const optionElements = options.map(o => <option key={o.value} value={o.value}>{o.label}</option>);

    const mappedValue = value ? mapFrom(value) : '';

    //Mapped value: '{JSON.stringify(mappedValue)}'

    return (
        <Select name={name} onChange={setValueMapped} value={mappedValue.value} variant='primary'>
            {optionElements}
        </Select>
    );
}



export const VideoVersionTypePicker = ({ name, labelKey }) => {
    const { entity, loading } = useVideoVersionTypes();

    if (loading) {
        return <Spinner />;
    }

    const mapFrom = (e) => {
        if (e.videoVersionType) {
            // The initial value is the link entity
            return ({ value: e.videoVersionType.videoVersionTypeId, label: e.videoVersionType.name });
        }

        // the options are the underlying entity
        return ({ value: e.videoVersionTypeId, label: e.name });
    };

    const mapTo = (value) => ({ videoVersionType: { videoVersionTypeId: value } } );
    return <ControlListControl name={name} labelKey={labelKey} defaultValue={mapTo(entity[0].videoVersionTypeId)}>
        {(props) => {
            return <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...props} defaultValue={entity[0]} />
        }
        }
    </ControlListControl>;
}

export const ChannelBrandingFinalFileVersionTypePicker = ({ name, labelKey }) => {
    const { entity, loading } = useChannelBrandingFinalFileVersionTypes();

    if (loading) {
        return <Spinner />;
    }

    const mapFrom = (e) => ({ value: e.channelBrandingFinalFileVersionTypeId, label: e.name });
    const mapTo = (value) => ({ channelBrandingFinalFileVersionType: { channelBrandingFinalFileVersionTypeId: value } });
    return <ControlListControl name={name} labelKey={labelKey} defaultValue={entity[0]}>
        {(props) => <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...props} />}
    </ControlListControl>;
}

export const LengthOfVideoTypePicker = ({name, labelKey}) => {
    const { entity, loading } = useLengthOfVideoTypes();

    if (loading) {
        return <Spinner />;
    }
    const mapFrom = (e) => ({ value: e.lengthOfVideoTypeId, label: e.name });
    const mapTo = (value) => ({ lengthOfVideoTypeId: Number(value) });
    return <ControlListControl name={name} labelKey={labelKey} defaultValue={mapTo(entity[0].value)}>
        {(props) => <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...props} />}
    </ControlListControl>;
}

export const MotionGraphicsFinalFileVersionTypePicker = ({ name, labelKey }) => {
    const { entity, loading } = useMotionGraphicsFinalFileVersionTypes();

    if (loading) {
        return <Spinner />;
    }

    const mapFrom = (e) => ({ value: e.motionGraphicsFinalFileVersionTypeId, label: e.name });
    const mapTo = (value) => ({ motionGraphicsFinalFileVersionTypeId: Number(value) });
    return <ControlListControl name={name} labelKey={labelKey} defaultValue={entity[0]}>
        {(props) => <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...props} />}
    </ControlListControl>;
}

export const TranslationDubbingFinalFileVersionTypePicker = ({ name, labelKey }) => {
    const { entity, loading } = useTranslationDubbingFinalFileVersionTypes();

    if (loading) {
        return <Spinner />;
    }

    const mapFrom = (e) => {
        // needs refactor
        if (e.translationDubbingFinalFileVersionType) {
            // The initial value is the link entity
            return ({ value: e.translationDubbingFinalFileVersionType.translationDubbingFinalFileVersionTypeId, label: e.translationDubbingFinalFileVersionType.name });
        }

        // the options are the underlying entity
        return ({ value: e.translationDubbingFinalFileVersionTypeId, label: e.name });
    }
    const mapTo = (value) => ({ translationDubbingFinalFileVersionType: { translationDubbingFinalFileVersionTypeId: value } });
    const defaultValue = { translationDubbingFinalFileVersionType: entity[0] };
    return <ControlListControl name={name} labelKey={labelKey} defaultValue={defaultValue}>
        {(props) => <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...props} />}
    </ControlListControl>;
}



export const VideoEditingSoftwareTypePicker = ({ name, labelKey, helperTextKey, ...props }) => {
    const { entity, loading } = useVideoEditingSoftwareTypes();
    const { t } = useTranslation();
    const [field] = useField(name);
    const { errors, touched } = useFormikContext();

    if (loading) {
        return <Spinner />;
    }

    const mapFrom = (e) => {
        if (e == null) return null;
        return { value: e.videoEditingSoftwareTypeId, label: e.name };
    }

    const mapTo = (value) => (value ? { videoEditingSoftwareTypeId: value } : null);

    const value = field.value;

    return <FormControl isInvalid={errors && errors[name] && touched[name]} paddingRight={5}>
        <FormLabel htmlFor={name}>{t(labelKey)}</FormLabel>
        <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...field} {...props} value={value} noneText="No preference" />
        {helperTextKey && <FormHelperText>{t(helperTextKey)}</FormHelperText>}
        <FormErrorMessage>{errors && errors[name]}</FormErrorMessage>
    </FormControl>
}

export const GraphicEditingSoftwareTypePicker = ({ name, labelKey, helperTextKey, ...props }) => {
    const { entity, loading } = useGraphicEditingSoftwareTypes();
    const { t } = useTranslation();
    const [field] = useField(name);
    const { errors, touched } = useFormikContext();

    if (loading) {
        return <Spinner />;
    }

    const mapFrom = (e) => ({ value: e.graphicSoftwareTypeId, label: e.name });
    const mapTo = (value) => ({ graphicSoftwareTypeId: value });

    const value = field.value ?? entity[0];

    return <FormControl isInvalid={errors && errors[name] && touched[name]} paddingRight={5}>
        <FormLabel htmlFor={name}>{t(labelKey)}</FormLabel>
        <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...field} {...props} value={value} />
        {helperTextKey && <FormHelperText>{t(helperTextKey)}</FormHelperText>}
        <FormErrorMessage>{errors && errors[name]}</FormErrorMessage>
    </FormControl>
}
export const LanguagePicker = ({ name, ...props }) => {
    const { entity, loading } = useLanguages();
    const [field, , helpers] = useField(name); // Destructure helpers for manual updates

    if (loading) {
        return <Spinner />;
    }

    const mapFrom = (e) => ({ value: e.languageId, label: e.name });
    const mapTo = (value) => ({ languageId: Number(value)});

    const handleChange = (newValue) => {
        helpers.setValue(newValue.value);
        props[name] = newValue.value;
    };

    const value = field.value ?? props[name]

    return (
        <RelatedEntityPicker
            entities={entity}
            mapFrom={mapFrom}
            mapTo={mapTo}
            {...field}
            {...props}
            value={value}
            onChange={handleChange}
        />
    );
}

