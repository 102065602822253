import {
    Box,
    Button,
    VStack,
} from '@chakra-ui/react';

import CompanyCard from './companyCard.jsx';
import {useParams} from "react-router-dom";
import {useEffect} from "react";

const CompaniesCard = ({ companies, createCompany, updateCompany, deleteCompany, refresh, setCompanyId }) => {
    companies = companies || [];
    const showDeleteButton = companies.length > 1;
    const showAddButton = companies.length === 0;
    const {companyId} = useParams();

    useEffect(() => {
        if(companies.length === 0) {
            setCompanyId(companyId)
        }
    },[companies])


    const companiesList = companies.map(company => {
        const props = {
            key: company.companyId,
            company,
            updateCompany: updateCompany(company.companyId),
            deleteCompany,
            showDeleteButton,
            refresh
        };
        return <CompanyCard {...props} />
    });
    
    return <>
            {companiesList}
            {showAddButton && <Button onClick={createCompany}>Add New</Button> }
        </>;
}

export default CompaniesCard;