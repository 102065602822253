import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle({
    field: {
        bg: 'brand.white',
        border: "1px solid",
        borderColor: "brand.darkerGray",
        '&:focus': {
            borderColor: 'brand.green',
            outline: 'none',
        }
    }
});

const sizes = {
    sm: definePartsStyle({
        field: {
            maxWidth: '30ch',
            borderRadius: '16px',
            borderColor: "brand.darkerGray",
            fontSize: "13px",
            height:"29px",
        }
    }),
    md: definePartsStyle({
        field: {
            maxWidth: '60ch',
            borderRadius: '16px',
            borderColor: "brand.darkerGray",
            fontSize: "13px",
            height:"29px",
        }
    }),
};


export const selectTheme = defineMultiStyleConfig({
    baseStyle,
    sizes
});