import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
    fontSize: '35px',
    fontWeight: 700,
    marginTop: '0.75rem',
});

const section = defineStyle({
  textTransform: 'uppercase',
  fontSize: 'md',
  fontWeight: 'normal'
});

// Define sizes styles
const sizes = {
    xs: {
        fontSize: '12px',
        marginTop: '0.5rem',
    },
    sm: {
        fontSize: '14px',
        marginTop: '0.5rem',
    },
    md: {
        fontSize: '18px',
        marginTop: '0.65rem',
    },
    lg: {
        fontSize: '24px',
        marginTop: '0.75rem',
    },
    xl: {
        fontSize: '35px',
        marginTop: '0.85rem',
    },
};

export const headingTheme = defineStyleConfig({
  baseStyle,
  variants: {
    section
  },
    sizes
})