import {Box, ListItem, Text, UnorderedList, VStack} from "@chakra-ui/react";
import TruncatedLink from "../../truncatedLink";
import ColorDisplay from "../colorDisplay";

const DetailInformation = ({project}) => (
    <VStack spacing={6} align="start">
        <Box>
            <Text fontWeight='bold'>Job Title:</Text>
            <Text>{project.jobTitle}</Text>
        </Box>

        <Box>
            <Text fontWeight='bold'>Describe your project a little...</Text>
            <Text whiteSpace="pre-wrap">{project.description}</Text>
        </Box>

        <Box>
            <Text fontWeight='bold'>Can you share any existing assets?</Text>
            <UnorderedList styleType='none'>
                {project.existingAssets?.map((url, i) => (
                    <ListItem key={i}>
                        {url.toLowerCase().includes('https') ?
                            <TruncatedLink url={url}/> : url}
                    </ListItem>
                ))}
            </UnorderedList>
        </Box>

        <Box>
            <Text fontWeight='bold'>Can you share anything to inspire?</Text>
            <UnorderedList styleType='none'>
                {project.inspirations?.map((url, i) => (
                    <ListItem key={i}>
                        {url.toLowerCase().includes('https') ?
                            <TruncatedLink url={url}/> : url}
                    </ListItem>
                ))}
            </UnorderedList>
        </Box>

        <Box>
            <Text fontWeight='bold'>Preferred colour specification:</Text>
            <ColorDisplay colorString={project.preferredColourSpecification}/>
        </Box>
    </VStack>
);

export default DetailInformation;