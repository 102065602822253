import { useAuth } from './useAuth.jsx';

const requestNoAuth = async (auth, url, method, body) => {
    const token = auth?.user?.token;
    const request = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body
    };

    return await fetch(url, request);
};

const request = async (auth, url, method, body) => {
    const response = await requestNoAuth(auth, url, method, body);
    if (response.status === 401) {
        auth.logout();
        window.location = '/';
    }

    return response;
}

const put = async (auth, url, body) => await request(auth, url, 'PUT', body);

const post = async (auth, url, body) => await request(auth, url, 'POST', body);

const get = async (auth, url, params) => {
    const urlWithParams = url + '?' + new URLSearchParams(params);
    return await request(auth, urlWithParams, 'GET', null);
};

const del = async (auth, url) => await request(auth, url, 'DELETE', null);

const useSkills = (auth) => {
    return {
        get: async () => await get(auth, `api/skills`),
    };
};

const useProjectTags= (auth) => {
    return {
        get: async () => await get(auth, `api/projecttags`),
    };
};

const createProjectApiCalls = (auth, projectTypeDescriptor) => {
    return {
        create: async (companyId) => await put(auth, `api/company/${companyId}/${projectTypeDescriptor}`),
        createAnonymous: async (companyId, anonymousId) => await put({}, `api/company/${companyId}/${projectTypeDescriptor}/${anonymousId}`),
        updateStep2: async (companyId, projectId, values) => await put(auth, `api/company/${companyId}/${projectTypeDescriptor}/${projectId}/step-2/`, JSON.stringify(values)),
        updateStep2Anonymous: async (companyId, projectId, anonymousId, values) => await put(auth, `api/company/${companyId}/${projectTypeDescriptor}/${projectId}/step-2/${anonymousId}`, JSON.stringify(values)),
        updateStep3: async (companyId, projectId, values) => await put(auth, `api/company/${companyId}/${projectTypeDescriptor}/${projectId}/step-3/`, JSON.stringify(values)),
        submitBrief: async (companyId, projectId) => await put(auth, `api/company/${companyId}/${projectTypeDescriptor}/${projectId}/submit-brief/`),
    }
}

const useProjects = (auth) => {
    return {
        get: async (companyId, projectId) => await get(auth, `api/company/${companyId}/project/${projectId}`),
        getAnonymous: async (companyId, projectId, anonymousId) => await get(auth, `api/company/${companyId}/project/${projectId}/${anonymousId}`),
        delete: async (companyId, projectId) => await del(auth, `api/company/${companyId}/project/${projectId}`),
        assign: async (companyId, projectId, userId) => await post(auth, `api/company/${companyId}/project/${projectId}/assign`, JSON.stringify({ userId })),
        unassign: async (companyId, projectId, userId) => await post(auth, `api/company/${companyId}/project/${projectId}/unassign`, JSON.stringify({ userId })),
        invite: async (companyId, projectId, email, invitationClientUri) => await post(auth, `api/company/${companyId}/project/${projectId}/invite`, JSON.stringify({ email, invitationClientUri })),
        uploadToken: async (companyId, projectId, projectFileTypeIdentifier, filename) => await post(auth, `api/company/${companyId}/project/${projectId}/upload-token`, JSON.stringify({ projectFileTypeIdentifier, filename })),
        submitEdit: async (companyId, projectId, values) => await put(auth, `api/company/${companyId}/project/${projectId}/edit/`, JSON.stringify(values)),
        acceptEdit: async (companyId, projectId, editId) => await put(auth, `api/company/${companyId}/project/${projectId}/edit/${editId}/accept`),
        complete: async (companyId, projectId) => await put(auth, `api/company/${companyId}/project/${projectId}/complete`),
        setStatus: async (companyId, projectId, status) => await put(auth, `api/company/${companyId}/project/${projectId}/status`, JSON.stringify({ status })),
        videoEditing: createProjectApiCalls(auth, 'video-editing-project'),
        channelBranding: createProjectApiCalls(auth, 'channel-branding-project'),
        motionGraphics: createProjectApiCalls(auth, 'motion-graphics-project'),
        translationDubbing: createProjectApiCalls(auth, 'translation-dubbing-project'),
        bespoke: createProjectApiCalls(auth, 'bespoke-project'),
        cameraOperator: createProjectApiCalls(auth, 'camera-operator-project')
    };
}

const useProfile = (auth) => {
    return {
        get: async () => await get(auth, '/api/user/me'),
        getById: async (id) => await get(auth, `/api/user/${id}`),
        getProjects: async (isFreelance, view, dateFilter, searchText, pageNumber, pageSize) => await get(auth, '/api/user/me/projects', { isFreelance, view, dateFilter, searchText, pageNumber, pageSize }),
        getAll: async () => await get(auth, '/api/user/all'),
        update: async (values) => await post(auth, '/api/user/me', JSON.stringify(values)),
        makeAdmin: async (id) => await post(auth, `/api/user/${id}/makeAdmin`),
        removeAdmin: async (id) => await post(auth, `/api/user/${id}/removeAdmin`),
        forgotPassword: async (email, clientUri) => await post(auth, '/api/account/forgot-password', JSON.stringify({ email, clientUri })),
        signupFreelancer: async (firstName, lastName) => await post(auth, '/api/user/signup/freelancer', JSON.stringify({ firstName, lastName })),
        signupCompany: async (firstName, lastName, companyName) => await post(auth, '/api/user/signup/company', JSON.stringify({ firstName, lastName, companyName })),
        deleteUser: async (id) => await del(auth, `/api/user/${id}`),
        uploadProfilePhotoToken: async (filename) => await post(auth, '/api/user/me/upload-profile-photo-token', JSON.stringify({ filename }))
    };
}

const useCompany = (auth) => {
    return {
        get: async () => await get(auth, '/api/company/'),
        getAnonymousById: async (anonymousId) => await get({}, `/api/company/anonymous/${anonymousId}`),
        getAll: async () => await get(auth, '/api/company/all'),
        getById: async (companyId) => await get(auth, `/api/company/${companyId}`),
        create: async () => await post(auth, '/api/company/', {}),
        createAnonymous: async (anonymousId) => await post({}, `/api/company/anonymous/${anonymousId}`),
        update: async (companyId, values) => await put(auth, `/api/company/${companyId}`, JSON.stringify(values)),
        del: async (companyId) => await del(auth, `/api/company/${companyId}`),
        invitations: {
            get: async (companyId) => await get(auth, `api/company/${companyId}/invitation`),
            create: async (companyId, ...values) => await post(auth, `api/company/${companyId}/invitation`, JSON.stringify(values)),
        },
        uploadCompanyAsset: async (companyId, filename) => await post(auth, `/api/company/${companyId}/upload-company-asset-token`, JSON.stringify({ filename }))
    }
}

const useOther = (auth) => {
    return {
        lengthOfVideoTypes: {
            get: async () => await get(auth, '/api/lengthOfVideoType')
        },
        videoVersionTypes: {
            get: async () => await get(auth, '/api/videoVersionType')
        },
        channelBrandingFinalFileVersionTypes: {
            get: async () => await get(auth, '/api/channelBrandingFinalFileVersionType')
        },
        motionGraphicsFinalFileVersionTypes: {
            get: async () => await get(auth, '/api/motionGraphicsFinalFileVersionType')
        },
        translationDubbingFinalFileVersionTypes: {
            get: async () => await get(auth, '/api/translationDubbingFinalFileVersionType')
        },
        videoEditingSoftwareTypes: {
            get: async () => await get(auth, '/api/videoEditingSoftwareType')
        },
        graphicSoftwareTypes: {
            get: async () => await get(auth, '/api/graphicSoftwareType')
        },
        languages: {
            get: async () => await get(auth, '/api/language')
        },
        projectActivityLogs: {
            get: async (projectId, pageNumber, pageSize) => await get(auth, '/api/projectActivityLog', { projectId: (projectId || ''), pageNumber, pageSize })
        }
    };
};

export const useApi = () => {
    const auth = useAuth();
    const projects = useProjects(auth);
    const profile = useProfile(auth);
    const company = useCompany(auth);
    const skills = useSkills(auth);
    const projectTags = useProjectTags(auth);
    const other = useOther(auth);
    
    return {
        projects,
        profile,
        company,
        skills,
        projectTags,
        ...other
    };
};

const useAccount = () => {
    return {
        signup: async (email, password, anonymousId) => await post({}, '/api/account/register-user', JSON.stringify({ email, password, anonymousId })),
        login: async (email, password) => await requestNoAuth(null, '/api/account/login', 'POST', JSON.stringify({ email, password })),
        forgotPassword: async (email, clientUri) => await post({}, '/api/account/forgot-password', JSON.stringify({ email, clientUri })),
        resetPassword: async (email, password, resetPasswordToken) => await post({}, '/api/account/reset-password', JSON.stringify({ email, password, resetPasswordToken })),
        externalLogin: async (externalProviderToken, invitationToken) => await post({}, '/api/account/externalLogin', JSON.stringify({ provider: 'Google', externalProviderToken, invitationToken }))
    };
};

export const usePublicApi = () => {
    const account = useAccount();
    return {
        account
    };
};
