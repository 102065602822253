import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Center,
    Container,
    Heading,
    Progress, VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/useApi.jsx';
import ComponentLoading from '../ComponentLoading.jsx';
import CreateProjectForm from "./createProjectForm";

export default function CreateProject({ history }) {
    const [error, setError] = useState('');
    const [defaultCompanyId, setDefaultCompanyId] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { projects, company } = useApi();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await company.get();
                if (!response.ok) throw new Error("An unknown error occurred");

                const body = await response.json();
                if (body.length === 0) throw new Error("This user has no companies set up");

                setDefaultCompanyId(body[0].companyId);
            } catch (e) {
                setError(e.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [company]);


    const handleSubmit = async (values) => {
        const urlParts = {
            'channelBranding': 'channel-branding',
            'videoEditing': 'video-editing',
            'cameraOperator': 'camera-operator',
            'bespoke': 'bespoke',
            'motionGraphics': 'motion-graphics',
            'translationDubbing': 'translation-dubbing'
        };

        const urlPart = urlParts[values.projectType];

        const response = await projects[values.projectType].create(values.companyId);

        const project = await response.json();
        history.push(`/company/${values.companyId}/${urlPart}-project/${project.projectId}/step-2`);
    };

    if (loading) return <ComponentLoading />;
    if (error) return <div>{error}</div>;

    return (
        <Container maxW={'3xl'} padding={6}>
            <Card>
                <CardBody>
                    <Center>
                        <VStack>
                            <Heading size='2xl'>①</Heading>
                            <Heading fontSize='35px'>{t('project.type.title')}</Heading>
                        </VStack>
                    </Center>
                    <Progress
                        value='20'
                        marginTop="1em"
                        size='sm'
                        w={"400px"}
                        marginLeft="auto"
                        marginRight="auto"
                        borderRadius={"12px"}
                        bg="brand.gray"
                    />
                    <CreateProjectForm defaultCompanyId={defaultCompanyId} onSubmit={handleSubmit} />
                </CardBody>
            </Card>
        </Container>
    );
}
