import React from 'react';
import {Route} from 'react-router';
import SignupForm from 'components/signup';
import LoginForm from 'components/loginForm.jsx';
import ResetPasswordForm from 'components/resetPasswordForm.jsx';
import ForgotPasswordForm from 'components/forgotPasswordForm.jsx';
import Profile from 'components/profile';
import projectDetail from 'components/projects/projectDetail.jsx';
import CreateProject from 'components/projects/createProject.jsx';
import videoEditingProject from 'components/projects/videoEditing';
import channelBrandingProject from 'components/projects/channelBranding';
import motionGraphicsProject from 'components/projects/motionGraphics';
import translationDubbingProject from 'components/projects/translationDubbing';
import cameraOperatorProject from 'components/projects/cameraOperator';
import bespokeProject from 'components/projects/bespoke';
import {AuthProvider} from 'hooks/useAuth.jsx';
import Dashboard from 'components/dashboard.jsx';
import config from './config.js';
import AppHeader from './AppHeader.jsx';
import ProtectedRoute from './components/protectedRoute.jsx';

const Routes = () => {
    return <>
        <ProtectedRoute exact path="/" component={Dashboard}/>
        <ProtectedRoute path="/profile" component={Profile}/>
        <ProtectedRoute exact path="/create-project" component={CreateProject}/>
        <ProtectedRoute exact path="/company/:companyId/project/:projectId" component={projectDetail}/>
        <ProtectedRoute path="/company/:companyId/video-editing-project" component={videoEditingProject}/>
        <ProtectedRoute path="/company/:companyId/channel-branding-project" component={channelBrandingProject}/>
        <ProtectedRoute path="/company/:companyId/motion-graphics-project" component={motionGraphicsProject}/>
        <ProtectedRoute path="/company/:companyId/translation-dubbing-project" component={translationDubbingProject}/>
        <ProtectedRoute path="/company/:companyId/bespoke-project" component={bespokeProject}/>
        <ProtectedRoute path="/company/:companyId/camera-operator-project" component={cameraOperatorProject}/>
        <Route path="/signup" render={() => <SignupForm/>}/>
        <Route exact path="/login" render={() => <LoginForm/>}/>
        <Route exact path="/forgot-password" render={() => <ForgotPasswordForm/>}/>
        <Route exact path="/reset-password" render={() => <ResetPasswordForm/>}/>
    </>;
}

const App = () => {
    return (<AuthProvider googleClientId={config.GOOGLE_CLIENT_ID}>
        <AppHeader/>
        <Routes/>
    </AuthProvider>);
};

export default App;
