const green = '#A3C56C';
const orange = '#F36600';
const pink = '#FF2A83';
const unspecified = green;

const projectColours = {
    Step2: unspecified,
    Step3: unspecified,
    Step4: unspecified,
    BriefSubmitted: orange,
    PendingAllocation: unspecified,
    ProjectAccepted: unspecified,
    ReviewLinksSubmitted: unspecified,
    FinalFilesSubmitted: green,
    ProjectCompleted: green
};

export default projectColours;

