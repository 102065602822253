import React, { useState, useEffect } from 'react';
import {
    Box,
    useRadioGroupContext,
    chakra,
    RadioGroup as ChakraRadioGroup,
    useRadio
} from '@chakra-ui/react';
import { useField } from 'formik';

// https://chakra-ui.com/community/recipes/advanced-formik-integration
export const HeroSelector = React.forwardRef((props, ref) => {
    const { label, name, ...radioProps } = props;
    const group = useRadioGroupContext();
    const isChecked = group.value.toString() === props.value.toString();

    const [{ checked, ...field }] = useField({
        name,
        type: "radio",
        value: radioProps.value.toString(),
        checked: isChecked
    });

    const {
        state,
        getInputProps,
        getCheckboxProps,
        htmlProps,
        getLabelProps } = useRadio({ isChecked, ...field });

    const borderColour = isChecked ? 'brand.green' : 'brand.darkGray';
    const borderWidth = isChecked ? '4px' : '1px';
    const backgroundColour = props.backgroundColor ?? '#ffffff';
    const padding = props.padding ?? '12px 32px';
    return <chakra.label {...htmlProps} cursor="pointer" width='100%'>
        <input {...getInputProps({}, ref)} hidden />
        <Box userSelect={'none'} borderRadius='30' borderWidth={borderWidth} borderColor={borderColour} padding={padding} bg={backgroundColour} {...getCheckboxProps()} textAlign='center' fontSize='13px' fontWeight='700' width={'190px'}>
            {label} {isChecked}
        </Box>
    </chakra.label>

});

export const RadioGroup = ({ name, children, ...props }) => {
    const [field, { setValue }] = useField({ name, value: props.value });
    const namedChildren = React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return;
        }

        return React.cloneElement(child, { name });
    });

    return <ChakraRadioGroup {...props} {...field} onChange={setValue} children={namedChildren} />
};