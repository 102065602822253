import {Button, Icon, Link, Tag, Td, Text, Tooltip, Tr, useDisclosure} from "@chakra-ui/react";
import AssignFreelancerModal from "../projects/assignFreelancer";
import DeleteProjectModal from "./deleteProjectModal";
import {DeleteIcon} from "@chakra-ui/icons";
import {FaUserPlus} from "react-icons/fa";
import React from "react";
import {useTranslation} from "react-i18next";
import {useApi} from "../../hooks/useApi";
import {projectLink} from "../../utils/projectUtils";
import ProjectStatus from "./projectStatus";

const ProjectRow = ({project, user, onProjectDeleted}) => {
    const {i18n} = useTranslation();
    const {projects: projectsApi} = useApi();

    const handleDeleteProject = async (companyId, projectId) => {
        await projectsApi.delete(companyId, projectId);
        onProjectDeleted();
    }


    const p = project;
    const title = p.jobTitle && p.jobTitle !== '' ? p.jobTitle : 'No Title';
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen: isAssignOpen, onOpen: onAssignOpen, onClose: onAssignClose} = useDisclosure();

    return <Tr>
        <AssignFreelancerModal onClose={onAssignClose} isOpen={isAssignOpen} companyId={p.companyId}
                               projectId={p.projectId}/>
        <DeleteProjectModal onClose={onClose} isOpen={isOpen}
                            handleDeleteProject={() => handleDeleteProject(p.companyId, p.projectId)}/>
        <Td maxWidth="100px" textAlign={'center'}>{Intl.DateTimeFormat(i18n.language).format(new Date(p.created))}</Td>
        <Td textAlign="center" maxWidth="250px" minWidth="100px">
            <Link href={projectLink(p)} style={{width: '100%', display: 'block'}}>
                {title.length > 30 ? (
                    <Tooltip label={title} placement="top" maxWidth="none">
                        <Text noOfLines={1} maxWidth="250px" style={{margin: '0 auto', display: 'block'}}>
                            {title}
                        </Text>
                    </Tooltip>
                ) : (
                    <Text noOfLines={1} maxWidth="250px" style={{margin: '0 auto', display: 'block'}}>
                        {title}
                    </Text>
                )}
            </Link>
        </Td>
        <Td textAlign="center" width="100px">
            <Tag variant='black'>
                {p.projectTypeIdentifier.replace(/-/g, ' ')}
            </Tag>
        </Td>
        {/*<Td textAlign={'center'}>{p.projectTypeIdentifier.replace(/-/g, ' ')}</Td>*/}
        <Td textAlign={'center'} minWidth="200px" maxWidth="250px"><ProjectStatus project={p}/></Td>
        <Td maxWidth="100px" textAlign={'center'}>
            {p.deadline !== null
                ? Intl.DateTimeFormat(i18n.language).format(new Date(p.deadline))
                : '-'
            }
        </Td>
        <Td textAlign={'center'}>
            {!user.isFreelanceMode && user.isAdmin &&
                (p.assignedFreelancers && p.assignedFreelancers.length > 0
                    ? p.assignedFreelancers.map((fp, index) => (
                        <span key={index}>
                    {fp.firstName}
                            {index < p.assignedFreelancers.length - 1 ? ', ' : ''}
                </span>
                    ))
                    : <span>Nobody</span>)
            }
        </Td>

        <Td textAlign={'center'}>
            {/*{!user.isFreelanceMode &&*/}
            {/*    <span><Link variant="dashboard" href={projectLink(p)}><EditIcon mr={3}/></Link></span>}*/}
            {!user.isFreelanceMode &&
                <span><Button mr={2} p={0} variant="dashboard" onClick={onOpen}><DeleteIcon
                    boxSize={4}/></Button></span>}
            {!user.isFreelanceMode && user.isAdmin &&
                <span><Button mr={2} p={0} variant="dashboard" onClick={onAssignOpen}><Icon boxSize={4} as={FaUserPlus}
                                                                                            verticalAlign='middle'/></Button></span>}
            {/*{user.isFreelanceMode && <span><Link variant="dashboard" href={projectLink(p)}><EditIcon/></Link></span>}*/}
        </Td>
    </Tr>;
}

export default ProjectRow;