import React from 'react';
import {Link, Text, Tooltip} from '@chakra-ui/react'; // Assuming you're using Chakra UI

const TruncatedLink = ({url, tooltipLabel, maxUrlLength = 30, linkColor = '#3B9D97'}) => (
    <Link href={url} isExternal target="_blank" style={{width: '100%', display: 'block'}}>
        {url?.length > maxUrlLength ? (
            <Tooltip label={tooltipLabel || url} placement="top" maxWidth="none">
                <Text noOfLines={1} maxWidth="250px" style={{
                    margin: '0 auto',
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: linkColor
                }}>
                    {url}
                </Text>
            </Tooltip>
        ) : (
            <Text noOfLines={1} maxWidth="250px" style={{
                margin: '0 auto',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: linkColor
            }}>
                {url}
            </Text>
        )}
    </Link>
);

export default TruncatedLink;
