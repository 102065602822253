import React from 'react';
import {
    Select
} from "@chakra-ui/react";

const DashboardViewPicker = ({ setValue, value }) => {
    //const { entity, loading } = useVideoVersionTypes();

    //if (loading) {
    //    return <Spinner />;
    //}
    //
    //const mapFrom = (e) => ({ value: e.videoVersionTypeId, label: e.name });
    //const mapTo = (value) => ({ videoVersionType: { videoVersionTypeId: value } });
    //return <ControlListControl name={name} labelKey={labelKey} defaultValue={entity[0]}>
    //    {(props) => <RelatedEntityPicker entities={entity} mapFrom={mapFrom} mapTo={mapTo} {...props} />}
    //</ControlListControl>;

    return <Select name="view" onChange={e => setValue(e.target.value)} value={value} variant='primary'>
        <option value="AllProjects">All</option>
        <option value="OpenProjects">Open</option>
        <option value="ProjectAccepted">Accepted</option>
        <option value="BriefSubmitted">Brief Submitted</option>
        <option value="CompletedProjects">Completed</option>
    </Select>
}

export default DashboardViewPicker;
