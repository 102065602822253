import { ChromePicker } from 'react-color';
import { useState } from 'react';
import {
    Box
} from "@chakra-ui/react";

const ColourPicker = (props) => {
    const [open, setOpen] = useState(false);

    // https://stackoverflow.com/a/6511606
    function contrastingColor(color) {
        return (luma(color) >= 165) ? '000' : 'fff';
    }
    function luma(color) // color can be a hx string or an array of RGB values 0-255
    {
        var rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
        return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
    }
    function hexToRGBArray(color) {
        if (color.length === 3)
            color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
        else if (color.length !== 6)
            throw ('Invalid hex color: ' + color);
        var rgb = [];
        for (var i = 0; i <= 2; i++)
            rgb[i] = parseInt(color.substr(i * 2, 2), 16);
        return rgb;
    }

    const colour = props.value && props.value !== '' ? props.value : '#fff';
    const text = '#' + contrastingColor(colour.substring(1));
    const handleChange = (e) => {
        props.onChange({ target: { name: props.name, value: e.hex } });
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return <>
        <Box bg={colour} color={text} onClick={() => setOpen(true)} padding={"4px 20px"} borderRadius={30} borderColor='brand.darkerGray' fontSize="13px" borderWidth={1}>
        Select colour
        </Box>
        {open && <div style={popover}>
            <div style={cover} onClick={handleClose} />
            <ChromePicker color={colour} onChange={handleChange}/>
        </div>
        }
        </>;
    
    /*return */
}

export { ColourPicker };