import {Box, Flex, Text, VStack} from "@chakra-ui/react";
import {format} from 'date-fns';

const DetailInformation = ({project}) => (
    <Flex
        direction={['column', null, 'row']} w="full" spacing={6}
    >
        <VStack spacing={6} align="start" width={['100%', null, '50%']}>

            <Box>
                <Text fontWeight='bold'>Job Title</Text>
                <Text>{project.jobTitle}</Text>
            </Box>

            <Box>
                <Text fontWeight='bold'>Job Description</Text>
                <Text whiteSpace="pre-wrap">{project.description}</Text>
            </Box>

            <Box>
                <Text fontWeight='bold'>When is the shoot taking place?</Text>
                <Text>
                    {project.whenIsTheShootTakingPlace ?
                        format(new Date(project.whenIsTheShootTakingPlace), 'dd/MM/yyyy') :
                        null}
                </Text>
            </Box>

        </VStack>

        <VStack spacing={6} align="start" width={['100%', null, '50%']}>

            <Box>
                <Text fontWeight='bold'>Where is the shoot taking place?</Text>
                <Text>{project.whereIsTheShootTakingPlace}</Text>
            </Box>

            <Box>
                <Text fontWeight='bold'>Do you need professional lighting?</Text>
                <Text>
                    {project.doYouNeedProfessionalLighting === true ? 'Yes' :
                        project.doYouNeedProfessionalLighting === false ? 'No' : ''}
                </Text>
            </Box>

            <Box>
                <Text fontWeight='bold'>Number Of Cameras</Text>
                <Text>{project.numberOfCameras}</Text>
            </Box>

        </VStack>
    </Flex>
)

export default DetailInformation;