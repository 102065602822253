import {Flex, VStack} from "@chakra-ui/react";
import {DatePickerControl, InputControl, SwitchControl, TextareaControl} from "../../forms";

const Step2Content = () => {
    return (
        <Flex direction={['column', null, 'row']} p={3} w="full">
            <VStack width={['100%', null, '50%']} spacing={6} mr={[0, null, 8]}>
                <InputControl
                    name="jobTitle"
                    labelKey="project.all.step2.jobTitle"
                    required
                />
                <TextareaControl
                    name="description"
                    labelKey="project.all.step2.description"
                    required
                />
                <InputControl
                    name="numberOfCameras"
                    labelKey="project.cameraOperator.step2.numberOfCameras"
                />
                <InputControl
                    name="whereIsTheShootTakingPlace"
                    labelKey="project.cameraOperator.step2.whereIsTheShootTakingPlace"
                />
            </VStack>

            <VStack width={['100%', null, '50%']} spacing={6}>
                <DatePickerControl
                    name="whenIsTheShootTakingPlace"
                    labelKey="project.cameraOperator.step2.whenIsTheShootTakingPlace"
                />
                <SwitchControl
                    name="doYouNeedProfessionalLighting"
                    labelKey="project.cameraOperator.step2.doYouNeedProfessionalLighting"
                />
            </VStack>
        </Flex>
    )
}
export default Step2Content;
