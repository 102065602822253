import {Flex, Text, VStack} from "@chakra-ui/react";

const DetailInformation = ({ project }) => {
    return (
        <Flex direction={['column', null, 'row']} w="full">
            <VStack width={['100%', null, '100%']} spacing={6} alignItems="start">
                <Text fontWeight='bold'>Job Title</Text>
                <Text>{project.jobTitle}</Text>
                <Text fontWeight='bold'>Job Description</Text>
                <Text whiteSpace="pre-wrap">{project.description}</Text>
            </VStack>
        </Flex>
    )
}
export default DetailInformation;