import { avatarTheme } from './Avatar.js';
import { linkTheme } from './Link.js';
import { cardTheme } from './Card.js';
import { headingTheme } from './Heading.js';
import { dividerTheme } from './Divider.js';
import { inputTheme } from './Input.js';
import { formLabelTheme } from './FormLabel.js';
import { buttonTheme } from './Button.js';
import { badgeTheme } from './Badge.js';
import { radioTheme } from './Radio.js';
import { extendTheme } from '@chakra-ui/react';
import { textareaTheme } from './Textarea.js';
import { selectTheme } from './Select.js';
import {progressTheme} from "./Progress.js";
import {checkboxTheme} from "./Checkbox";
import {containerTheme} from "./Container";
import {tagTheme} from "./Tag";

export const theme = extendTheme({
    colors: {
        transparent: 'transparent',
        brand: {
            white: '#ffffff',
            black: '#4d4d4d',
            gray: '#ECECEC',
            darkerGray: '#C4C4C4',
            darkGray: '#DBDBDB',
            veryDarkGray: '#4d4d4d',
            green: '#3B9D97',
            pink: '#FF4ADD'
        },
    },
    fonts: {
        body: "Lato",
        heading: "Lato"
    },
    styles: {
        global: (props) => ({
            body: {
                bg: 'brand.gray'
            }
        })
    },
    components: {
        Avatar: avatarTheme,
        Link: linkTheme,
        Card: cardTheme,
        Heading: headingTheme,
        Divider: dividerTheme,
        Input: inputTheme,
        FormLabel: formLabelTheme,
        Button: buttonTheme,
        Badge: badgeTheme,
        Radio: radioTheme,
        Textarea: textareaTheme,
        Select: selectTheme,
        Progress: progressTheme,
        Checkbox: checkboxTheme,
        Container: containerTheme,
        Tag: tagTheme,
    },
  });