import { useState, useEffect } from 'react';

const localStorageKey = 'elefant-rock-temp-id';

const useLocalStorageUtil = () => {
    const [guid, setGuid] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [projectId, setProjectId] = useState(null);

    useEffect(() => {
        const dataString = localStorage.getItem(localStorageKey);
        if (dataString) {
            const data = JSON.parse(dataString);
            setGuid(data.guid);
            setCompanyId(data.companyId);
            setProjectId(data.projectId);
        }
    }, []);

    const updateLocalStorage = (newData) => {
        const existingData = JSON.parse(localStorage.getItem(localStorageKey)) || {};
        const updatedData = { ...existingData, ...newData };
        localStorage.setItem(localStorageKey, JSON.stringify(updatedData));

        setGuid(updatedData.guid);
        setCompanyId(updatedData.companyId);
        setProjectId(updatedData.projectId);
    };

    const clearLocalStorage = () => {
        localStorage.removeItem(localStorageKey);
        setGuid(null);
        setCompanyId(null);
        setProjectId(null);
    };

    return [guid, companyId, projectId, updateLocalStorage, clearLocalStorage];
};

export default useLocalStorageUtil;
